import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { startretencionAddItem } from '../../../../redux/actions/retencionActions'
import { useDispatch } from 'react-redux'
import useRetenciones from '../../../../hooks/useRetenciones'
import { formatCurrency } from '../../../../Helpers/formater'

// const enumTipoRegistro = [
//   { value: '01-REG', label: '01-REG' },
//   { value: '02-AJUSND', label: '02-AJUSND' },
//   { value: '02-AJUSNC', label: '02-AJUSNC' },
//   { value: '03-ANULA', label: '03-ANULA' },
// ]

// const enumAlicuota = [
//   { value: 8, label: '8 %' },
//   { value: 16, label: '16 %' },
//   { value: 31, label: '31 %' },
// ]

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: 0,
      width: '100%',
    },
  },
  CardHeader: {
    color: 'white',
    padding: '8px',
  },
  cardheader_icons: {
    fontSize: '1.5rem',
  },
  texto: {
    fontSize: '12px',
    fontWeight: 'bolder',
  },
  textField: {
    '& .MuiInputBase-input': {
      fontSize: '12px',
      fontWeight: 'bolder',
    },
  },
  textodireccion: {
    fontSize: '12px',
    color: '#9e9e9e',
    fontWeight: 'bolder',
  },
  select: {
    fontSize: '12px',
    fontWeight: 'bold',
    alignSelf: 'center',
  },
  sublabel: {
    fontSize: '12px',
    color: '#546E7A',
    alignItems: 'center',
    margin: 0,
  },

  subselect: {
    fontSize: '12px',
    fontWeight: 'bold',
    alignSelf: 'center',
    width: '100%',
    '& .MuiMenuItem-root': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  // class="MuiSelect-root MuiSelect-select MuiSelect-selectMenu MuiInputBase-input MuiInput-input"
  selectWtihLabel: {
    fontSize: '10px',
    fontWeight: 'bold',
    alignSelf: 'center',
    '& .MuiSelect-select': {
      // height: '300px',
      width: '600px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}))

export default function DetalleRetencionIslr({ Siguiente }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { addRetencion, conceptosIsrl } = useRetenciones()
  console.log('🚀 ~ DetalleRetencionIslr ~ conceptosIsrl:', conceptosIsrl)

  const schema = yup.object().shape({
    fechaDocumento: yup
      .date()
      .typeError('Debe ser una fecha válida')
      .required('Fecha del Documento es requerida'),
    // tipodocumento: yup
    //   .string()
    //   .required('Debe seleccionar el Tipo de Documento')
    //   .oneOf(['Factura', 'NotaC', 'NotaD']),
    nroDocumento: yup
      .string()
      .required('Nro de Documento es requerido')
      .max(20),
    nroControlDocumento: yup
      .string()
      .required('Nro de Control del Documento es requerido')
      .max(20),
    // nroDocumentoAfectado: yup
    //   .string()
    //   .required('Nro de Documento Afectado es requerido')
    //   .max(20),
    montoTotalDocumento: yup
      .number()
      .required('Monto Total del Documento es requerido'),
    compraExento: yup.number(),
    // tipoTransaccion: yup
    //   .string()
    //   .required('Tipo de Transacción es requerido')
    //   .oneOf(['01-REG', '02-AJUSND', '03-AJUSNC', '04-ANULA']),
    baseImponible: yup.number().required('Base Imponible es requerida'),
    // Alicuota: yup.number().oneOf([8, 16, 31]),
    conceptoIslr: yup.string().required('Concepto de ISLR es requerido'),
    // impuestoCausado: yup.number().required('Impuesto Causado es requerido'),
    // impuestoRetenido: yup.number().required('Impuesto Retenido es requerido'),
    causadoRetenido: yup
      .number()
      .required('Impuesto de ISLR causado y retenido es requerido'),
    // nroNota: yup.string().max(20),
    // .required('Nro de Nota de Crédito es requerido')
  })

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    reset({
      fechaDocumento: '',
      // tipodocumento: '',
      nroDocumento: '',
      nroControlDocumento: '',
      // nroDocumentoAfectado: '',
      montoTotalDocumento: 0,
      compraExento: 0,
      // tipoTransaccion: '',
      baseImponible: 0,
      // Alicuota: 0,
      conceptoIslr: '',
      // impuestoCausado: 0,
      // impuestoRetenido: 0,
      causadoRetenido: 0,
      // nroNota: '',
    })
  }, [isSubmitSuccessful, reset, dispatch])

  const onSubmit = (data) => {
    console.log('🚀 ~ onSubmit ~ data:', data)

    try {
      dispatch(startretencionAddItem(data))
      reset()
    } catch (error) {
      console.log('🚀 ~ onSubmit ~ error:', error)
    }
  }

  return (
    <Card
      style={{
        minHeight: '40vh',
        // maxWidth: 500,
        boxShadow:
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      }}
    >
      <CardHeader
        style={{
          backgroundColor: Siguiente ? '#054477' : '#9e9e9e',
        }}
        className={classes.CardHeader}
        title={
          <div
            style={{ textAlign: 'left', margin: 0, padding: 0, width: '100%' }}
          >
            <p style={{ margin: 0, fontSize: '20px', fontWeight: 'bolder' }}>
              Detalle Retención ISLR
            </p>
          </div>
        }
      />
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Controller
                name='fechaDocumento'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={!Siguiente}
                    label='Fecha Documento'
                    type='date'
                    fullWidth
                    variant='standard'
                    inputProps={{
                      max: new Date().toISOString().split('T')[0], // Disable future dates
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '14px' },
                    }}
                    className={classes.textField}
                    error={!!errors.fechaDocumento}
                    helperText={errors.fechaDocumento?.message}
                  />
                )}
              />
            </Grid>

            {/* <Grid
              item
              xs={12}
              sm={4}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <InputLabel
                style={{
                  fontSize: '12px',
                  color: '#9e9e9e',
                }}
              >
                Tipo Transacción
              </InputLabel>
              <Controller
                control={control}
                name='tipoTransaccion'
                defaultValue=''
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Select
                    labelId='tipoTransaccion'
                    id='tipoTransaccion'
                    fullWidth
                    defaultValue=''
                    disabled={!Siguiente}
                    className={classes.select}
                    onChange={onChange}
                    value={value}
                    error={!!error}
                    label='Tipo Transacción'
                  >
                    {enumTipoRegistro.map((option) => {
                      return (
                        <MenuItem
                          className={classes.sublabel}
                          key={option.key}
                          value={option.value}
                        >
                          {option.value}
                        </MenuItem>
                      )
                    })}
                  </Select>
                )}
              />
              <FormHelperText error>
                {errors?.tipoTransaccion?.message}
              </FormHelperText>
            </Grid> */}

            <Grid item xs={12} sm={4}>
              <Controller
                name='nroDocumento'
                control={control}
                label='Nro Documento'
                variant='outlined'
                rules={{
                  required: true,
                  minLength: 1,
                  maxLength: 20,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={!Siguiente}
                    label='Nro Documento'
                    type='text'
                    fullWidth
                    variant='standard'
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '14px' },
                    }}
                    className={classes.textField}
                    error={!!errors.nroDocumento}
                    helperText={errors.nroDocumento?.message}
                  />
                )}
              />
            </Grid>
            {/*  4 */}
            <Grid item xs={12} sm={4}>
              <Controller
                name='nroControlDocumento'
                control={control}
                label='Nro Control Documento'
                variant='outlined'
                rules={{
                  required: true,
                  minLength: 1,
                  maxLength: 20,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={!Siguiente}
                    fullWidth
                    label='Nro Control Documento'
                    type='text'
                    variant='standard'
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '14px' },
                    }}
                    className={classes.textField}
                    error={!!errors.nroControlDocumento}
                    helperText={errors.nroControlDocumento?.message}
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className={classes.selectWtihLabel}
            >
              <InputLabel
                style={{
                  fontSize: '12px',
                  color: '#9e9e9e',
                  width: '100%',
                }}
              >
                Concepto ISLR
              </InputLabel>
              <Controller
                control={control}
                name='conceptoIslr'
                defaultValue=''
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Select
                    labelId='conceptoIslr'
                    id='conceptoIslr'
                    fullWidth
                    defaultValue=''
                    disabled={!Siguiente}
                    className={classes.selectWtihLabel}
                    onChange={onChange}
                    value={value}
                    error={!!error}
                    label='Concepto ISLR'
                    multiline
                  >
                    {conceptosIsrl?.results?.map((option) => {
                      return (
                        <MenuItem
                          className={classes.selectWtihLabel}
                          key={option.key}
                          value={option.Codigo}
                        >
                          {option.Concepto}
                        </MenuItem>
                      )
                    })}
                  </Select>
                )}
              />
              <FormHelperText error>
                {errors?.conceptoIslr?.message}
              </FormHelperText>
            </Grid>

            {/* <Grid item xs={12} sm={4}>
              <Controller
                name='nroNota'
                control={control}
                label='Nro Nota de Crédito'
                variant='outlined'
                rules={{
                  required: true,
                  minLength: 1,
                  maxLength: 20,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={!Siguiente}
                    fullWidth
                    label='Nro Nota de Crédito'
                    type='text'
                    variant='standard'
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '14px' },
                    }}
                    className={classes.textField}
                    error={!!errors.nroNota}
                    helperText={errors.nroNota?.message}
                  />
                )}
              />
            </Grid> */}
            {/* <Grid item xs={12} sm={4}>
              <Controller
                name='nroDocumentoAfectado'
                control={control}
                label='Nro Documento Afectado'
                variant='outlined'
                rules={{
                  required: true,
                  minLength: 1,
                  maxLength: 20,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={!Siguiente}
                    fullWidth
                    label='Nro Documento Afectado'
                    type='text'
                    variant='standard'
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '14px' },
                    }}
                    className={classes.textField}
                    error={!!errors.nroDocumentoAfectado}
                    helperText={errors.nroDocumentoAfectado?.message}
                  />
                )}
              />
            </Grid> */}

            <Grid item xs={12} sm={4}>
              <Controller
                name='montoTotalDocumento'
                control={control}
                label='Monto Total Documento'
                variant='outlined'
                rules={{
                  required: true,
                  minLength: 1,
                  maxLength: 20,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    // {...field}
                    disabled={!Siguiente}
                    fullWidth
                    value={value}
                    onChange={(e) => {
                      const formattedValue = formatCurrency(e.target.value)
                      onChange(formattedValue) // Actualizar el valor en React Hook Form
                    }}
                    label='Monto Total Documento'
                    type='text'
                    variant='standard'
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '14px' },
                    }}
                    className={classes.textField}
                    error={!!errors.montoTotalDocumento}
                    helperText={errors.montoTotalDocumento?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controller
                name='compraExento'
                control={control}
                label='Compras sin Derecho a Crédito (exento)	'
                variant='outlined'
                rules={{
                  required: true,
                  minLength: 1,
                  maxLength: 20,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    // {...field}
                    disabled={!Siguiente}
                    fullWidth
                    label='Compras sin Derecho a Crédito (exento)	'
                    type='text'
                    variant='standard'
                    value={value}
                    onChange={(e) => {
                      const formattedValue = formatCurrency(e.target.value)
                      onChange(formattedValue) // Actualizar el valor en React Hook Form
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '14px' },
                    }}
                    className={classes.textField}
                    error={!!errors.compraExento}
                    helperText={errors.compraExento?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controller
                name='baseImponible'
                control={control}
                label='Base Imponible'
                variant='outlined'
                type='number'
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    disabled={!Siguiente}
                    fullWidth
                    label='Base Imponible'
                    type='text'
                    variant='standard'
                    value={value}
                    onChange={(e) => {
                      const formattedValue = formatCurrency(e.target.value)
                      onChange(formattedValue) // Actualizar el valor en React Hook Form
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '14px' },
                    }}
                    className={classes.textField}
                    error={!!errors.baseImponible}
                    helperText={errors.baseImponible?.message}
                  />
                )}
              />
            </Grid>

            {/* <Grid item xs={12} sm={4}>
              <Controller
                // as={TextField}
                control={control}
                name='Alicuota'
                label='Alicuota'
                variant='outlined'
                type='number'
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    disabled={!Siguiente}
                    fullWidth
                    label='Alicuota'
                    type='text'
                    variant='standard'
                    value={value}
                    onChange={(e) => {
                      const formattedValue = formatCurrency(e.target.value)
                      onChange(formattedValue) // Actualizar el valor en React Hook Form
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '14px' },
                    }}
                    className={classes.textField}
                    error={!!errors.Alicuota}
                    helperText={errors.Alicuota?.message}
                  />
                )}
              />
            </Grid> */}

            <Grid item xs={12} sm={4}>
              <Controller
                name='causadoRetenido'
                control={control}
                label='Impuesto de ISLR causado y retenido'
                variant='outlined'
                type='number'
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    // {...field}
                    fullWidth
                    disabled={!Siguiente}
                    label='Impuesto de ISLR causado y retenido'
                    type='text'
                    variant='standard'
                    value={value}
                    onChange={(e) => {
                      const formattedValue = formatCurrency(e.target.value)
                      onChange(formattedValue) // Actualizar el valor en React Hook Form
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '14px' },
                    }}
                    className={classes.textField}
                    error={!!errors.causadoRetenido}
                    helperText={errors.causadoRetenido?.message}
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12} sm={4}>
              <Controller
                name='impuestoRetenido'
                control={control}
                label='Impuesto Retenido'
                variant='outlined'
                type='number'
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    // {...field}
                    fullWidth
                    disabled={!Siguiente}
                    label='Impuesto Retenido'
                    type='text'
                    variant='standard'
                    value={value}
                    onChange={(e) => {
                      const formattedValue = formatCurrency(e.target.value)
                      onChange(formattedValue) // Actualizar el valor en React Hook Form
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '14px' },
                    }}
                    className={classes.textField}
                    error={!!errors.impuestoRetenido}
                    helperText={errors.impuestoRetenido?.message}
                  />
                )}
              />
            </Grid> */}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              textAlign: 'right',
              paddingRight: '20px',
              marginTop: '20px',
            }}
          >
            <Button
              type='submit'
              variant='contained'
              color='primary'
              disabled={!Siguiente}
            >
              Agregar
            </Button>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}
