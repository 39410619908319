import { useEffect, useState } from 'react'
import {
  retencionActive,
  startLoadRetenciones,
  StartqueryConceptosIsrl,
} from '../redux/actions/retencionActions'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

const useRetenciones = () => {
  const { retenciones, activeRetencion, addRetencion, conceptosIsrl } =
    useSelector((state) => state.retencion)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [openView, setOpenView] = useState(false)
  const [openAnularV, setopenAnularV] = useState(false)
  const [openFiltro, setOpenFiltro] = useState(false)

  const [loading, setLoading] = useState(true)
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    limit: 10,
    orderBy: 'id',
    order: 'asc',
  })

  useEffect(() => {
    dispatch(StartqueryConceptosIsrl())
  }, [dispatch])

  useEffect(() => {
    dispatch(
      startLoadRetenciones({
        limit: pagination.limit,
        page: pagination.pageIndex,
      })
    )
  }, [dispatch, pagination.pageIndex, pagination.limit])

  const hnadleAnularOrden = (retencion) => {
    // dispatch(startAnularOrden(orden))
  }
  const handleretencionActive = (retencion) => {
    dispatch(retencionActive(retencion))
  }

  const handleView = () => {
    setOpenView(true)
  }
  const handleCloseView = () => {
    setOpenView(false)
  }
  const handlecloseAnularV = () => {
    setopenAnularV(false)
  }
  const handleclosefiltro = () => {
    setOpenFiltro(false)
  }
  const hnadleOpenFiltro = () => {
    setOpenFiltro(true)
  }

  const goToBase = (e) => {
    navigate('/retenciones/iva-islr/Agregar', { replace: true })
  }

  return {
    retenciones,
    pagination,
    loading,
    openFiltro,
    openView,
    openAnularV,
    activeRetencion,
    addRetencion,
    conceptosIsrl,
    setopenAnularV,
    setOpenView,
    hnadleAnularOrden,
    hnadleOpenFiltro,
    handlecloseAnularV,
    handleCloseView,
    handleView,
    handleretencionActive,
    handleclosefiltro,
    goToBase,
    setPagination,
    setLoading,
  }
}

export default useRetenciones
