import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ModalGenerico from '../../commons/ui/Modal'
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate'

import { makeStyles } from '@material-ui/core/styles'
import {
  ConsultacontrolOrdenesSucursalLocalidad,
  ConsultacontrolSucursalLocalidad,
} from '../../../redux/actions/controlFacturaActions'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  inputMaterial: {
    width: '100%',
  },
  modal: {
    background: 'white',
    borderRadius: '5px',
    color: 'rgb(51, 51, 51)',
    maxHeight: '620px',
    minWidth: '300px',
    maxWidth: '700px',
    outline: 'none',
    padding: '10px',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-25%',
    transform: 'translate(-50%, -50%)',
  },
  logo: {
    width: '60%',
    height: '60%',
    objectFit: 'contain',
  },
}))

export default function ConsultaLocalidad({ open, handleClose, title }) {
  const dispatch = useDispatch()
  const { activeLocalidad } = useSelector((state) => state.localidad)
  const styles = useStyles()
  const [Control, setControl] = useState()
  const [ControlOrden, setControlOrden] = useState()

  useEffect(() => {
    async function fetchData() {
      // You can await here
      await dispatch(
        ConsultacontrolSucursalLocalidad(activeLocalidad, 'Localidad')
      ).then((res) => {
        console.log("🚀 ~ ).then ~ res:", res)
        setControl(res.payload.resultado)
      })
    }
    fetchData()
  }, [activeLocalidad, dispatch])

  useEffect(() => {
    async function fetchData() {
      // You can await here
      await dispatch(
        ConsultacontrolOrdenesSucursalLocalidad(activeLocalidad, 'Localidad')
      ).then((res) => {
        setControlOrden(res.payload.resultado)
      })
    }
    fetchData()
  }, [activeLocalidad, dispatch])

  //  const tipo = Control?.controlX?.tipo === 'Global' ? 'global' : 'individual'

  return (
    <ModalGenerico open={open} handleClose={() => handleClose()} title={title}>
      <div className='row'>
        {activeLocalidad.rutaLogo && (
          <div className='col-4'>
            <img
              className={styles.logo}
              src={
                activeLocalidad.rutaLogo ? (
                  activeLocalidad.rutaLogo
                ) : (
                  <AddPhotoAlternateIcon />
                )
              }
              alt='Logo'
            />
          </div>
        )}
        <div className='col-8'>
          <h3>{activeLocalidad.nombre}</h3>
        </div>
      </div>
      <div className='container'>
        <div className={styles.modal}>
          <div className='row'>
            <div className='col-md-auto'>
              <strong>N° Documento: </strong> {activeLocalidad.tipoDocumento}-
              {activeLocalidad.numeroDocumento}
            </div>
          </div>
          <div className='row'>
            <div className='col-md-auto'>
              <strong>Domicilio fiscal: </strong>
              {activeLocalidad.direccion}
            </div>
          </div>
          <div className='row'>
            <div className='col-md-auto'>
              <strong>Estado: </strong>
              {activeLocalidad?.Estado?.length > 0 &&
                activeLocalidad?.Estado[0].estado}
            </div>
          </div>
          <div className='row'>
            <div className='col-md-auto'>
              <strong>Municipio: </strong>{' '}
              {activeLocalidad?.Municipio[0].municipio}
            </div>
          </div>
          <div className='row'>
            <div className='col-md-auto'>
              <strong>Teléfonos: </strong>
              {activeLocalidad.telefono}
            </div>
          </div>
          <div className='row'>
            <div className='col-md-auto'>
              <strong>Correo: </strong>
              {activeLocalidad.correo}
            </div>
          </div>
          <div className='row'>
            <div className='col-md-auto'>
              <strong>Contribuyente especial: </strong>
              {String(activeLocalidad.contribuyenteEspecial) === 'true'
                ? 'Sí'
                : 'No'}
            </div>
          </div>
          <div className='row'>
            <div className='col-md-auto'>
              <strong>Tipo Proveedor: </strong>
              {activeLocalidad.tipoProveedor}
            </div>
          </div>
          {activeLocalidad.aerolinea && (
            <div className='row'>
              <div className='col-md-auto'>
                <strong>Aerolínea: </strong>Sí
              </div>
            </div>
          )}
          {/* <div className='row'>
            <div className='col-md-auto'>
              <strong>Codigo (Envio Masivo): </strong>
              {activeLocalidad._id}
            </div>
          </div> */}

          {/* nor control asignado para facturas */}
          <div className='row'>
            <div className='row mt-4'>
              {!Control?.controlX && (
                <div className='row'>
                  <div className='col-12 mb-2'>
                    <div className='text-center text-uppercase text-white bg-danger text-bold'>
                      NO tiene Nro. De control para Factura Asignado
                    </div>
                  </div>
                </div>
              )}

              <div className='col-12'>
                <strong className='text-uppercase text-info'>
                  N° Control: Facturas
                </strong>
              </div>

              <div className='col-12 '>
                <strong className='text-uppercase'>
                  Tipo: {Control?.controlX?.tipo}
                </strong>
              </div>
              <div className='row'>
                <div className='col-1 fw-bold'>Status</div>
                <div className='col-2 fw-bold'>Asignadas</div>
                <div className='col-2 fw-bold'>Actual</div>
                <div className='col-2 fw-bold'>Desde</div>
                <div className='col-2 fw-bold'>Hasta</div>
                <div className='col-2 fw-bold'>Disp.</div>
                <div className='col-1 fw-bold'>Serie</div>
              </div>
            </div>
            {Control?.controlX?.tipo === 'Global' &&
              Control?.controlX?.global.map((ind) => (
                <div
                  className='row mt-1 mb-2'
                  key={ind.id}
                  style={{ textAlign: 'left', fontSize: '10px' }}
                >
                  {ind.logEstatusRegistro === 'ACTIVO' ? (
                    <div
                      className='col-1'
                      style={{
                        color: 'green',
                        // backgroundColor: 'green',
                        fontSize: '10px',
                        textAlign: 'left',
                        fontWeight: 'bold',
                      }}
                    >
                      {ind.logEstatusRegistro}
                    </div>
                  ) : (
                    <div
                      className='col-1'
                      style={{
                        color: 'orange',
                        // backgroundColor: 'orange',
                        fontSize: '10px',
                        textAlign: 'left',
                        fontWeight: 'bold',
                      }}
                    >
                      {ind.logEstatusRegistro}
                    </div>
                  )}
                  <div className='col-2'>
                    {ind.nroControlHasta - ind.nroControlDesde}
                  </div>
                  <div className='col-2'>{ind.nroControlActual}</div>
                  <div className='col-2'>{ind.nroControlDesde}</div>
                  <div className='col-2'>{ind.nroControlHasta}</div>
                  <div className='col-2'>
                    {Number(ind.nroControlHasta) - Number(ind.nroControlActual)}
                  </div>

                  <div className='col-1'>{ind.serie}</div>
                </div>
              ))}

            {Control?.controlX?.tipo === 'Individual' &&
              Control?.controlX?.individual.map((ind) => (
                <div
                  className='row mt-1 mb-2'
                  key={ind.id}
                  style={{ textAlign: 'left', fontSize: '10px' }}
                >
                  {ind.logEstatusRegistro === 'ACTIVO' ? (
                    <div
                      className='col-1'
                      style={{
                        color: 'green',
                        // backgroundColor: 'green',
                        fontSize: '10px',
                        textAlign: 'left',
                        fontWeight: 'bold',
                      }}
                    >
                      {ind.logEstatusRegistro}
                    </div>
                  ) : (
                    <div
                      className='col-1'
                      style={{
                        color: 'orange',
                        // backgroundColor: 'orange',
                        fontSize: '10px',
                        textAlign: 'left',
                        fontWeight: 'bold',
                      }}
                    >
                      {ind.logEstatusRegistro}
                    </div>
                  )}
                  <div className='col-2'>
                    {ind.nroControlHasta - ind.nroControlDesde}
                  </div>
                  <div className='col-2'>{ind.nroControlActual}</div>
                  <div className='col-2'>{ind.nroControlDesde}</div>
                  <div className='col-2'>{ind.nroControlHasta}</div>
                  <div className='col-2'>
                    {Number(ind.nroControlHasta) - Number(ind.nroControlActual)}
                  </div>

                  <div className='col-1'>{ind.serie}</div>
                </div>
              ))}
          </div>

          {/* nor control asignado para ordenes y Guias */}
          <div className='row'>
            <div className='row mt-4'>
              {!ControlOrden?.controlX && (
                <div className='row'>
                  <div className='col-12 mb-2'>
                    <div className='text-center text-uppercase text-white bg-danger text-bold'>
                      NO tiene Nro. De control para Ordenes/Guias Asignado
                    </div>
                  </div>
                </div>
              )}

              <div className='col-12'>
                <strong className='text-uppercase text-success'>
                  N° Control: Ordenes/Guias
                </strong>
              </div>

              <div className='col-12 mb-2'>
                <strong className='text-uppercase'>
                  Tipo: {ControlOrden?.controlX?.tipo}
                </strong>
              </div>
              <div className='row'>
                <div className='col-1 fw-bold'>Status</div>
                <div className='col-2 fw-bold'>Asignadas</div>
                <div className='col-2 fw-bold'>Actual</div>
                <div className='col-2 fw-bold'>Desde</div>
                <div className='col-2 fw-bold'>Hasta</div>
                <div className='col-2 fw-bold'>Disp.</div>
                <div className='col-1 fw-bold'>Serie</div>
              </div>
            </div>
            {ControlOrden?.controlX?.tipo === 'Global' &&
              ControlOrden?.controlX?.global.map((ind) => (
                <div
                  className='row mt-1 mb-2'
                  key={ind.id}
                  style={{ textAlign: 'left', fontSize: '10px' }}
                >
                  {ind.logEstatusRegistro === 'ACTIVO' ? (
                    <div
                      className='col-1'
                      style={{
                        color: 'green',
                        // backgroundColor: 'green',
                        fontSize: '10px',
                        textAlign: 'left',
                        fontWeight: 'bold',
                      }}
                    >
                      {ind.logEstatusRegistro}
                    </div>
                  ) : (
                    <div
                      className='col-1'
                      style={{
                        color: 'orange',
                        // backgroundColor: 'orange',
                        fontSize: '10px',
                        textAlign: 'left',
                        fontWeight: 'bold',
                      }}
                    >
                      {ind.logEstatusRegistro}
                    </div>
                  )}
                  <div className='col-2'>{ind.cantidadFacturas}</div>
                  <div className='col-2'>{ind.nroControlActual}</div>
                  <div className='col-2'>{ind.nroControlDesde}</div>
                  <div className='col-2'>{ind.nroControlHasta}</div>
                  <div className='col-2'>
                    {Number(ind.nroControlHasta) - Number(ind.nroControlActual)}
                  </div>

                  <div className='col-1'>{ind.serie}</div>
                </div>
              ))}

            {ControlOrden?.controlX?.tipo === 'Individual' &&
              ControlOrden?.controlX?.individual.map((ind) => (
                <div
                  className='row mt-1 mb-2'
                  key={ind.id}
                  style={{ textAlign: 'left', fontSize: '10px' }}
                >
                  {ind.logEstatusRegistro === 'ACTIVO' ? (
                    <div
                      className='col-1'
                      style={{
                        color: 'green',
                        // backgroundColor: 'green',
                        fontSize: '10px',
                        textAlign: 'left',
                        fontWeight: 'bold',
                      }}
                    >
                      {ind.logEstatusRegistro}
                    </div>
                  ) : (
                    <div
                      className='col-1'
                      style={{
                        color: 'orange',
                        // backgroundColor: 'orange',
                        fontSize: '10px',
                        textAlign: 'left',
                        fontWeight: 'bold',
                      }}
                    >
                      {ind.logEstatusRegistro}
                    </div>
                  )}
                  <div className='col-2'>{ind.cantidadFacturas}</div>
                  <div className='col-2'>{ind.nroControlActual}</div>
                  <div className='col-2'>{ind.nroControlDesde}</div>
                  <div className='col-2'>{ind.nroControlHasta}</div>
                  <div className='col-2'>
                    {Number(ind.nroControlHasta) - Number(ind.nroControlActual)}
                  </div>

                  <div className='col-1'>{ind.serie}</div>
                </div>
              ))}
          </div>
        </div>
        {/* nro control Odenes Guias */}
      </div>
    </ModalGenerico>
  )
}
