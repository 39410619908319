import { Navigate } from 'react-router-dom'
import Banco from './components/mantenimiento/Banco/Banco'
import Divisa from './components/mantenimiento/Divisa/Divisa'
import Localidad from './components/mantenimiento/Localidad/Localidad'
import Sucursal from './components/mantenimiento/Sucursal/Sucursal'
import LibroVentas from './components/mantenimiento/Reportes/LibroVentas'
import LogsEmail from './components/mantenimiento/Reportes/LogsEmail/LogsEmail'
import ReportSeniat from './components/mantenimiento/Reportes/ReportSeniat/ReportSeniat'
import Logs from './components/mantenimiento/Reportes/Logs/Logs'

import Pais from './components/mantenimiento/Pais/Pais'
import Estado from './components/mantenimiento/Estado/Estado'
import Municipio from './components/mantenimiento/Municipio/Municipio'
import TipoGravamen from './components/mantenimiento/TipoGravamen/TipoGravamen'
import ValorGravamen from './components/mantenimiento/ValorGravamen/ValorGravamen'
import CategoriaNota from './components/mantenimiento/CategoriaNota/CategoriaNota'
import Descuento from './components/mantenimiento/Descuento/Descuento'
import Incoterm from './components/mantenimiento/Incoterm/Incoterm'
import Producto from './components/mantenimiento/Producto/Producto'
import Page404 from './components/Page404'
import Linear from './components/charts/Linear'
import { Home } from './components/home/Home'
// import { Dashboard } from './components/home/Dashboard';
// import TheLayout from './containers/TheLayout'
// import ListarFacturaGeneral from './components/facturacion/General/ListarFacturaGeneral'
import { FacturaGeneralBase } from './components/facturacion/General/CrearFactura/FacturaGeneralBase'
// import { FacturaGeneralBaseModif } from './components/facturacion/General/FacturaGeneralBaseModif'
import FacturaCompra from './components/facturacion/facturaCompra/FacturaCompra'
import { FacturaCompraBase } from './components/facturacion/facturaCompra/FacturaCompraBase'
import Nota from './components/facturacion/nota/Nota'
import NotaBase from './components/facturacion/nota/NotaBase'
import Role from './components/seguridad/Role/Role'
import Usuario from './components/seguridad/Usuario/Usuario'
import Modulo from './components/seguridad/Modulo/Modulo'
import FacturaAerea from './components/facturacion/facturaAerea/FacturaAerea'
import Facturas from './components/facturacion/General/ListarFacturaGeneral/Facturas'

// ordenes de despacho
import Ordenes from './components/facturacion/orden/Ordenes'
import OdenesGuiasBase from './components/facturacion/orden/add-ordenes-guias/OrdenesGuiasBase'
import LibroOrdenes from './components/mantenimiento/Reportes/LibroOrdenes'
import Iva from './components/retenciones/retenciones-iva/Iva'
import CrearRetencionesIva from './components/retenciones/retenciones-iva/AddretencionesIva/CrearRetencionesIva'
import ConsultaConceptosIslr from './components/retenciones/ConsultaconceptoIslr/consultaConceptosIslr'

const routes = [
  // { path: '/home', exact: "true", name: 'Home', component: <Home /> },
  // { path: '/home', exact: "true", name: 'Home', component: Home },
  {
    path: '/facturacion/generales',
    exact: 'true',
    name: 'Facturas Generales',
    element: <Facturas />, //  <ListarFacturaGeneral />,
    permiso: ['user', 'admin'],
  },
  {
    path: '/facturacion/generales/facturaGeneral',
    exact: 'true',
    name: 'Agregar Factura General',
    element: <FacturaGeneralBase />,
    permiso: ['user', 'admin'],
  },

  {
    path: '/facturacion/generales/notas',
    exact: 'true',
    name: 'Notas Generales',
    element: <Nota />,
    permiso: ['user', 'admin'],
  },
  {
    path: '/facturacion/generales/notas/nota',
    exact: 'true',
    name: 'Agregar Notas',
    element: <NotaBase />,
    permiso: ['user', 'admin'],
  },

  {
    path: '/facturacion/OrdenesGuias',
    exact: 'true',
    name: 'Ordenes de Despacho',
    element: <Ordenes />,
    permiso: ['user', 'admin'],
  },

  {
    path: '/facturacion/OrdenesGuias/CrearOrdenesGuias',
    exact: 'true',
    name: 'Agregar Ordenes/Guias',
    element: <OdenesGuiasBase />,
    permiso: ['user', 'admin'],
  },

  {
    path: '/facturacion/aereas',
    exact: 'true',
    name: 'Facturas Aéreas',
    element: <FacturaAerea />,
    permiso: ['user', 'admin'],
  },
  {
    path: '/facturacion/licores',
    exact: 'true',
    name: 'Facturas de Licores',
    element: <Page404 />,
    permiso: ['user', 'admin'],
  },
  {
    path: '/facturacion/compras',
    exact: 'true',
    name: 'Facturas de Compras',
    element: <FacturaCompra />,
    permiso: ['user', 'admin'],
  },
  {
    path: '/facturacion/facturaCompra',
    exact: 'true',
    name: 'Facturas de Compras',
    element: <FacturaCompraBase />,
    permiso: ['user', 'admin'],
  },
  {
    path: '/facturacion/notas',
    exact: 'true',
    name: 'Notas',
    element: <Nota />,
    permiso: ['user', 'admin'],
  },
  {
    path: '/retenciones/iva-islr',
    exact: 'true',
    name: 'Retenciones IVA-ISLR',
    element: <Iva />,
    permiso: ['user', 'admin'],
  },

  {
    path: '/retenciones/iva-islr/Agregar',
    exact: 'true',
    name: 'Agregar retencion Iva-islr',
    element: <CrearRetencionesIva />,
    permiso: ['user', 'admin'],
  },
  {
    path: '/retenciones/consulta-conceptos-islr',
    exact: 'true',
    name: 'Consulta Conceptos ISLR',
    element: <ConsultaConceptosIslr />,
    permiso: ['user', 'admin'],
  },

  // {
  //   path: '/retenciones/isrl',
  //   exact: 'true',
  //   name: 'Retenciones ISRL',
  //   element: <Page404 />,
  //   permiso: ['user', 'admin'],
  // },
  {
    path: '/mantenimiento/Banco',
    exact: 'true',
    name: 'Banco',
    element: <Banco />,
    permiso: ['admin'],
  },
  {
    path: '/mantenimiento/Localidad',
    exact: 'true',
    name: 'Razón Social',
    element: <Localidad />,
    permiso: ['admin'],
  },
  {
    path: '/mantenimiento/Sucursal',
    exact: 'true',
    name: 'Sucursal',
    element: <Sucursal />,
    permiso: ['admin'],
  },
  {
    path: '/mantenimiento/Producto',
    exact: 'true',
    name: 'Producto',
    element: <Producto />,
    permiso: ['admin'],
  },
  {
    path: '/mantenimiento/TipoGravamen',
    exact: 'true',
    name: 'Tipo Alícuota',
    element: <TipoGravamen />,
    permiso: ['admin'],
  },
  {
    path: '/mantenimiento/ValorGravamen',
    exact: 'true',
    name: 'Valor Gravamen',
    element: <ValorGravamen />,
    permiso: ['admin'],
  },
  {
    path: '/mantenimiento/Divisa',
    exact: 'true',
    name: 'Divisa',
    element: <Divisa />,
    permiso: ['admin'],
  },
  {
    path: '/mantenimiento/TasaCambio',
    exact: 'true',
    name: 'Tasa Cambio',
    element: <Page404 />,
    permiso: ['admin'],
  },
  {
    path: '/mantenimiento/Descuento',
    exact: 'true',
    name: 'Descuento',
    element: <Descuento />,
    permiso: ['admin'],
  },
  {
    path: '/mantenimiento/IVA',
    exact: 'true',
    name: 'IVA',
    element: <Page404 />,
    permiso: ['admin'],
  },
  {
    path: '/mantenimiento/INCOTERM',
    exact: 'true',
    name: 'Incoterm',
    element: <Incoterm />,
    permiso: ['admin'],
  },
  {
    path: '/mantenimiento/Pais',
    exact: 'true',
    name: 'Pais',
    element: <Pais />,
    permiso: ['admin'],
  },
  {
    path: '/mantenimiento/Estado',
    exact: 'true',
    name: 'Estado',
    element: <Estado />,
    permiso: ['admin'],
  },
  {
    path: '/mantenimiento/Municipio',
    name: 'Municipio',
    element: <Municipio />,
    permiso: ['admin'],
  },
  {
    path: '/mantenimiento/CategoriaNota',
    exact: 'true',
    name: 'Categoría Nota',
    element: <CategoriaNota />,
    permiso: ['admin'],
  },
  {
    path: '/charts/Linear',
    exact: 'true',
    name: 'Operativos',
    element: <Linear />,
    permiso: ['user', 'admin'],
  },
  {
    path: '/reportes/libroVentas',
    exact: 'true',
    name: 'LibrosVenta',
    element: <LibroVentas />,
    permiso: ['user', 'admin'],
  },
  {
    path: '/reportes/ReporteOrdenes',
    exact: 'true',
    name: 'libroOrdenes',
    element: <LibroOrdenes />,
    permiso: ['user', 'admin'],
  },
  {
    path: '/reportes/logs',
    exact: 'true',
    name: 'Logs',
    element: <Logs />,
    permiso: ['user', 'admin'],
  },
  {
    path: '/reportes/logsEmail',
    exact: 'true',
    name: 'LogsEmail',
    element: <LogsEmail />,
    permiso: ['user', 'admin'],
  },
  {
    path: '/reportes/ReportSeniat',
    exact: 'true',
    name: 'ReportSeniat',
    element: <ReportSeniat />,
    permiso: ['user', 'admin'],
  },
  {
    path: '/seguridad/clientes',
    exact: 'true',
    name: 'Clientes',
    element: <Page404 />,
    permiso: ['admin'],
  },
  {
    path: '/seguridad/usuarios',
    exact: 'true',
    name: 'Usuarios',
    element: <Usuario />,
    permiso: ['user', 'admin'],
  },
  {
    path: '/seguridad/roles',
    exact: 'true',
    name: 'Roles',
    element: <Role />,
    permiso: ['admin'],
  },
  {
    path: '/seguridad/modulos',
    exact: 'true',
    name: 'Módulos',
    element: <Modulo />,
    permiso: ['admin'],
  },
  {
    path: 'home',
    exact: 'true',
    element: <Home />,
    permiso: ['user', 'admin'],
  },
  {
    path: '*',
    exact: 'true',
    element: <Navigate to='/home' />,
    permiso: ['user', 'admin'],
  },
]

export default routes
