import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { startretencionAddItem } from '../../../../redux/actions/retencionActions'
import { useDispatch } from 'react-redux'
import useRetenciones from '../../../../hooks/useRetenciones'
import { formatCurrency } from '../../../../Helpers/formater'
import { errorSetActive } from '../../../../redux/actions/errorActions'

const enumTipoRegistro = [
  { value: '01-REG', label: '01-REG' },
  { value: '02-AJUSND', label: '02-AJUSND' },
  { value: '03-AJUSNC', label: '03-AJUSNC' },
  // { value: '03-ANULA', label: '03-ANULA' },
]

const enumAlicuota = [
  { value: 8, label: '8 %' },
  { value: 16, label: '16 %' },
  { value: 31, label: '31 %' },
]

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: 0,
      width: '100%',
    },
  },
  CardHeader: {
    color: 'white',
    padding: '8px',
  },
  cardheader_icons: {
    fontSize: '1.5rem',
  },
  texto: {
    fontSize: '12px',
    fontWeight: 'bolder',
  },
  textField: {
    '& .MuiInputBase-input': {
      fontSize: '12px',
      fontWeight: 'bolder',
    },
  },
  textodireccion: {
    fontSize: '12px',
    color: '#9e9e9e',
    fontWeight: 'bolder',
  },
  select: {
    fontSize: '12px',
    fontWeight: 'bold',
    alignSelf: 'center',
  },
  sublabel: {
    fontSize: '12px',
    color: '#546E7A',
    alignItems: 'center',
    margin: 0,
  },
}))

export default function DetalleRetencionIva({ Siguiente }) {
  const [disabled, setdisabled] = useState(true)
  const [disabledNrodocumento, setdisabledNrodocumento] = useState(true)

  const classes = useStyles()
  const dispatch = useDispatch()
  const { addRetencion } = useRetenciones()

  const schema = yup.object().shape({
    fechaDocumento: yup
      .date()
      .typeError('Debe ser una fecha válida')
      .required('Fecha del Documento es requerida'),
    // tipodocumento: yup
    //   .string()
    //   .required('Debe seleccionar el Tipo de Documento')
    //   .oneOf(['Factura', 'NotaC', 'NotaD']),
    nroDocumento: yup.string().max(20),
    // .required('Nro de Documento es requerido')
    nroControlDocumento: yup
      .string()
      .required('Nro de Control del Documento es requerido')
      .max(20),
    nroDocumentoAfectado: yup.string().max(20),
    montoTotalDocumento: yup
      .number()
      .required('Monto Total del Documento es requerido'),
    compraExento: yup.number(),
    tipoTransaccion: yup
      .string()
      .required('Tipo de Transacción es requerido')
      .oneOf(['01-REG', '02-AJUSND', '03-AJUSNC', '04-ANULA']),
    baseImponible: yup.number().required('Base Imponible es requerida'),
    Alicuota: yup.number().oneOf([8, 16, 31]),
    impuestoCausado: yup.number().required('Impuesto Causado es requerido'),
    impuestoRetenido: yup.number().required('Impuesto Retenido es requerido'),
    nroNota: yup.string().max(20),
    // .required('Nro de Nota de Crédito es requerido')
  })

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    reset({
      fechaDocumento: '',
      nroDocumento: '',
      nroControlDocumento: '',
      nroDocumentoAfectado: '',
      montoTotalDocumento: 0,
      compraExento: 0,
      tipoTransaccion: '',
      baseImponible: 0,
      Alicuota: 0,
      impuestoCausado: 0,
      impuestoRetenido: 0,
      nroNota: '',
    })
  }, [isSubmitSuccessful, reset, dispatch])

  const onSubmit = (data) => {
    try {
      const lstRet = addRetencion?.lstRetenciones
      console.log('🚀 ~ onSubmit ~ data: DETAlllee', data)

      const dataEnviar = {
        nroNota: data.nroNota === '' ? undefined : data.nroNota,
        nroDocumento: data.nroDocumento === '' ? undefined : data.nroDocumento,
        nroControlDocumento: data.nroControlDocumento,
        nroDocumentoAfectado:
          data.nroDocumentoAfectado === ''
            ? undefined
            : data.nroDocumentoAfectado,
        impuestoRetenido: data.impuestoRetenido,
        impuestoCausado: data.impuestoCausado,
        Alicuota: data.Alicuota,
        baseImponible: data.baseImponible,
        tipoTransaccion: data.tipoTransaccion,
        compraExento: data.compraExento,
        montoTotalDocumento: data.montoTotalDocumento,
        fechaDocumento: data.fechaDocumento,
      }
      console.log('🚀 ~ onSubmit ~ dataEnviar:', dataEnviar)

      // validar que en el array de lstretenciones no se repita el tipoTransaccion, nroDocumento y nroControlDocumento y no permitir que lo guarde y de un alerta en toast que diga que no se puede
      if (
        lstRet.some(
          (ret) =>
            ret.nroDocumento === dataEnviar.nroDocumento &&
            ret.nroControlDocumento === dataEnviar.nroControlDocumento &&
            ret.tipoTransaccion === dataEnviar.tipoTransaccion
        )
      ) {
        throw new Error(
          'No se puede agregar la retencion porque ya existe una retencion con el mismo nroDocumento, nroControlDocumento y tipoTransaccion'
        )
      }

      if (
        lstRet.some(
          (ret) =>
            ret.nroNota === dataEnviar.nroNota &&
            ret.nroControlDocumento === dataEnviar.nroControlDocumento &&
            ret.tipoTransaccion === dataEnviar.tipoTransaccion
        )
      ) {
        throw new Error(
          'No se puede agregar la retencion porque ya existe una retencion con el mismo nroNota, nroControlDocumento y tipoTransaccion'
        )
      }
      console.log('🚀 ~ onSubmit ~ dataEnviar:', dataEnviar)
      // dispatch(startValidarRetenciones(data))
      dispatch(startretencionAddItem(dataEnviar))
      reset()
    } catch (error) {
      console.log('🚀 ~ onSubmit ~ error:', error.message)
      dispatch(
        errorSetActive(error?.message || 'ocurrido un error de red', 'error')
      )
    }
  }

  const handlechangediabled = (e) => {
    const { value } = e.target
    if (value === '01-REG') {
      setdisabled(true)
    } else {
      setdisabled(false)
    }
    if (value === '02-AJUSND' || value === '03-AJUSNC') {
      setdisabledNrodocumento(true)
    } else {
      setdisabledNrodocumento(false)
    }
  }

  return (
    <Card
      style={{
        minHeight: '40vh',
        // maxWidth: 500,
        boxShadow:
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      }}
    >
      <CardHeader
        style={{
          backgroundColor: Siguiente ? '#054477' : '#9e9e9e',
        }}
        className={classes.CardHeader}
        title={
          <div
            style={{ textAlign: 'left', margin: 0, padding: 0, width: '100%' }}
          >
            <p style={{ margin: 0, fontSize: '20px', fontWeight: 'bolder' }}>
              Detalle Retención IVA
            </p>
          </div>
        }
      />
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Controller
                name='fechaDocumento'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={!Siguiente}
                    label='Fecha Documento'
                    type='date'
                    inputProps={{
                      max: new Date().toISOString().split('T')[0], // Disable future dates
                    }}
                    fullWidth
                    variant='standard'
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '14px' },
                    }}
                    className={classes.textField}
                    error={!!errors.fechaDocumento}
                    helperText={errors.fechaDocumento?.message}
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <InputLabel
                style={{
                  fontSize: '12px',
                  color: '#9e9e9e',
                }}
              >
                Tipo Transacción
              </InputLabel>
              <Controller
                control={control}
                name='tipoTransaccion'
                defaultValue=''
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Select
                    labelId='tipoTransaccion'
                    id='tipoTransaccion'
                    name='tipoTransaccion'
                    fullWidth
                    defaultValue=''
                    disabled={!Siguiente}
                    className={classes.select}
                    onChange={(e) => {
                      handlechangediabled(e)
                      onChange(e)
                    }}
                    value={value}
                    error={!!error}
                    label='Tipo Transacción'
                  >
                    {enumTipoRegistro.map((option) => {
                      return (
                        <MenuItem
                          className={classes.sublabel}
                          key={option.key}
                          value={option.value}
                        >
                          {option.value}
                        </MenuItem>
                      )
                    })}
                  </Select>
                )}
              />
              <FormHelperText error>
                {errors?.tipoTransaccion?.message}
              </FormHelperText>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controller
                name='nroDocumento'
                control={control}
                label='Nro Documento'
                variant='outlined'
                rules={{
                  required: true,
                  minLength: 1,
                  maxLength: 20,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={!Siguiente || disabledNrodocumento}
                    label='Nro Documento'
                    type='text'
                    fullWidth
                    variant='standard'
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '14px' },
                    }}
                    className={classes.textField}
                    error={!!errors.nroDocumento}
                    helperText={errors.nroDocumento?.message}
                  />
                )}
              />
            </Grid>
            {/*  4 */}
            <Grid item xs={12} sm={4}>
              <Controller
                name='nroControlDocumento'
                control={control}
                label='Nro Control Documento'
                variant='outlined'
                rules={{
                  required: true,
                  minLength: 1,
                  maxLength: 20,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={!Siguiente}
                    fullWidth
                    label='Nro Control Documento'
                    type='text'
                    variant='standard'
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '14px' },
                    }}
                    className={classes.textField}
                    error={!!errors.nroControlDocumento}
                    helperText={errors.nroControlDocumento?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controller
                disabled={!Siguiente || disabled}
                name='nroNota'
                control={control}
                label='Nro Nota de Crédito/Debito'
                variant='outlined'
                rules={{
                  required: true,
                  minLength: 1,
                  maxLength: 20,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={!Siguiente || disabled}
                    fullWidth
                    label='Nro Nota de Crédito/Debito'
                    type='text'
                    variant='standard'
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '14px' },
                    }}
                    className={classes.textField}
                    error={!!errors.nroNota}
                    helperText={errors.nroNota?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                disabled={!Siguiente || disabled}
                name='nroDocumentoAfectado'
                control={control}
                label='Nro Documento Afectado'
                variant='outlined'
                rules={{
                  required: true,
                  minLength: 1,
                  maxLength: 20,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={!Siguiente || disabled}
                    fullWidth
                    label='Nro Documento Afectado'
                    type='text'
                    variant='standard'
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '14px' },
                    }}
                    className={classes.textField}
                    error={!!errors.nroDocumentoAfectado}
                    helperText={errors.nroDocumentoAfectado?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controller
                name='montoTotalDocumento'
                control={control}
                label='Monto Total Documento'
                variant='outlined'
                rules={{
                  required: true,
                  minLength: 1,
                  maxLength: 20,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    // {...field}
                    disabled={!Siguiente}
                    fullWidth
                    value={value}
                    onChange={(e) => {
                      const formattedValue = formatCurrency(e.target.value)
                      onChange(formattedValue) // Actualizar el valor en React Hook Form
                    }}
                    label='Monto Total Documento'
                    type='text'
                    variant='standard'
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '14px' },
                    }}
                    className={classes.textField}
                    error={!!errors.montoTotalDocumento}
                    helperText={errors.montoTotalDocumento?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controller
                name='compraExento'
                control={control}
                label='Compras sin Derecho a Crédito (exento)	'
                variant='outlined'
                rules={{
                  required: true,
                  minLength: 1,
                  maxLength: 20,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    // {...field}
                    disabled={!Siguiente}
                    fullWidth
                    label='Compras sin Derecho a Crédito (exento)	'
                    type='text'
                    variant='standard'
                    value={value}
                    onChange={(e) => {
                      const formattedValue = formatCurrency(e.target.value)
                      onChange(formattedValue) // Actualizar el valor en React Hook Form
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '14px' },
                    }}
                    className={classes.textField}
                    error={!!errors.compraExento}
                    helperText={errors.compraExento?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controller
                name='baseImponible'
                control={control}
                label='Base Imponible'
                variant='outlined'
                type='number'
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    disabled={!Siguiente}
                    fullWidth
                    label='Base Imponible'
                    type='text'
                    variant='standard'
                    value={value}
                    onChange={(e) => {
                      const formattedValue = formatCurrency(e.target.value)
                      onChange(formattedValue) // Actualizar el valor en React Hook Form
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '14px' },
                    }}
                    className={classes.textField}
                    error={!!errors.baseImponible}
                    helperText={errors.baseImponible?.message}
                  />
                )}
              />
            </Grid>

            {/* <Grid item xs={12} sm={4}>
              <Controller
                // as={TextField}
                control={control}
                name='Alicuota'
                label='Alicuota'
                variant='outlined'
                type='number'
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    disabled={!Siguiente}
                    fullWidth
                    label='Alicuota'
                    type='text'
                    variant='standard'
                    value={value}
                    onChange={(e) => {
                      const formattedValue = formatCurrency(e.target.value)
                      onChange(formattedValue) // Actualizar el valor en React Hook Form
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '14px' },
                    }}
                    className={classes.textField}
                    error={!!errors.Alicuota}
                    helperText={errors.Alicuota?.message}
                  />
                )}
              />
            </Grid> */}

            <Grid
              item
              xs={12}
              sm={4}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <InputLabel
                style={{
                  fontSize: '12px',
                  color: '#9e9e9e',
                }}
              >
                Alicuota
              </InputLabel>
              <Controller
                control={control}
                name='Alicuota'
                defaultValue=''
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Select
                    labelId='Alicuota'
                    id='Alicuota'
                    fullWidth
                    defaultValue=''
                    disabled={!Siguiente}
                    className={classes.select}
                    onChange={onChange}
                    value={value}
                    error={!!error}
                    label='Alicuota'
                  >
                    {enumAlicuota.map((option) => {
                      return (
                        <MenuItem
                          className={classes.sublabel}
                          key={option.key}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      )
                    })}
                  </Select>
                )}
              />
              <FormHelperText error>{errors?.Alicuota?.message}</FormHelperText>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controller
                name='impuestoCausado'
                control={control}
                label='Impuesto Causado'
                variant='outlined'
                type='number'
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    // {...field}
                    fullWidth
                    disabled={!Siguiente}
                    label='Impuesto Causado'
                    type='text'
                    variant='standard'
                    value={value}
                    onChange={(e) => {
                      const formattedValue = formatCurrency(e.target.value)
                      onChange(formattedValue) // Actualizar el valor en React Hook Form
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '14px' },
                    }}
                    className={classes.textField}
                    error={!!errors.impuestoCausado}
                    helperText={errors.impuestoCausado?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name='impuestoRetenido'
                control={control}
                label='Impuesto Retenido'
                variant='outlined'
                type='number'
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    // {...field}
                    fullWidth
                    disabled={!Siguiente}
                    label='Impuesto Retenido'
                    type='text'
                    variant='standard'
                    value={value}
                    onChange={(e) => {
                      const formattedValue = formatCurrency(e.target.value)
                      onChange(formattedValue) // Actualizar el valor en React Hook Form
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '14px' },
                    }}
                    className={classes.textField}
                    error={!!errors.impuestoRetenido}
                    helperText={errors.impuestoRetenido?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              textAlign: 'right',
              paddingRight: '20px',
              marginTop: '20px',
            }}
          >
            <Button
              type='submit'
              variant='contained'
              color='primary'
              disabled={!Siguiente}
            >
              Agregar
            </Button>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}
