export const types = {
  authCheckingFinish: '[Auth] Finish checking login state',
  authStartLogin: '[Auth] Start login',
  authStartTokenRenew: '[Auth] Start token renew',
  authLogout: '[Auth] logout',
  testLogin: '[Auth] Testing de Login ',

  errorManejo: '[Error] ADD Manejo de Error',
  errorClearActive: '[Error] clear Manejo de Error',
  setStatusLoaidng: '[Error]  Cambio de Loading',

  bancoCloseModal: '[Banco] Close modal',
  bancoOpenModal: '[Banco] Open modal',
  bancoSetActive: '[Banco] Banco set active',
  bancoAddNew: '[Banco] Add New',
  bancoClearActive: '[Banco] Clear active',
  bancoUpdated: '[Banco] Banco updated',
  bancoDelete: '[Banco] Banco delete',
  bancoLoaded: '[Banco] Banco Loaded',
  bancoCloseModalDelete: '[Banco] Close modal delete',
  bancoOpenModalDelete: '[Banco] Open modal delete',
  bancoReactived: '[Banco] Banco reactived',
  queryConceptosIsrl: '[Banco] Query Conceptos Isrl',

  divisaCloseModal: '[Divisa] Close modal',
  divisaOpenModal: '[Divisa] Open modal',
  divisaSetActive: '[Divisa] Divisa set active',
  divisaAddNew: '[Divisa] Add New',
  divisaClearActive: '[Divisa] Clear active Divisa',
  divisaUpdated: '[Divisa] Divisa updated',
  divisaDelete: '[Divisa] Divisa delete',
  divisaLoaded: '[Divisa] Divisas Loaded',
  divisaCloseModalDelete: '[Divisa] Close modal delete',
  divisaOpenModalDelete: '[Divisa] Open modal delete',
  divisaReactived: '[Divisa] Divisa reactived',

  paisCloseModal: '[País] Close modal',
  paisOpenModal: '[País] Open modal',
  paisSetActive: '[País] País set active',
  paisAddNew: '[País] Add New',
  paisClearActive: '[País] Clear active',
  paisUpdated: '[País] País updated',
  paisDelete: '[País] País delete',
  paisLoaded: '[País] País Loaded',
  paisCloseModalDelete: '[País] Close modal delete',
  paisOpenModalDelete: '[País] Open modal delete',
  paisReactived: '[País] País reactived',

  estadoCloseModal: '[Estado] Close modal',
  estadoOpenModal: '[Estado] Open modal',
  estadoSetActive: '[Estado] Estado set active',
  estadoAddNew: '[Estado] Add New',
  estadoClearActive: '[Estado] Clear active',
  estadoUpdated: '[Estado] Estado updated',
  estadoDelete: '[Estado] Estado delete',
  estadoLoaded: '[Estado] Estado Loaded',
  estadoCloseModalDelete: '[Estado] Close modal delete',
  estadoOpenModalDelete: '[Estado] Open modal delete',
  estadoReactived: '[Estado] Estado reactived',

  municipioCloseModal: '[Municipio] Close modal',
  municipioOpenModal: '[Municipio] Open modal',
  municipioSetActive: '[Municipio] Municipio set active',
  municipioAddNew: '[Municipio] Add New',
  municipioClearActive: '[Municipio] Clear active',
  municipioUpdated: '[Municipio] Municipio updated',
  municipioDelete: '[Municipio] Municipio delete',
  municipioLoaded: '[Municipio] Municipio Loaded',
  municipioLoadedByEstado: '[Municipio] Municipio Loaded by Estado',
  municipioCloseModalDelete: '[Municipio] Close modal delete',
  municipioOpenModalDelete: '[Municipio] Open modal delete',
  municipioReactived: '[Municipio] Municipio reactived',

  localidadCloseModal: '[Localidad] Close modal',
  localidadOpenModal: '[Localidad] Open modal',
  localidadSetActive: '[Localidad] Localidad set active',
  localidadAddNew: '[Localidad] Add New',
  localidadClearActive: '[Localidad] Clear active',
  localidadClear: '[Localidad] Clear Total',
  localidadUpdated: '[Localidad] Localidad updated',
  certificadoUpdated: '[Localidad] Actualizar Certficado de localidad',
  localidadDelete: '[Localidad] Localidad delete',
  localidadLoaded: '[Localidad] Localidad Loaded',
  localidadOpenModalDelete: '[Localidad] Open modal delete',
  localidadCloseModalDelete: '[Localidad] Close modal delete',
  localidadOpenModalShow: '[Localidad] Open modal show',
  localidadCloseModalShow: '[Localidad] Close modal show',
  localidadReactived: '[Localidad] Localidad reactived',
  localidadMunicipioSelected: '[Localidad] Municipio selected',
  localidadCargaFacturasJsonSetActive:
    '[Localidad] Carga facturas json set active',
  localidadcuentaTercerosAddNew: '[FacturaGeneral] Cuenta terceros add new',
  localidadcuentaTercerosUpdated: '[FacturaGeneral] Cuenta terceros updated',
  localidadcuentaTercerosDelete: '[FacturaGeneral] Cuenta terceros delete',
  CtrNrotofacturasLoaded: '[Localidad] load facturas Automaticas de localidad',
  updateAsignaNroFacturasLocalidad:
    '[Localidad] actualizar el Nrofactura Automatico',

  sucursalCloseModal: '[Sucursal] Close modal',
  sucursalOpenModal: '[Sucursal] Open modal',
  sucursalSetActive: '[Sucursal] Sucursal set active',
  sucursalAddNew: '[Sucursal] Add New',
  sucursalClearActive: '[Sucursal] Clear active',
  sucursalUpdated: '[Sucursal] Sucursal updated',
  sucursalDelete: '[Sucursal] Sucursal delete',
  sucursalLoaded: '[Sucursal] Sucursal Loaded',
  sucursalLoadedByLocalidad: '[Sucursal] Sucursal LoadedByLocalidad',
  sucursalLoadedByLocalidadID: '[Sucursal] Sucursal LoadedByLocalidadForID',
  sucursalClearByLocalidadID:
    '[Sucursal] Sucursal Clear LoadedByLocalidadForID',
  sucursalClear: '[Sucursal] Sucursal Clear all',

  sucursalOpenModalDelete: '[Sucursal] Open modal delete',
  sucursalCloseModalDelete: '[Sucursal] Close modal delete',
  sucursalOpenModalShow: '[Sucursal] Open modal show',
  sucursalCloseModalShow: '[Sucursal] Close modal show',
  sucursalReactived: '[Sucursal] Sucursal reactived',
  updateAsignaNroFacturasSucursal:
    '[Sucursal] actualizar el Nrofactura Automatico',
  CtrNrotofacturasLoadedSucursal:
    '[Sucursal] load facturas Automaticas de localidad',

  categoriaNotaCloseModal: '[CategoriaNota] Close modal',
  categoriaNotaOpenModal: '[CategoriaNota] Open modal',
  categoriaNotaSetActive: '[CategoriaNota] CategoriaNota set active',
  categoriaNotaAddNew: '[CategoriaNota] Add New',
  categoriaNotaClearActive: '[CategoriaNota] Clear active',
  categoriaNotaUpdated: '[CategoriaNota] CategoriaNota updated',
  categoriaNotaDelete: '[CategoriaNota] CategoriaNota delete',
  categoriaNotaLoaded: '[CategoriaNota] CategoriaNota Loaded',
  categoriaNotaCloseModalDelete: '[CategoriaNota] Close modal delete',
  categoriaNotaOpenModalDelete: '[CategoriaNota] Open modal delete',
  categoriaNotaReactived: '[CategoriaNota] CategoriaNota reactived',

  productoCloseModal: '[Producto] Close modal',
  productoOpenModal: '[Producto] Open modal',
  productoSetActive: '[Producto] Producto set active',
  productoAddNew: '[Producto] Add New',
  productoClearActive: '[Producto] Clear active',
  productoClear: '[Producto] Clear All',
  productoUpdated: '[Producto] Producto updated',
  ActivaDesavtivaProducto: '[Producto] Producto Activar/Desavtivar',
  productoLoaded: '[Producto] Producto Loaded',
  productoOpenModalDelete: '[Producto] Open modal delete',
  productoCloseModalDelete: '[Producto] Close modal delete',
  productoOpenModalShow: '[Producto] Open modal show',
  productoCloseModalShow: '[Producto] Close modal show',
  productoReactived: '[Producto] Producto reactived',
  productosLoadedByLocalidad: '[Producto] Productos Loaded By Localidad',
  ProductosByLocalidadByID: '[Producto] Productos Loaded By Localidad by ID',
  productoSetLocalidadActive: '[Producto] Set Localidad Active',
  productoSetTipoGravamenActive: '[Producto] Set TipoGravamen Active',
  productoTipoVentaSelected: '[Producto] Set TipoVenta Selected',
  productoTipoVentaClearSelected: '[Producto] Clear TipoVenta',
  setproductoClear: '[Producto] Clear Productos totales',
  productosClearByLocalidadByID:
    '[Producto] Clear Productos By Localidad By ID',

  descuentoCloseModal: '[Descuento] Close modal',
  descuentoOpenModal: '[Descuento] Open modal',
  descuentoSetActive: '[Descuento] Descuento set active',
  descuentoAddNew: '[Descuento] Add New',
  descuentoClearActive: '[Descuento] Clear active',
  descuentoUpdated: '[Descuento] Descuento updated',
  descuentoDelete: '[Descuento] Descuento delete',
  descuentoLoaded: '[Descuento] Descuento Loaded',
  descuentoCloseModalDelete: '[Descuento] Close modal delete',
  descuentoOpenModalDelete: '[Descuento] Open modal delete',
  descuentoReactived: '[Descuento] Descuento reactived',

  incotermCloseModal: '[Incoterm] Close modal',
  incotermOpenModal: '[Incoterm] Open modal',
  incotermSetActive: '[Incoterm] Incoterm set active',
  incotermAddNew: '[Incoterm] Add New',
  incotermClearActive: '[Incoterm] Clear active',
  incotermUpdated: '[Incoterm] Incoterm updated',
  incotermDelete: '[Incoterm] Incoterm delete',
  incotermLoaded: '[Incoterm] Incoterm Loaded',
  incotermCloseModalDelete: '[Incoterm] Close modal delete',
  incotermOpenModalDelete: '[Incoterm] Open modal delete',
  incotermReactived: '[Incoterm] Incoterm reactived',

  tipoGravamenCloseModal: '[TipoGravamen] Close modal',
  tipoGravamenOpenModal: '[TipoGravamen] Open modal',
  tipoGravamenSetActive: '[TipoGravamen] TipoGravamen set active',
  tipoGravamenAddNew: '[TipoGravamen] Add New',
  tipoGravamenClearActive: '[TipoGravamen] Clear active',
  tipoGravamenUpdated: '[TipoGravamen] TipoGravamen updated',
  tipoGravamenDelete: '[TipoGravamen] TipoGravamen delete',
  tipoGravamenLoaded: '[TipoGravamen] TipoGravamen Loaded',
  tipoGravamenCloseModalDelete: '[TipoGravamen] Close modal delete',
  tipoGravamenOpenModalDelete: '[TipoGravamen] Open modal delete',
  tipoGravamenReactived: '[TipoGravamen] TipoGravamen reactived',

  valorGravamenCloseModal: '[ValorGravamen] Close modal',
  valorGravamenOpenModal: '[ValorGravamen] Open modal',
  valorGravamenSetActive: '[ValorGravamen] ValorGravamen set active',
  valorGravamenAddNew: '[ValorGravamen] Add New',
  valorGravamenClearActive: '[ValorGravamen] Clear active',
  valorGravamenUpdated: '[ValorGravamen] ValorGravamen updated',
  valorGravamenDelete: '[ValorGravamen] ValorGravamen delete',
  valorGravamenLoaded: '[ValorGravamen] ValorGravamen Loaded',
  valorGravamenActuales: '[ValorGravamen] Valores gravámenes actuales',
  valorGravamenCloseModalDelete: '[ValorGravamen] Close modal delete',
  valorGravamenOpenModalDelete: '[ValorGravamen] Open modal delete',
  valorGravamenReactived: '[ValorGravamen] ValorGravamen reactived',

  tasasLoaded: '[Tasas] Tasas Loaded',

  facturaGeneralCloseModal: '[FacturaGeneral] Close modal',
  facturaGeneralOpenModal: '[FacturaGeneral] Open modal',
  facturaGeneralOpenDetalleModal: '[FacturaGeneral] Open modal detalle',
  facturaGeneralCloseDetalleModal: '[FacturaGeneral] Close modal detalle',
  facturaGeneralOpenPagoModal: '[FacturaGeneral] Open modal pago',
  facturaGeneralClosePagoModal: '[FacturaGeneral] Close modal pago',
  facturaGeneralSetActive: '[FacturaGeneral] FacturaGeneral set active',
  facturaGeneralAddNew: '[FacturaGeneral] Add New',
  facturaGeneralClearActive: '[FacturaGeneral] Clear active',
  facturaGeneralUpdated: '[FacturaGeneral] FacturaGeneral updated',
  facturaGeneralAnular:
    '[FacturaGeneral] FacturaGeneral Anular/Activar factura',
  facturaGeneralDelete: '[FacturaGeneral] FacturaGeneral Eliminar factura',
  facturaGeneralLoaded: '[FacturaGeneral] FacturaGeneral Loaded',
  facturaGeneralDetallesLoaded:
    '[FacturaGeneral] FacturaGeneral Detalles Loaded',
  facturaGeneralPagosLoaded: '[FacturaGeneral] FacturaGeneral Pagos Loaded',
  facturaGeneralGravamenesLoaded:
    '[FacturaGeneral] FacturaGeneral Gravamanes Loaded',
  facturaGeneralCloseModalDelete: '[FacturaGeneral] Close modal delete',
  facturaGeneralOpenModalDelete: '[FacturaGeneral] Open modal delete',
  facturaGeneralReactived: '[FacturaGeneral] FacturaGeneral reactived',
  facturaGeneralAddNewDetalle: '[FacturaGeneral] Add New detalle',
  facturaGeneralDeleteDetalle: '[FacturaGeneral] Delete detalle',
  facturaGeneralAddNewPago: '[FacturaGeneral] Add New Pago',
  facturaGeneralDeletePago: '[FacturaGeneral] Delete Pago',
  facturaGeneralAddNewGravamen: '[FacturaGeneral] Add New Gravamen',
  facturaGeneralClienteSelected: '[FacturaGeneral] Cliente selected',
  facturaGeneralClienteClearSelected: '[FacturaGeneral] Cliente clear selected',
  facturaGeneralClienteOpenModal: '[FacturaGeneral] Cliente Open Modal',
  facturaGeneralClienteCloseModal: '[FacturaGeneral] Cliente Close Modal',
  facturaGeneralLocalidadSelected: '[FacturaGeneral] Localidad selected',
  facturaGeneralLocalidadClearSelected:
    '[FacturaGeneral] Localidad clear selected',
  facturaGeneralSucursalSelected: '[FacturaGeneral] Sucursal selected',
  facturaGeneralSucursalClearSelected:
    '[FacturaGeneral] Sucursal clear selected',
  facturaGeneralTipoVentaSelected: '[FacturaGeneral] Tipo Venta selected',
  facturaGeneralTipoVentaClearSelected:
    '[FacturaGeneral] Tipo Venta clear selected',
  facturaGeneralNrofacturaSelected: '[FacturaGeneral] add Nro factura selected',
  facturaGeneralNrofacturaClearSelected:
    '[FacturaGeneral] Nro factura clear selected',

  facturaGeneralCondicionSelected: '[FacturaGeneral] Condición Pago selected',
  facturaGeneralCondicionClearSelected:
    '[FacturaGeneral] Condición Pago clear selected',
  facturaGeneralBancoSelected: '[FacturaGeneral] Banco selected',
  facturaGeneralBancoClearSelected: '[FacturaGeneral] Banco clear selected',
  facturaGeneralMedioPagoSelected: '[FacturaGeneral] Medio Pago selected',
  facturaGeneralMedioPagoClearSelected:
    '[FacturaGeneral] Medio Pago clear selected',
  facturaGeneralDivisaSelected: '[FacturaGeneral] Divisa selected',
  facturaGeneralDivisaClearSelected: '[FacturaGeneral] Divisa clear selected',
  facturaGeneralDiasCreditoSelected: '[FacturaGeneral] Dias Crédito selected',
  facturaGeneraDiasCreditoClearSelected:
    '[FacturaGeneral] Dias Crédito clear selected',
  facturaGeneralUpdateSubTotal: '[FacturaGeneral] Actualizar sub total',
  facturaGeneralUpdateExento: '[FacturaGeneral] Actualizar total exento',
  facturaGeneralUpdateExonerado: '[FacturaGeneral] Actualizar total exonerado',
  facturaGeneralUpdateTotalDescuento:
    '[FacturaGeneral] Actualizar Total Descuento',
  facturaGeneralUpdateTotalIva: '[FacturaGeneral] Actualizar total IVA',
  facturaGeneralUpdateImporteTotal: '[FacturaGeneral] Actualizar Importe total',
  facturaGeneralGenerada: '[FacturaGeneral] Generar PDF',
  facturaGeneralOpenModalShow: '[FacturaGeneral] Abrir modal Show',
  facturaGeneralCloseModalShow: '[FacturaGeneral] Cerrar modal Show',
  facturaGeneralUpdatelLstGravamenes:
    '[FacturaGeneral] Update element Gravamen',
  facturaGeneralUpdateTotalGravamen:
    '[FacturaGeneral] Update Total Gravamen. ADD Detalle Producto',
  facturaGeneralUpdateTotalesModificar:
    '[FacturaGeneral] Update Totales Modificar',
  facturaGeneralVerPdf: '[FacturaGeneral] Ver Pdf',
  facturaGeneralClearPdf: '[FacturaGeneral] Clear Pdf',
  facturaGeneralClearlstDetalles: '[FacturaGeneral] clear lstDetalles',
  facturaupdateDetalleProductoExistente:
    '[FacturaGeneral] Actualizar lstDetalles producto existente',
  facturaupdatedeleteTotalGravame:
    '[FacturaGeneral] Update/delete Total Gravamen. delete Detalle Producto',
  setUpdateFactura:
    '[FacturaGeneral] Set Update Factura tipo de Factura (VEF,USD)',
  clearDetalleProducto: '[FacturaGeneral] Clear Detalle Producto',
  facturaGeneralLoadCambioDivisa: '[FacturaGeneral] Load Cambio Divisa del dia',
  facturaAsignaNroFacturaLoaded: '[FacturaGeneral] Asigna Nro Factura Loaded',

  facturaCloseModal: '[FacturaCompra] Close modal',
  facturaCompraOpenModal: '[FacturaCompra] Open modal',
  facturaCompraOpenDetalleModal: '[FacturaCompra] Open modal detalle',
  facturaCompraCloseDetalleModal: '[FacturaCompra] Close modal detalle',
  facturaCompraOpenPagoModal: '[FacturaCompra] Open modal pago',
  facturaCompraClosePagoModal: '[FacturaCompra] Close modal pago',
  facturaCompraSetActive: '[FacturaCompra] FacturaGeneral set active',
  facturaCompraAddNew: '[FacturaCompra] Add New',
  facturaCompraClearActive: '[FacturaCompra] Clear active',
  facturaCompraUpdated: '[FacturaCompra] FacturaGeneral updated',
  facturaCompraDelete: '[FacturaCompra] FacturaGeneral delete',
  facturaCompraLoaded: '[FacturaCompra] FacturaGeneral Loaded',
  facturaCompraCloseModalDelete: '[FacturaCompra] Close modal delete',
  facturaCompraOpenModalDelete: '[FacturaCompra] Open modal delete',
  facturaCompraReactived: '[FacturaCompra] FacturaGeneral reactived',
  facturaCompraAddNewDetalle: '[FacturaCompra] Add New detalle',
  facturaCompraDeleteDetalle: '[FacturaCompra] Delete detalle',
  facturaCompraProveedorSelected: '[FacturaCompra] Proveedor selected',
  facturaCompraProveedorClearSelected:
    '[FacturaCompra] Proveedor clear selected',
  facturaCompraLocalidadSelected: '[FacturaCompra] Localidad selected',
  facturaCompraLocalidadClearSelected:
    '[FacturaCompra] Localidad clear selected',
  facturaCompraSucursalSelected: '[FacturaCompra] Sucursal selected',
  facturaCompraSucursalClearSelected: '[FacturaCompra] Sucursal clear selected',
  facturaCompraTipoCompraSelected: '[FacturaCompra] Tipo Compra selected',
  facturaCompraTipoCompraClearSelected:
    '[FacturaCompra] Tipo Compra clear selected',
  facturaCompraDivisaSelected: '[FacturaCompra] Divisa selected',
  facturaCompraDivisaClearSelected: '[FacturaCompra] Divisa clear selected',
  facturaCompraDiasCreditoSelected: '[FacturaCompra] Dias Crédito selected',
  facturaCompraDiasCreditoClearSelected:
    '[FacturaCompra] Dias Crédito clear selected',
  facturaGeneralLoadtLoadtipoCambioDiaUsd:
    '[FacturaGeneral] Load tipo cambio dia USD',
  facturaGeneracuentaTerceros: '[FacturaGeneral] Generar cuenta terceros',
  facturaGeneracuentaTercerosClear: '[FacturaGeneral] Cuenta terceros clear',
  facturaUpdateVendedor: '[FacturaGeneral] Update Vendedor',
  facturaClearVendedor: '[FacturaGeneral] Clear Vendedor',

  clienteCloseModal: '[Cliente] Close modal',
  clienteOpenModal: '[Cliente] Open modal',
  clienteSetActive: '[Cliente] Cliente set active',
  clienteAddNew: '[Cliente] Add New',
  clienteClearActive: '[Cliente] Clear active',
  clienteUpdated: '[Cliente] Cliente updated',
  clienteDelete: '[Cliente] Cliente delete',
  clienteLoaded: '[Cliente] Cliente Loaded',
  clienteLoadedDetalle: '[Cliente] Cliente Loaded Detalle',
  clienteCloseModalDelete: '[Cliente] Close modal delete',
  clienteOpenModalDelete: '[Cliente] Open modal delete',
  clienteReactived: '[Cliente] Cliente reactived',

  notaCloseModal: '[Nota] Close modal',
  notaOpenModal: '[Nota] Open modal',
  notaSetActive: '[Nota] Nota set active',
  notaAddNew: '[Nota] Add New',
  notaClearActive: '[Nota] Clear Valores raiz active (nota)',
  activeClearNota: '[Nota] Clear active',
  notaUpdated: '[Nota] Nota updated',
  notaDelete: '[Nota] Nota delete',
  notaAnulada: '[Nota] Nota anulada',
  notaLoaded: '[Nota] Nota Loaded',
  notaCloseModalDelete: '[Nota] Close modal delete',
  notaOpenModalDelete: '[Nota] Open modal delete',
  notaReactived: '[Nota] Nota reactived',
  notaDatosAdd: '[Nota] updateed datos general ADD',
  cargadetalleNota: '[Nota] carga detalle nota Activa pra modificar',
  deletedetalleNota: '[Nota] delete detalle nota',
  notaUpdateSubTotal: '[Nota] Actualizar sub total',
  notaUpdateTotalIva: '[Nota] Actualizar total IVA',
  notaUpdateImporteTotal: '[Nota] Actualizar Importe total',
  notaUpdateExento: '[Nota] Actualizar total exento',
  notaUpdateExonerado: '[Nota] Actualizar total exonerado',
  notaUpdateDeleteTotalGravame: '[Nota] Actualizar Total Gravamen',
  NotaGeneraLoaded: '[Nota] Nota Genera Loaded Con filtro',

  roleCloseModal: '[Role] Close modal',
  roleOpenModal: '[Role] Open modal',
  roleSetActive: '[Role] Role set active',
  roleAddNew: '[Role] Add New',
  roleClearActive: '[Role] Clear active',
  roleClear: '[Role] Clear all data',
  roleUpdated: '[Role] Role updated',
  roleDelete: '[Role] Role delete',
  roleLoaded: '[Role] Role Loaded',
  roleCloseModalDelete: '[Role] Close modal delete',
  roleOpenModalDelete: '[Role] Open modal delete',
  roleReactived: '[Role] Role reactived',
  notaAddNewDetalle: '[Nota] Add New detalle Producto',
  updateDetalleNotaConexpcion:
    '[Nota] Actualizar detalle producto existente con expeciones',
  updateDetalleNotaConInpusto:
    '[Nota] Actualizar detalle producto existente con impuesto',
  notaUpdatelLstGravamenes: '[Nota] Actualizar lstGravamenes',
  notaAddNewLstGravamen: '[Nota] Add New lstGravamenes',
  notaUpdateTotalDescuento: '[Nota] Actualizar Total Descuento',
  notaTotales: '[Nota] Actualizar Total Gravamen',
  updateDetalleNotaConInpustoPrecio: '[Nota] Actualizar precio',
  updateDetalleNotaConexpcionPrecio: '[Nota] Actualizar precio expeciones',
  NotaGeneradaUpdate: '[Nota] Actualizar Nota Generada en base64',
  notaDetalleajustado: '[Nota] Actualizar detalle ajustado luego de validar',

  usuarioCloseModal: '[Usuario] Close modal',
  usuarioOpenModal: '[Usuario] Open modal',
  usuarioSetActive: '[Usuario] Usuario set active',
  usuarioAddNew: '[Usuario] Add New',
  usuarioClearActive: '[Usuario] Clear active',
  usuarioUpdated: '[Usuario] Usuario updated',
  usuarioDelete: '[Usuario] Usuario delete',
  usuarioLoaded: '[Usuario] Usuario Loaded',
  usuarioCloseModalDelete: '[Usuario] Close modal delete',
  usuarioOpenModalDelete: '[Usuario] Open modal delete',
  usuarioReactived: '[Usuario] Usuario reactived',

  moduloCloseModal: '[Modulo] Close modal',
  moduloOpenModal: '[Modulo] Open modal',
  moduloSetActive: '[Modulo] Modulo set active',
  moduloAddNew: '[Modulo] Add New',
  moduloClearActive: '[Modulo] Clear active',
  moduloClear: '[Modulo] Clear all data',
  moduloUpdated: '[Modulo] Modulo updated',
  moduloDelete: '[Modulo] Modulo delete',
  moduloLoaded: '[Modulo] Modulo Loaded',
  moduloCloseModalDelete: '[Modulo] Close modal delete',
  moduloOpenModalDelete: '[Modulo] Open modal delete',
  moduloReactived: '[Modulo] Modulo reactived',
  moduloOpenModalShow: '[Modulo] Open modal Show',
  moduloCloseModalShow: '[Modulo] Close modal Show',

  controlFacturaLoaded: '[controlFactura] Control de Factura Loaded',
  controlFacturaSetActive: '[controlFactura] Control de Factura Active',
  controlFacturaClearActive:
    '[controlFactura] Control de Factura] Clear active',

  controlFacturaClearLoaded:
    '[controlFactura] Control de Factura] Clear  Loaded',
  controlSucursaloaded:
    '[controlFactura] Control de sucursales por control de Localidad Loaded',
  ControlSucursalClearloded:
    '[controlFactura] Control de sucursales por control de Localidad Clear Loaded',
  reporteLoaded: '[reporte] Reporte Loaded',
  reporteLoadedLogsPdf: '[reporte]  carga reporte pdf logs',
  reporteClear: '[reporte] Reporte Clear',
  // types para logsemail
  logsEmailLoaded: '[LogsEmail] Carga de LogsEmail por criterio',
  logsEmailClear: '[LogsEmail]  LogsEmail Clear',
  logsEmailparams: '[LogsEmail]  Actualizar params de buscar LogsEmail',
  logsEmailSendgrid:
    '[LogsEmail] Carga de LogsEmail de SendGrid Api por criterio',
  logsEmaiClearSendgrid: '[LogsEmail]  LogsEmail Clear SendGrid',

  // types para Vendedores
  vendedoresLoaded: '[Vendedores] Carga de Vendedores por criterio',
  vendedoresClear: '[Vendedores]  Vendedores Clear',
  vendedorActive: '[Vendedores] Vendedor set active',
  vendedoresClearAvtive: '[Vendedores] Vendedores Clear active',
  vendedorAdd: '[Vendedores] Vendedor Add',

  // types para Ordenes
  ordenesLoaded: '[Ordenes] Carga de Ordenes por criterio',
  ordenesClear: '[Ordenes]  Ordenes Clear',
  ordenesActive: '[Ordenes] Orden set active',
  ordenesClearAvtive: '[Ordenes] Ordenes Clear active',
  ordenesAdd: '[Ordenes] Add New',
  ordenesClearFiltro: '[Ordenes] Clear filtro',
  filtroOrdenAdd: '[Ordenes] Add filtro',
  anularOrdenGuia: '[Ordenes] Anular Orden',
  setUpdateOrden: '[Ordenes] setUpdateOrden',
  ordenClearActive: '[Ordenes] Clear active',

  // type para retensones
  retencionesLoaded: '[Retencion] Retenciones Loaded',
  retencionActive: '[Retencion] Retencion set active',
  retencionesAdd: '[Retencion ] Add New',
  retencionUpdated: '[Retencion] Retencion updated',
  retencionDelete: '[Retencion] Retencion delete',
  filtroRetencionAdd: '[Retencion] Add filtro',
  retencionesClear: '[Retencion ] Clear all',
  retencionClearActive: '[Retencion] Clear active',
  retencionesClearFiltro: '[Retencion] Clear filtro',
  retencionAddItem: '[Retencion] Add item',
  retencionRemoveItem: '[Retencion] Remove item',
  addRetencion: '[Retencion] Add retencion',
  addRetencionClear: '[Retencion] Clear add retencion',
  lstRetencionesClear: '[Retencion] Clear lstretenciones',
}
