export const diasCredito = [
  {
    id: 0,
    dias: 'D_5',
  },
  {
    id: 1,
    dias: 'D_10',
  },
  {
    id: 2,
    dias: 'D_15',
  },
  {
    id: 3,
    dias: 'D_30',
  },
  {
    id: 4,
    dias: 'D_45',
  },
  {
    id: 5,
    dias: 'D_60',
  },
  {
    id: 6,
    dias: 'D_90',
  },
]
export const mediosPago = [
  {
    id: 1,
    nombre: 'EFECTIVO',
  },
  {
    id: 2,
    nombre: 'TARJETA_DEBITO',
  },
  {
    id: 3,
    nombre: 'TARJETA_CREDITO',
  },
  {
    id: 4,
    nombre: 'CHEQUE',
  },
  {
    id: 5,
    nombre: 'TRANSFERENCIA',
  },
  {
    id: 5,
    nombre: 'CESTA_TICKETS',
  },
  {
    id: 6,
    nombre: 'TARJETA_ALIMENTACION',
  },
  {
    id: 7,
    nombre: 'POS_VIRTUAL',
  },
]

export const tipodoc = [
  { key: 0, value: 'V' },
  { key: 1, value: 'E' },
  { key: 2, value: 'J' },
  { key: 3, value: 'G' },
  { key: 4, value: 'P' },
]

export const roles = [
  { key: 'Admin', value: 'admin' },
  { key: 'User', value: 'user' },
]

export const tipoAciones = [
  { key: 'add', description: 'Agregar', tipo: 'generica' },
  { key: 'update', description: 'Modificar', tipo: 'generica' },
  { key: 'delete', description: 'Eliminar', tipo: 'generica' },
  { key: 'view', description: 'Ver', tipo: 'generica' },
  { key: 'print', description: 'Ver/imprimir pdf', tipo: 'generica' },
  { key: 'addSucursal', description: 'Agregar Sucursal', tipo: 'localidad' },
  { key: 'addControl', description: 'Agregar Nro. Control', tipo: 'localidad' },
  {
    key: 'addCertificado',
    description: 'Agregar Certficado',
    tipo: 'localidad',
  },
]

export const tipoProveedor = [
  { key: 0, value: 'PJD' },
  { key: 1, value: 'PJND' },
  { key: 2, value: 'PNR' },
  { key: 3, value: 'PNNR' },
]

// export const unidadMedida = [
//   { key: 0, value: 'Kg.' },
//   { key: 1, value: 'Gr.' },
//   { key: 2, value: 'Tons.' },
//   { key: 3, value: 'Und.' },
//   { key: 5, value: 'Lts.' },
//   { key: 6, value: 'Ml.' },
//   { key: 7, value: 'Mts.' },
// ]

export const unidadMedida = [
  { key: 0, value: 'MM', description: 'MILITRO' },
  { key: 1, value: 'CM', description: 'CENTIMETRO' },
  { key: 2, value: 'MTS', description: 'METRO' },
  { key: 3, value: 'HMT', description: 'HECTOMETRO' },
  { key: 4, value: 'KMT', description: 'KILOMETRO' },
  { key: 5, value: 'H', description: 'HECTAREA' },
  { key: 6, value: 'KG', description: 'KILOGRAMO' },
  { key: 7, value: 'MGR', description: 'MILIGRAMO' },
  { key: 8, value: 'GR', description: 'GRAMO' },
  { key: 9, value: 'TM', description: 'TONELADA METRICA' },
  { key: 10, value: 'BULTO', description: 'BULTO' },
  { key: 11, value: 'UND', description: 'UNIDAD' },
  { key: 12, value: 'CM3', description: 'CENTRIMETRO CUBIC' },
  { key: 13, value: 'CCM', description: 'CENTIMETRO CUBIC' },
  { key: 14, value: 'M3', description: 'METRO CUBICO' },
  { key: 15, value: 'MT3', description: 'METRO CUBICO' },
  { key: 16, value: 'HRS', description: 'HORAS' },
  { key: 17, value: 'CAJ', description: 'CAJA' },
  { key: 18, value: 'PAL', description: 'PALETA' },
  { key: 19, value: 'CESTA', description: 'CESTA' },
  { key: 20, value: 'PAQ', description: 'PAQUETE' },

  { key: 21, value: '1/4', description: '1/4 GALON' },
  { key: 22, value: 'BIDON', description: 'BIDON 12.5/1' },
  { key: 23, value: 'CAR', description: 'CARTUCHO' },
  { key: 24, value: 'CIL', description: 'CILINDRO' },
  { key: 25, value: 'COM', description: 'COMBO' },
  { key: 26, value: 'CTE', description: 'CUÑETE 4/1' },
  { key: 27, value: 'CTE5', description: 'CUÑETE 5/1' },
  { key: 28, value: 'DIA', description: 'DIA' },
  { key: 29, value: 'GAL', description: 'GALON' },
  { key: 30, value: 'KG', description: 'KG' },
  { key: 31, value: 'LAT', description: 'LATA 5/1' },
  { key: 32, value: 'LTS', description: 'LITRO' },
//  { key: 33, value: 'MTS', description: 'METRO' },
  // { key: 34, value: 'PAQ', description: 'PAQUETE' },
  { key: 35, value: 'ROL', description: 'ROLLO' },
  { key: 36, value: 'SAC', description: 'SACO' },
  { key: 37, value: 'TAM', description: 'TAMBOR' },
  // { key: 38, value: 'UND', description: 'UNIDAD' },
]

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

/**
 * @description Constante que contiene los tipos de documentos para retenciones
 */
export const MODELO_RETENCION = [
  { value: 'IVA', label: 'IVA' },
  { value: 'ISLR', label: 'ISLR' },
]