import {
 CDropdown,
 CDropdownItem,
 CDropdownMenu,
 CDropdownToggle,
} from '@coreui/react'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../Helpers/AuthPermisos'

export default function MenuActions({
 rowData,
 setOpenView,
 handleordenActive,
 setopenAnularV,
}) {
 const { roles } = useSelector((state) => state.role)
 const { UsuarioLogiado: user } = useSelector((state) => state.auth)
 const handleView = () => {
  setOpenView(true)
  handleordenActive(rowData)
 }

 const handleAnularOrden = () => {
  setopenAnularV(true)
  handleordenActive(rowData)
 }

 return (
  <div>
   <CDropdown>
    <CDropdownToggle variant='ghost'>
     <i className='fas fa-ellipsis-v'></i>
    </CDropdownToggle>
    <CDropdownMenu>
     {hasPermission(
      roles.filter((r) => r.id === user.user.role),
      '/retenciones/iva-islr',
      'print',
      user.user
     ) && (
      <CDropdownItem onClick={handleView}>
       <i className='fas fa-file-pdf text-success fa-1x'></i>
       <span className='ml-3 font-weight-bold'>PDF Retencion Iva-islr</span>
      </CDropdownItem>
     )}

     {hasPermission(
      roles.filter((r) => r.id === user.user.role),
      '/retenciones/iva-islr',
        'delete',
        user.user
     ) && (
      <CDropdownItem
       hidden={rowData.logEstatusRegistro === 'INACTIVO'}
       disabled={rowData.logEstatusRegistro === 'INACTIVO'}
       onClick={handleAnularOrden}
      >
       <i className='fas fa-trash text-warning fa-1x'></i>
       <span className='ml-3 font-weight-bold'>Anular Retencion</span>
      </CDropdownItem>
     )}
    </CDropdownMenu>
   </CDropdown>
  </div>
 )
}
