import React from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  Button,
  Grid,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Autocomplete } from '@material-ui/lab'
import { useRazonSocial } from '../../../../hooks/useRazonSocial'
import useRetenciones from '../../../../hooks/useRetenciones'
import { useDispatch } from 'react-redux'
import {
  setClearAddRetencion,
  startAddRetencion,
  startSaveRetencion,
} from '../../../../redux/actions/retencionActions'
import { Iso } from '@material-ui/icons'
import { setLocalidadClearActive } from '../../../../redux/actions/facturaGeneralActions'
import { MODELO_RETENCION } from '../../../../Constants/ConstantesFacturas'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: 0,
      width: '100%',
    },
  },
  CardHeader: {
    backgroundColor: '#054477',
    color: 'white',
    padding: '8px',
  },
  cardheader_icons: {
    fontSize: '1.5rem',
  },
  texto: {
    fontSize: '12px',
    fontWeight: 'bolder',
  },
  textField: {
    '& .MuiInputBase-input': {
      fontSize: '12px',
      fontWeight: 'bolder',
    },
  },
  textodireccion: {
    fontSize: '12px',
    color: '#9e9e9e',
    fontWeight: 'bolder',
  },
  select: {
    fontSize: '12px',
    color: '#9e9e9e',
    fontWeight: 'bolder',
    // justifyContent: 'center',
  },
  sublabel: {
    fontSize: '12px',
    color: '#546E7A',
    // alignItems: 'center',
  },
}))

const rifPattern = /^([JGVEPjgvep]{1})-([0-9]{9})$/

const schema = yup.object().shape({
  localidad: yup.string().required('Localidad es requerida'),
  peridofiscalDel: yup
    .date()
    .typeError('Debe ser una fecha válida')
    .required('Fecha de Inicio de Periodo Fiscal es requerida'),
  periodoFiscalAl: yup
    .date()
    .typeError('Debe ser una fecha válida')
    .required('Fecha de Fin de Periodo Fiscal es requerida'),
  // rif: yup.string().required('RIF es requerido').max(20),
  rifProveedor: yup
    .string()
    .required('RIF Retenido es requerido')
    .max(20)
    .matches(rifPattern, 'RIF debe tener el formato J-123456789'),
  identificacion: yup
    .string()
    .required('Nombre/Razón Social Retenido es requerido')
    .max(200),
  direccion: yup.string().required('Dirección Retenido es requerida').max(200),
  correo: yup
    .string()
    .email('debe ser un correo valido')
    .required('E-MAIL Retenido es requerido')
    .max(200),
  modeloRetencion: yup.string().required('Tipo de Retencion es requerido'),
})

const getFirstDayOfMonth = () => {
  const date = new Date()
  return new Date(date.getFullYear(), date.getMonth(), 1)
    .toISOString()
    .split('T')[0]
}

const getLastDayOfMonth = () => {
  const date = new Date()
  return new Date(date.getFullYear(), date.getMonth() + 1, 0)
    .toISOString()
    .split('T')[0]
}

export default function AddRetencionIva({ setSiguiente, Siguiente }) {
  const dispatch = useDispatch()
  const { localidades, localidadActive, HandleselectLocalidad } =
    useRazonSocial()
  const { addRetencion } = useRetenciones()

  const classes = useStyles()
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      localidad: '',
      peridofiscalDel: getFirstDayOfMonth(),
      periodoFiscalAl: getLastDayOfMonth(),
      rifProveedor: '',
      identificacion: '',
      direccion: '',
      correo: '',
      modeloRetencion: '',
    },
  })

  const onSubmit = (data) => {

    try {
      dispatch(
        startAddRetencion({
          rif:
            localidadActive?.tipoDocumento +
            '-' +
            localidadActive?.numeroDocumento,
          rifProveedor: data.rifProveedor,
          identificacion: data.identificacion,
          direccion: data.direccion,
          correo: data.correo,
          fechaEmision: new Date(),
          peridofiscalDel: data.peridofiscalDel,
          periodoFiscalAl: data.periodoFiscalAl,
          modeloRetencion: data.modeloRetencion,
        })
      )
      setSiguiente(true)
    } catch (error) {}

    // addRetencion(data)
  }

  const handleAddRetencion = () => {
    try {
      dispatch(startSaveRetencion(addRetencion))
        .then((res) => {
          if (res.payload?.tipoMsg === 'sucess') {
            reset({
              localidad: '',
              peridofiscalDel: getFirstDayOfMonth(),
              periodoFiscalAl: getLastDayOfMonth(),
              rifProveedor: '',
              identificacion: '',
              direccion: '',
              correo: '',
            })
            setSiguiente(false)
            dispatch(setLocalidadClearActive())
            dispatch(setClearAddRetencion())
          }
        })
        .catch((error) => {
          console.log('🚀 ~ dispatch ~ error:', error)
        })
    } catch (error) {
      console.log('🚀 ~ handleAddRetencion ~ error:', error)
    }
  }

  const handleRifChange = (event) => {
    const value = event.target.value.toUpperCase().replace(/[^JGVEP0-9]/g, '')
    if (value.length > 1) {
      event.target.value = value.slice(0, 1) + '-' + value.slice(1)
    } else {
      event.target.value = value
    }
  }

  console.log('🚀 ~ AddRetencionIva ~ errors:', errors)

  console.log('🚀 ~ AddRetencionIva ~ addRetencion:', addRetencion)

  return (
    <Card
      style={{
        minHeight: '50vh',
        // maxWidth: 500,
        boxShadow:
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      }}
    >
      <CardHeader
        className={classes.CardHeader}
        title={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                textAlign: 'left',
                margin: 0,
                padding: 0,
                width: '100%',
              }}
            >
              <p style={{ margin: 0, fontSize: '20px', fontWeight: 'bolder' }}>
                Agregar Retención IVA
              </p>
              <p
                style={{
                  margin: 0,
                  fontSize: '12px',
                  fontWeight: 'bolder',
                  color: '#9e9e9e',
                }}
              >
                Fecha de Emisión: {new Date().toLocaleDateString()}
              </p>
            </div>
            <IconButton
              disabled={!Siguiente || addRetencion.lstRetenciones.length === 0}
              style={{ color: 'white' }}
              size='medium'
              onClick={() => {
                handleAddRetencion()
              }}
            >
              <Iso fontSize='large' />
            </IconButton>
          </div>
        }
      />
      <CardContent>
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Controller
                name='localidad'
                control={control}
                defaultValue=''
                rules={{ required: 'Este campo es requerido' }}
                render={({ field }) => (
                  <Autocomplete
                    style={{ width: '100%' }}
                    size='small'
                    id='localidad'
                    clearText='Limpiar'
                    noOptionsText='No hay Localidades'
                    options={localidades}
                    getOptionLabel={(option) => option.nombre || ''}
                    value={localidadActive}
                    onChange={(_, newValue) => {
                      field.onChange(newValue?._id)
                      HandleselectLocalidad(_, newValue)
                    }}
                    // filterSelectedOptions
                    renderOption={(option) => {
                      return <h6 className={classes.texto}>{option.nombre}</h6>
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ style: { fontSize: '12px' } }}
                        className={classes.textField}
                        variant='standard'
                        label='NOMBRE O RAZÓN SOCIAL DEL AGENTE DE RETENCIÓN'
                        error={!!errors.localidad}
                        helperText={errors.localidad?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <p style={{ margin: 0, fontWeight: 'bold', fontSize: '12px' }}>
                DIRECCION
              </p>
              <p className={classes.textodireccion}>
                {localidadActive?.direccion}
              </p>

              <p style={{ margin: 0, fontWeight: 'bold', fontSize: '12px' }}>
                REGISTRO DE INFORMACIÓN FISCAL DEL AGENTE DE RETENCIÓN:
              </p>

              <p className={classes.textodireccion}>
                {localidadActive?.tipoDocumento}-
                {localidadActive?.numeroDocumento}
              </p>

              <p style={{ margin: 0, fontWeight: 'bold', fontSize: '12px' }}>
                PERIODO DE IMPOSICIÓN FISCAL
              </p>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Controller
                name='peridofiscalDel'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    disabled
                    {...field}
                    label='Periodo Fiscal Desde'
                    type='date'
                    // InputLabelProps={{  }}
                    variant='standard'
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '14px' },
                    }}
                    className={classes.textField}
                    error={!!errors.peridofiscalDel}
                    helperText={
                      <p style={{ margin: 0, fontSize: '12px' }}>
                        {errors.peridofiscalDel?.message}
                      </p>
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Controller
                name='periodoFiscalAl'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    disabled
                    {...field}
                    label='Periodo Fiscal Hasta'
                    type='date'
                    variant='standard'
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '14px' },
                    }}
                    className={classes.textField}
                    error={!!errors.periodoFiscalAl}
                    helperText={
                      <p style={{ margin: 0, fontSize: '12px' }}>
                        {errors.periodoFiscalAl?.message}
                      </p>
                    }
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <InputLabel
                style={{
                  fontSize: '10px',
                  color: '#9e9e9e',
                }}
              >
                Tipo de Retenccion
              </InputLabel>
              <Controller
                control={control}
                name='modeloRetencion'
                defaultValue=''
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Select
                    labelId='modeloRetencion'
                    id='modeloRetencion'
                    fullWidth
                    defaultValue=''
                    className={classes.select}
                    onChange={onChange}
                    value={value}
                    error={!!error}
                    label='Tipo de Retenccion'
                  >
                    {MODELO_RETENCION.map((option) => {
                      return (
                        <MenuItem
                          className={classes.sublabel}
                          key={option.key}
                          value={option.value}
                        >
                          {option.value}
                        </MenuItem>
                      )
                    })}
                  </Select>
                )}
              />
              <FormHelperText error>
                {errors?.tipoTransaccion?.message}
              </FormHelperText>
            </Grid>

            <Grid item xs={12} sm={12}>
              {/* <Divider
                variant='fullWidth'
                component={'div'}
                style={{ marginBottom: '12px' }}
              /> */}
              <p
                style={{
                  margin: 0,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontSize: '12px',
                }}
              >
                DATOS AGENTE DE RETENCIÓN
              </p>
            </Grid>

            <Grid item xs={12} sm={8}>
              <Controller
                name='identificacion'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    {...field}
                    name='identificacion'
                    label='NOMBRE O RAZÓN SOCIAL DEL SUJETO RETENIDO'
                    variant='standard'
                    InputLabelProps={{ style: { fontSize: '12px' } }}
                    className={classes.textField}
                    fullWidth
                    error={!!errors.identificacion}
                    helperText={
                      <p style={{ margin: 0, fontSize: '12px' }}>
                        {errors.identificacion?.message}
                      </p>
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controller
                name='rifProveedor'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    {...field}
                    label='RIF S. Retenido'
                    variant='standard'
                    inputProps={{
                      maxLength: 11,
                      pattern: /^[JjVvEeGg][-][0-9]{9}$/,
                      title: 'El formato debe ser J-062075240',
                      onChange: handleRifChange,
                    }}
                    InputLabelProps={{ style: { fontSize: '12px' } }}
                    className={classes.textField}
                    fullWidth
                    error={!!errors.rifProveedor}
                    helperText={
                      <p style={{ margin: 0, fontSize: '12px' }}>
                        {errors.rifProveedor?.message}
                      </p>
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Controller
                name='direccion'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    {...field}
                    label='DIRECCIÓN FISCAL DEL PROVEEDOR'
                    variant='standard'
                    InputLabelProps={{ style: { fontSize: '12px' } }}
                    className={classes.textField}
                    fullWidth
                    error={!!errors.direccion}
                    helperText={
                      <p style={{ margin: 0, fontSize: '12px' }}>
                        {errors.direccion?.message}
                      </p>
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Controller
                name='correo'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    {...field}
                    label='E-MAIL DEL PROVEEDOR'
                    variant='standard'
                    InputLabelProps={{ style: { fontSize: '12px' } }}
                    className={classes.textField}
                    fullWidth
                    error={!!errors.correo}
                    helperText={
                      <p style={{ margin: 0, fontSize: '12px' }}>
                        {errors.correo?.message}
                      </p>
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <CardActions style={{ justifyContent: 'flex-end' }}>
            <Button
              type='submit'
              color='primary'
              variant='contained'
              style={{
                marginTop: '20px',
                borderColor: '#054477',
                fontSize: '0.80rem',
                fontWeight: 'bolder',
                backgroundColor: Siguiente ? '#2e7d32' : '#fd7e14',
              }}
            >
              {!Siguiente ? 'Siguiente ➡' : 'Actualizar 🥖'}
            </Button>
          </CardActions>
        </form>
      </CardContent>
    </Card>
  )
}
