import axios from 'axios'
import Swal from 'sweetalert2'
import { types } from '../types/types'
import { config } from '../../config/configProperties'
import {
 errorClearActive,
 errorSetActive,
 errorStatusLoading,
} from './errorActions'
import {
 ApiAxiosGet,
 ApiAxiosPost,
 ApiAxiosPostFetch,
} from '../../Helpers/ApiAxios'
// import { errorReducer } from '../reducers/errorReducer'

const url = `${config.urlServer}/facturacion/facturaGeneral`

const URL = `${config.urlServer}/facturas`

const URLORDENS = `${config.urlServer}/ordenes`
//Acción para listar todas las Facturas Generales. Sin el atributo facturaCodificada.
export const startLoadFacturasGenerales = () => {
 return async (dispatch) => {
  dispatch(errorStatusLoading('pagina'))
  try {
   const res = await ApiAxiosGet(`${URL}/queryfacturacion/`, 'GET')
   // console.log('reswwwwwwWWWWWW', res)
   dispatch(errorClearActive())
   dispatch(facturaGeneralLoaded(res))
  } catch (error) {
   console.log('ERRORRROR', error)
   dispatch(
    errorSetActive(error?.response?.data?.message || 'ocurrido un error de red')
   )
   // dispatch(errorStatusLoading())
  }
 }
}

export const startLoadFiltroFacturas = (data) => {
 return async (dispatch) => {
  dispatch(errorStatusLoading('pagina'))
  try {
   const res = await ApiAxiosPost(`${URL}/FiltrarFacturas/`, 'POST', data)
   console.log('reswwwwwwWWWWWW', res)
   // dispatch(errorClearActive())
   dispatch(facturaGeneralLoaded(res))
   return dispatch(errorSetActive('Busqueda Encontrada', 'sucess'))
  } catch (error) {
   console.log('ERRORRROR', error.response)
   return dispatch(
    errorSetActive(
     error?.response?.data?.message || 'ocurrido un error de red',
     'error'
    )
   )
   // dispatch(errorStatusLoading())
  }
 }
}

//Acción para cargar la consulta de una factura
export const startLoadDetalles = (idFactura) => {
 return async (dispatch) => {
  try {
   const res = await ApiAxiosGet(`${URL}/getfacturacion/${idFactura}`, 'GET')
   dispatch(errorClearActive())
   dispatch(facturaGeneralDetallesLoaded(res))
  } catch (error) {
   console.log('ERRORRROR', error)
   dispatch(
    errorSetActive(error?.response?.data?.message || 'ocurrido un error de red')
   )
  }
 }
}

//Acción para Ver PDF
export const queryFacturasCodificada = (id) => {
 return async (dispatch, getState) => {
  try {
   // dispatch(errorStatusLoading('SoloLoading'))
   const res = await ApiAxiosGet(`${URL}/queryFacturasCodificada/${id}`, 'GET')
   console.log('RESSSSSSSS', res)
   dispatch(facturaGeneralVerPdf(res))
   return errorSetActive(
    'factura codificada encontrada con exito',
    'sucess',
    res
   )
  } catch (error) {
   console.log('ERRORRROR', error)
   dispatch(
    errorSetActive(error?.response?.data?.message || 'ocurrido un error de red')
   )
  }
 }

 // console.log('idFactura', idFactura)
 // await axios.get(`${url}/getFacturaPdf/${idFactura}`).then((res) => {
 //   console.log('facturaGeneralVerPdf', res)

 //   dispatch(facturaGeneralVerPdf(res.data.facturaCodificada))
 //   window.open(
 //     'data:application/octet-stream;charset=utf-8;base64,' +
 //       res.data.facturaCodificada,
 //     '_blank'
 //   )
 // })

 // setTimeout(() => {
 //   dispatch(facturaGeneralClearPdf())
 // }, 3000)
 // }
}

//Acción para Ver PDF
export const startShowDetalle = (idFactura) => {
 return async (dispatch, getState) => {
  try {
   dispatch(errorStatusLoading('SoloLoading'))
   const res = await ApiAxiosGet(`${URL}/getfacturacion/${idFactura}`, 'GET')
   dispatch(facturaGeneralSetActive(res))
   dispatch(errorClearActive())
  } catch (error) {
   dispatch(
    errorSetActive(error?.response?.data?.message || 'ocurrio un error')
   )
  }
 }
}

//Carga la lista de Pagos de una Facura General
export const startLoadPagos = (idFactura) => {
 return async (dispatch) => {
  try {
   dispatch(errorStatusLoading('SoloLoading'))
   const res = await ApiAxiosGet(`${URL}/pagos/${idFactura}`, 'GET')
   dispatch(facturaGeneralPagosLoaded(res))
   dispatch(errorClearActive())
  } catch (error) {
   dispatch(
    errorSetActive(error?.response.data.message || 'ocurrio un error de Red')
   )
  }
 }
 // axios.get(`${url}/pagos/${idFactura}`).then((res) => {
 //   dispatch(facturaGeneralPagosLoaded(res.data))
 // })
}

//Lista los gravámanes de una Factura General
export const startLoadGravamenes = (idFactura) => {
 return async (dispatch) => {
  try {
   dispatch(errorStatusLoading('SoloLoading'))
   const res = await ApiAxiosGet(`${URL}/gravamenes/${idFactura}`, 'GET')
   dispatch(facturaGeneralGravamenesLoaded(res))
   dispatch(errorClearActive())
  } catch (error) {
   dispatch(
    errorSetActive(error?.response?.data.message || 'ocurrio un error de Red')
   )
  }
 }
}

//Registra/Crea una Factura General
export const startSaveFacturaGeneral = (facturaGeneral) => {
 return async (dispatch, getState) => {
  try {
   dispatch(errorStatusLoading())
   const res = await ApiAxiosPost(
    `${URL}/addfacturacion`,
    'POST',
    facturaGeneral
   )
   dispatch(errorSetActive('Factura General registrada', 'sucess'))
   dispatch(facturaGeneralClearActive())
   // Swal.fire('Factura General registrada ', res, 'success')
  } catch (error) {
   const msgError = error.response.data.message
   console.log('AQUI HUBO UN ERROR', msgError)
   // Swal.fire('Error', msgError, 'error')
   dispatch(
    errorSetActive(
     error.response.data.message || 'Ocurrio un error al registrar Factura',
     'error'
    )
   )
  }
 }
}

/**
 * Inactiva/Anula una Factura General
 * @param {*} idFactura
 * @returns
 */
export const startAnularFacturaGeneral = (idFactura) => {
 return async (dispatch, getState) => {
  //Llamado al endpoint

  try {
   dispatch(errorStatusLoading())
   const res = await ApiAxiosPost(`${URL}/anularfactura/${idFactura}`, 'PUT')
   console.log('RESPUT ANULAR', res)
   dispatch(anularFacturaGeneral(res.id, res.logEstatusRegistro))
   dispatch(errorSetActive(`Anulada Factura N°: ${res.nroFactura}`, 'sucess'))
  } catch (error) {
   console.log('ERRORRROR', error)
   dispatch(
    errorSetActive(error?.response?.data?.message || 'ocurrido un error de red')
   )
  }
 }
}

export const startDeleteFacturaGeneral = (idFactura) => {
 return async (dispatch, getState) => {
  //Llamado al endpoint
  try {
   // dispatch(errorStatusLoading())
   const res = await ApiAxiosPost(`${URL}/deletefactura/${idFactura}`, 'PUT')
   console.log('RESPUT DELETE', res)
   dispatch(deletedFacturaGeneral(res.id))
   dispatch(errorSetActive(`Eliminada Factura N°: ${res.nroFactura}`, 'sucess'))
  } catch (error) {
   console.log('ERRORRROR', error)
   dispatch(
    errorSetActive(error?.response?.data?.message || 'ocurrido un error de red')
   )
  }
 }
}

//Reactiva una Factura General
export const startReactivedFacturaGeneral = (facturaGeneral) => {
 return async (dispatch, getState) => {
  //Llamado al endpoint

  try {
   dispatch(errorStatusLoading())
   const res = await ApiAxiosPost(
    `${URL}/activarfactura/${facturaGeneral.id}`,
    'PUT'
   )
   // console.log('RESPUT DELETE', res)
   dispatch(anularFacturaGeneral(res.id, res.logEstatusRegistro))
   dispatch(errorSetActive(`Activada Factura N°: ${res.nroFactura}`, 'sucess'))
  } catch (error) {
   console.log(error)
   dispatch(
    errorSetActive(
     error.response?.data.message || 'Ocurrio un error al Activar Factura',
     'error'
    )
   )
  }
 }
}

//Actualiza - Modifica una Factura General
export const startUpdateFacturaGeneral = (facturaGeneral) => {
 return (dispatch, getState) => {
  const { activeFacturaGeneral } = getState().facturaGeneral
  const id = activeFacturaGeneral.id

  //Llamado al endpoint usando Axios
  axios({
   method: 'PUT',
   url: `${url}/editar/${id}`,
   data: facturaGeneral,
  })
   .then((response) => {
    Swal.fire('Actualizado', response.data, 'success')
    dispatch(facturaGeneralUpdated(response.data))
   })
   .catch((error) => {
    const msgError = error.response.data.message
    console.log(error.response.data.message)
    Swal.fire('Error', msgError, 'error')
   })
 }
}

//Carga los datos para el Modificar
export const starLoadDatosFacturaGenerallUpdate = () => {
 return (dispatch, getState) => {
  const { activeFacturaGeneral } = getState().facturaGeneral
  const id = activeFacturaGeneral.id

  dispatch(startLoadDetalles(id))
  dispatch(startLoadPagos(id))
  dispatch(startLoadGravamenes(id))
  dispatch(setClienteActive(activeFacturaGeneral.cliente))
  dispatch(facturaGeneralUpdateTotalesModificar(activeFacturaGeneral))
 }
}

//Acción para Generar PDF de una Factura General
export const startGenerarFacturaGeneral = (facturaGeneral) => {
 return async (dispatch, getState) => {
  const { activeFacturaGeneral } = getState().facturaGeneral
  const id = activeFacturaGeneral.id

  try {
   dispatch(errorStatusLoading('SoloLoading'))
   const res = await ApiAxiosGet(`${URL}/generarfactura/${id}`, 'PUT')
   console.log('response.data', id, res)
   dispatch(facturaGeneralGenerada(id, res))
   dispatch(startClearFacturaActive())
   dispatch(errorSetActive('Generada con exito', 'sucess'))
   // dispatch(errorClearActive())
  } catch (error) {
   // const msgError = error.response?.data
   console.log(error.response)
   // Swal.fire('Error', msgError, 'error')
   dispatch(
    errorSetActive(
     error.response?.data.message || 'Ocurrio un error al Generar Factura',
     'error'
    )
   )
  }
 }
}

//Acción para borrar un Detalle al registrar Factura General
export const startDeleteDetalle = (detalle, index) => {
 console.log('Index: ' + index)
 return (dispatch, getState) => {
  dispatch(deleteDetalle(index))
  dispatch(facturaGeneralUpdateSubTotal('resta', detalle.importe))
  dispatch(facturaGeneralUpdateTotalIva('resta', detalle.montoGravamen))
  dispatch(
   facturaGeneralUpdateImporteTotal(
    'resta',
    detalle.importe + detalle.montoGravamen
   )
  )

  const { productosByLocalidad } = getState().producto
  const { localidadActive } = getState().facturaGeneral

  const prod = productosByLocalidad.find(
   (p) =>
    p.codigo === detalle.codigoProducto &&
    p.id_localidad === localidadActive._id
  )

  if (prod.exento === true) {
   dispatch(
    facturaGeneraUpdateTotalExento('resta', detalle.precio * detalle.cantidad)
   )
  } else if (prod.exonerado === true) {
   dispatch(
    facturaGeneraUpdateTotalExonerado(
     'resta',
     detalle.precio * detalle.cantidad
    )
   )
  }

  if (!prod.exento) {
   dispatch(
    facturaGeneralDeleteUpdateTotalGravamen({
     baseImponible: detalle.precio * detalle.cantidad,
     montoGravamen: detalle.montoGravamen,
     idgravamen: detalle.idgravamen,
     TipoGravamen: prod.tipoGravamen?.lstValores.find(
      (valor) => valor.logEstatusRegistro === 'ACTIVO'
     ),
     // idTipoGravamen: prod.tipoGravamen?.id  // manda el valor que no corresponde modificado ph arriba
    })
   )
  }
 }
}

export const startDeleteDetalleModif = (detalle, index) => {
 // console.log('Index: ' + index)
 return (dispatch, getState) => {
  dispatch(deleteDetalle(index))
  dispatch(facturaGeneralUpdateSubTotal('resta', detalle.importe))
  dispatch(facturaGeneralUpdateTotalIva('resta', detalle.montoGravamen))
  dispatch(
   facturaGeneralUpdateImporteTotal(
    'resta',
    detalle.importe + detalle.montoGravamen
   )
  )

  const { productosByLocalidad } = getState().producto
  const prod = productosByLocalidad.find((p) => p.id === detalle.producto.id)
  console.log(prod)

  if (prod.exento === true) {
   dispatch(
    facturaGeneraUpdateTotalExento('resta', detalle.precio * detalle.cantidad)
   )
  } else if (prod.exonerado === true) {
   dispatch(
    facturaGeneraUpdateTotalExonerado(
     'resta',
     detalle.precio * detalle.cantidad
    )
   )
  }

  dispatch(
   facturaGeneralUpdateTotalGravamen({
    idTipoGravamen: prod.tipoGravamen.id,
    baseImponible: detalle.precio * detalle.cantidad,
    montoAlicuota: detalle.montoAlicuota,
   })
  )
 }
}

//Acción para incluir un Detalle al registrar Factura General
export const startNewDetalle = (detalle) => {
 return (dispatch, getState) => {
  const { productosByLocalidad } = getState().producto

  const prod = productosByLocalidad.find((p) => p.id === detalle.producto)

  //TODO: Invocar DAO para obtener ValorGravamenActual asociado al Producto
  const { valoresGravamanesActuales } = getState().valorGravamen
  const baseImponible = prod.precio * detalle.cantidad
  let valorGravamen = null
  let montoGravamenCalculado = 0.0
  if (!prod.exento && !prod.exonerado) {
   valorGravamen = valoresGravamanesActuales.find(
    (vg) => vg.tipoGravamen.id === prod.tipoGravamen.id
   )
   montoGravamenCalculado = (baseImponible * valorGravamen.valor) / 100
  }

  //Acción para llenar la lista de  Productos
  dispatch(
   facturaGeneralAddNewDetalle({
    codigoProducto: prod.id,
    descripcion: prod.descripcion,
    cantidad: detalle.cantidad,
    precio: prod.precio,
    importe:
     prod.descuento !== null
      ? prod.precio * detalle.cantidad - prod.descuento.monto
      : prod.precio * detalle.cantidad,
    gravamen: valorGravamen !== null ? valorGravamen.valor : 0.0,
    montoGravamen: montoGravamenCalculado,
    descuento: prod.descuento !== null ? prod.descuento.monto : 0.0,
    montoDescuento: 0.0,
   })
  )

  const { lstGravamenes } = getState().facturaGeneral

  //Acción para llenar la lista de Gravámenes si el Producto no está Exento o Exonerado
  if (!prod.exento && !prod.exonerado) {
   const existe = lstGravamenes.some(
    (g) => g.idTipoGravamen === prod.tipoGravamen.id
   )

   console.log('existe', existe)
   if (existe) {
    dispatch(
     facturaGeneralUpdatelLstGravamenes({
      tipoGravamen: prod.tipoGravamen.id,
      baseImponible,
      montoGravamenCalculado,
     })
    )
   } else {
    dispatch(
     facturaGeneralAddNewGravamen({
      baseImponible,
      montoGravamen: valorGravamen.valor,
      totalGravamen: montoGravamenCalculado,
      idTipoGravamen: valorGravamen.tipoGravamen.id,
     })
    )
   }
  }

  dispatch(facturaGeneralUpdateSubTotal('suma', baseImponible))
  dispatch(facturaGeneralUpdateTotalIva('suma', montoGravamenCalculado))
  dispatch(
   facturaGeneralUpdateImporteTotal(
    'suma',
    baseImponible + montoGravamenCalculado
   )
  )

  if (prod.exento === true) {
   dispatch(
    facturaGeneraUpdateTotalExento('suma', prod.precio * detalle.cantidad)
   )
  } else if (prod.exonerado === true) {
   dispatch(
    facturaGeneraUpdateTotalExonerado('suma', prod.precio * detalle.cantidad)
   )
  }
 }
}
// Agregar y validar el Porducto  al detalle factura PH cambiado por el de arriba
export const startAddProductDetalle = (
 producto,
 cantidad,
 facturaDivisa,
 tipoDescuento,
 valorDescuento
) => {
 console.log('🚀 ~ producto:', producto)
 console.log('🚀 ~ valorDescuento:', valorDescuento)
 console.log('🚀 ~ tipoDescuento:', tipoDescuento)
 return (dispatch, getState) => {
  const { lstGravamenes, lstDetalles, totalDescuento } =
   getState().facturaGeneral

  console.log('🚀 ~ return ~ totalDescuento:', totalDescuento)
  let valorGravamenActivo = null // nuevo colcado para dertmiar valor a buscar
  let montoGravamenCalculado = 0.0
  let valueDivisa
  switch (facturaDivisa) {
   case 'USD':
    valueDivisa = producto.divisaPrecio
    break
   case 'VEF':
    valueDivisa = producto.precio
    break
   case 'PTR':
    valueDivisa = producto.PrecioPtr
    break
   case 'EUR':
    valueDivisa = producto.PrecioEur
    break
   default:
    break
  }

  // facturaDivisa ? producto.divisaPrecio : producto.precio

  // console.log('valueDivisa', valueDivisa)

  // const baseImponible = producto?.descuento
  //  ? (parseInt(valueDivisa) - parseFloat(producto.descuento.monto)) *
  //    parseInt(cantidad)
  //  : valueDivisa * parseInt(cantidad)

  const baseImponible =
   tipoDescuento === 'sindescuento'
    ? valueDivisa * cantidad
    : tipoDescuento === 'monto'
    ? valueDivisa * cantidad - valorDescuento
    : (valueDivisa - (valueDivisa * valorDescuento) / 100) * cantidad

  // : valueDivisa * cantidad - (valueDivisa * cantidad - valorDescuento) / 100

  console.log('🚀 ~ return ~ baseImponible:', baseImponible)
  // Esto es la   correcion
  // console.log('valorGravamenActivovalorGravamenActivo', producto, valorGravamenActivo)
  if (!producto.exento && !producto.exonerado) {
   valorGravamenActivo = producto?.tipoGravamen?.lstValores.find(
    (vgAct) => vgAct.logEstatusRegistro === 'ACTIVO'
   )
   // console.log('valorGravamenActivo AQUI', valorGravamenActivo)
   montoGravamenCalculado = (baseImponible * valorGravamenActivo?.valor) / 100
  }

  const isInDetalle = lstDetalles.some((prod) => {
   return prod.codigoProducto === producto.codigo
  })

  // console.log('isInDetalle >>>>><', producto)

  if (!isInDetalle) {
   dispatch(
    facturaGeneralAddNewDetalle({
     codigoProducto: producto.codigo, //id,
     descripcion: producto.descripcion,
     cantidad: cantidad,
     unidadMedida: producto.unidadMedida,
     precio: valueDivisa,
     exento: producto.exento,
     exonerado: producto.exonerado,
     importe: baseImponible,
     // producto.descuento !== undefined
     //  ? valueDivisa * cantidad - producto.descuento.monto
     //  : valueDivisa * cantidad,
     alicuotaGravamen:
      valorGravamenActivo !== null ? valorGravamenActivo?.valor : 0.0,
     idgravamen: producto.tipoGravamen.idgravamen,
     montoGravamen: montoGravamenCalculado,
     descuento:
      tipoDescuento === 'sindescuento' || tipoDescuento === 'monto'
       ? 0.0
       : valorDescuento,
     // producto.descuento !== undefined ? producto.descuento.monto : 0.0,
     montoDescuento:
      tipoDescuento === 'sindescuento'
       ? 0.0
       : tipoDescuento === 'porcentaje'
       ? (valueDivisa * cantidad * valorDescuento) / 100
       : valorDescuento, // * cantidad, // 0.0,
     // logUsername: 'admin',
    })
   )

   //  dispatch(facturaGeneraUpdateTotalDescuento('sumar', valorDescuento))
  } else {
   // console.log('montoGravamenCalculado AQUI', montoGravamenCalculado)
   dispatch(
    updateDetalleProductoExistente({
     codigoProducto: producto.codigo, // id,
     importe: baseImponible,
     // producto.descuento !== undefined
     //  ? valueDivisa * cantidad - producto.descuento.monto
     //  : valueDivisa * cantidad,
     cantidad: cantidad,
     montoGravamen: montoGravamenCalculado,
     descuento:
      tipoDescuento === 'sindescuento' || tipoDescuento === 'monto'
       ? 0.0
       : valorDescuento,
     montoDescuento:
      tipoDescuento === 'sindescuento'
       ? 0.0
       : tipoDescuento === 'porcentaje'
       ? (valueDivisa * cantidad * valorDescuento) / 100
       : valorDescuento * cantidad, // 0.0,
    })
   )
   // console.log('isInDetalle >>>>><', isInDetalle)
  }
  //Acción para llenar la lista de Gravámenes si el Producto no está Exento o Exonerado
  if (!producto.exento && !producto.exonerado) {
   const existe = lstGravamenes.some(
    (g) => g.tipo === producto.tipoGravamen.idgravamen
   )
   if (existe) {
    console.log('valorGravamenActivo 548ppRRR', valorGravamenActivo)
    dispatch(
     facturaGeneralUpdatelLstGravamenes({
      baseImponible,
      tipo: producto.tipoGravamen.idgravamen,
      montoAlicuota: montoGravamenCalculado,
     })
    )
   } else {
    dispatch(
     facturaGeneralAddNewGravamen({
      baseImponible,
      alicuota: valorGravamenActivo.valor,
      montoAlicuota: montoGravamenCalculado,
      tipo: producto.tipoGravamen.idgravamen,
      // idTipoGravamen:  valorGravamen.id,
     })
    )
   }
  }
  // fase tres totales y Sub totales
  // console.log('PRODUCTO>>>>>>', producto)

  dispatch(
   facturaGeneraUpdateTotalDescuento(
    'suma',
    tipoDescuento === 'sindescuento'
     ? 0.0
     : tipoDescuento === 'porcentaje'
     ? (valueDivisa * cantidad * valorDescuento) / 100
     : valorDescuento * cantidad // 0.0,
   )
  )
  dispatch(facturaGeneralUpdateSubTotal('suma', baseImponible))
  dispatch(facturaGeneralUpdateTotalIva('suma', montoGravamenCalculado))
  dispatch(
   facturaGeneralUpdateImporteTotal(
    'suma',
    baseImponible + montoGravamenCalculado
   )
  )

  if (producto.exento === true) {
   dispatch(
    facturaGeneraUpdateTotalExento('suma', Number(valueDivisa) * cantidad)
   )
  } else if (producto.exonerado === true) {
   dispatch(
    facturaGeneraUpdateTotalExonerado('suma', Number(valueDivisa) * cantidad)
   )
  }
 }
}

export const startCargaFacturaJson = (json) => {
 return async (dispatch) => {
  //Llamado al endpoint usando Axios
  console.log('ENTRO OOjson', json)
  try {
   dispatch(errorStatusLoading())
   const res = await ApiAxiosPostFetch(`${URL}/masivafacturacion`, 'POST', json)
   console.log('RRRRRRRR', res)
   if (res.code === 200) {
    // dispatch(localidadCargaFacturasJsonSetActive(res.result))
    return dispatch(errorSetActive('Archivo Cargado con Exito', 'sucess', res))
   } else {
    return dispatch(
     errorSetActive(
      `Error al Cargar el Archivo:  ${res?.message}`,
      'error',
      res?.message
     )
    )
   }
  } catch (error) {
   console.log('error', error)
   return dispatch(
    errorSetActive(
     error?.response?.data.message || `Ocurrio un error ${error}`,
     'error'
    )
   )
  }
 }
}

export const startCargaFacturaJsonXls = (json) => {
 return async (dispatch) => {
  //Llamado al endpoint usando Axios
  console.log('ENTRO OOjson', json)
  try {
   dispatch(errorStatusLoading())
   const res = await ApiAxiosPostFetch(
    `${URL}/masivafacturacionXls`,
    'POST',
    json
   )
   console.log('RRRRRRRR', res)
   if (res.code === 200) {
    // dispatch(localidadCargaFacturasJsonSetActive(res.result))
    return dispatch(errorSetActive('Archivo Cargado con Exito', 'sucess', res))
   } else {
    return dispatch(
     errorSetActive(
      `Error al Cargar el Archivo:  ${res?.message}`,
      'error',
      res?.message
     )
    )
   }
  } catch (error) {
   console.log('error', error)
   return dispatch(
    errorSetActive(
     error?.response?.data.message || `Ocurrio un error ${error}`,
     'error'
    )
   )
  }
 }
}

export const startCargaOrdenJsonXls = (json) => {
  return async (dispatch) => {
   //Llamado al endpoint usando Axios
   console.log('ENTRO OOjson', json)
   try {
    dispatch(errorStatusLoading())
    const res = await ApiAxiosPostFetch(
     `${URLORDENS}/masivaordenes`,
     'POST',
     json
    )
    console.log('RRRRRRRR', res)
    if (res.code === 200) {
     // dispatch(localidadCargaFacturasJsonSetActive(res.result))
     return dispatch(errorSetActive('Archivo Cargado con Exito', 'sucess', res))
    } else {
     return dispatch(
      errorSetActive(
       `Error al Cargar el Archivo:  ${res?.message}`,
       'error',
       res?.message
      )
     )
    }
   } catch (error) {
    console.log('error', error)
    return dispatch(
     errorSetActive(
      error?.response?.data.message || `Ocurrio un error ${error}`,
      'error'
     )
    )
   }
  }
 }


export const startCargaAnularFacturaJsonXls = (json) => {
 return async (dispatch) => {
  //Llamado al endpoint usando Axios
  console.log('ENTRO OOjson', json)
  try {
   dispatch(errorStatusLoading())
   const res = await ApiAxiosPostFetch(
    `${URL}/masivaAnularfacturacionXls`,
    'POST',
    json
   )
   console.log('🚀 ~ file: facturaGeneralActions.js:786 ~ return ~ res', res)
   if (res.result.code === 200) {
    // dispatch(localidadCargaFacturasJsonSetActive(res.result))
    return dispatch(
     errorSetActive('Archivo Cargado con Exito', 'sucess', res.result)
    )
   } else {
    return dispatch(
     errorSetActive(
      `Error al Cargar el Archivo:  ${res?.message}`,
      'error',
      res?.message
     )
    )
   }
  } catch (error) {
   console.log('error', error)
   return dispatch(
    errorSetActive(
     error?.response?.data.message || `Ocurrio un error ${error}`,
     'error'
    )
   )
  }
 }
}

export const startCargaAnularOrdenesJsonXls = (json) => {
  return async (dispatch) => {
   //Llamado al endpoint usando Axios
   console.log('ENTRO OOjson', json)
   try {
    dispatch(errorStatusLoading())
    const res = await ApiAxiosPostFetch(
     `${URLORDENS}/anular`,
     'PUT',
     json
    )
    if (res.result.code === 200) {
     // dispatch(localidadCargaFacturasJsonSetActive(res.result))
     return dispatch(
      errorSetActive('Archivo Cargado con Exito', 'sucess', res.result)
     )
    } else {
     return dispatch(
      errorSetActive(
       `Error al Cargar el Archivo:  ${res?.message}`,
       'error',
       res?.message
      )
     )
    }
   } catch (error) {
    console.log('error', error)
    return dispatch(
     errorSetActive(
      error?.response?.data.message || `Ocurrio un error ${error}`,
      'error'
     )
    )
   }
  }
 }
// RETENCIONES IVA y RETENCIONES ISRL
 export const startCargaRetencionIvaJsonXls = (json) => {
  return async (dispatch) => {
   //Llamado al endpoint usando Axios
   console.log('ENTRO OOjson', json)
   try {
    dispatch(errorStatusLoading())
    const res = await ApiAxiosPostFetch(
     `${URL}/masivaRetencionIvaXls`,
     'POST',
     json
    )
    console.log('RRRRRRRR', res)
    if (res.code === 200) {
     // dispatch(localidadCargaFacturasJsonSetActive(res.result))
     return dispatch(errorSetActive('Archivo Cargado con Exito', 'sucess', res))
    } else {
     return dispatch(
      errorSetActive(
       `Error al Cargar el Archivo:  ${res?.message}`,
       'error',
       res?.message
      )
     )
    }
   } catch (error) {
    console.log('error', error)
    return dispatch(
     errorSetActive(
      error?.response?.data.message || `Ocurrio un error ${error}`,
      'error'
     )
    )
   }
  }
 }

export const startLoadAsignaNroFactura = ({ id, tipo, sucursalTipo }) => {
 return async (dispatch, getState) => {
  //Llamado al endpoint usando Axios
  try {
   dispatch(errorStatusLoading())
   const res = await ApiAxiosGet(
    `${URL}/asignanrofactura/?id=${id}&tipo=${tipo}&sucursalTipo=${sucursalTipo}`,
    'GET'
   )
   dispatch(asignaNroFacturaLoaded(res.nroFactura))
   if (res.status) {
    return dispatch(errorSetActive('Asignado Nro Factura Cargada', 'sucess'))
   } else {
    return dispatch(
     errorSetActive('No pudo ser asignado nro de Factura ', 'error')
    )
   }
  } catch (error) {
   return dispatch(
    errorSetActive(
     error?.response?.data.message || `Ocurrio un error ${error}`,
     'error'
    )
   )
  }
 }
}

export const startfacturaActiveVendedor = () => {
 return async (dispatch, getState) => {
  const { activeVendedor } = getState().vendedor
  console.log('🚀 ~ return ~ activeVendedor:', activeVendedor)
  if (activeVendedor) {
   dispatch(setfacturaUpdateVendedor(activeVendedor.nombre))
  }
  //Llamado al endpoint usando Axios
 }
}

export const setfacturaUpdateVendedor = (vendedor) => ({
 type: types.facturaUpdateVendedor,
 payload: vendedor,
})

export const facturaClearVendedor = () => ({
 type: types.facturaClearVendedor,
})

const updateDetalleProductoExistente = (detalleProducto) => ({
 type: types.facturaupdateDetalleProductoExistente,
 payload: detalleProducto,
})

// Acción para agregar nuevo pago a la Factura
export const startNewPago = (pago) => {
 return (dispatch, getState) => {
  const { facturaDivisa, cambioDivisa, igtfAcumulado } =
   getState().facturaGeneral

  console.log('pago >>> ', pago)
  console.log('pago >>> facturaDivisa', facturaDivisa)
  console.log('pago >>> cambioDivisa', cambioDivisa)
  const igtfValor = pago.igtf
   ? facturaDivisa
     ? ((Number(pago.monto).toFixed(2) *
        Number(pago.tasaDiaDivisa).toFixed(2)) /
        Number(cambioDivisa).toFixed(2)) *
       0.03
     : Number(pago.monto).toFixed(2) *
       Number(pago.tasaDiaDivisa).toFixed(2) *
       0.03
   : 0.0
  console.log('pago >>> igtfValor', igtfValor)
  const acumIgtf = igtfAcumulado + igtfValor
  console.log('pago >>> acumIgtf', igtfAcumulado)
  dispatch(
   facturaGeneralAddNewPago(
    { ...pago, igtfValor },
    Number(parseFloat(acumIgtf).toFixed(2))
   )
  )
 }
}

//Acción para suprimir un pago
export const startDeletePago = (index, pago) => {
 console.log('Index: ' + index)
 return (dispatch, getState) => {
  dispatch(deletePago(index, pago))
 }
}

export const startCalculoCambio = () => {
 return (dispatch, getState) => {
  const { divisaActive } = getState().facturaGeneral
  const { lstTasas } = getState().tasas
  const { USD, EUR } = lstTasas
  console.log(divisaActive)

  if (divisaActive.siglas === 'USD') {
   console.log(USD.promedio_real)
  } else if (divisaActive.siglas === 'EUR') {
   console.log(EUR.promedio_real)
  }

  // dispatch(deletePago(index));
 }
}

export const startClearFacturaActive = () => {
 return (dispatch, getState) => {
  console.log('Clear FacturaActive')
  dispatch(facturaGeneralClearActive())
 }
}

const facturaGeneralLoaded = (facturaGenerales) => ({
 type: types.facturaGeneralLoaded,
 payload: facturaGenerales,
})

const facturaGeneralDetallesLoaded = (detalles) => ({
 type: types.facturaGeneralDetallesLoaded,
 payload: detalles,
})

const facturaGeneralPagosLoaded = (pagos) => ({
 type: types.facturaGeneralPagosLoaded,
 payload: pagos,
})

const facturaGeneralGravamenesLoaded = (gravamenes) => ({
 type: types.facturaGeneralGravamenesLoaded,
 payload: gravamenes,
})

export const facturaGeneralOpenModal = () => ({
 type: types.facturaGeneralOpenModal,
})

export const facturaGeneralCloseModal = () => ({
 type: types.facturaGeneralCloseModal,
})

export const facturaGeneralOpenModalShow = () => ({
 type: types.facturaGeneralOpenModalShow,
})

export const facturaGeneralCloseModalShow = () => ({
 type: types.facturaGeneralCloseModalShow,
})

export const facturaGeneralOpenDetalleModal = () => ({
 type: types.facturaGeneralOpenDetalleModal,
})

export const facturaGeneralCloseDetalleModal = () => ({
 type: types.facturaGeneralCloseDetalleModal,
})

export const facturaGeneralOpenPagoModal = () => ({
 type: types.facturaGeneralOpenPagoModal,
})

export const facturaGeneralClosePagoModal = () => ({
 type: types.facturaGeneralClosePagoModal,
})

export const facturaGeneralOpenModalDelete = () => ({
 type: types.facturaGeneralOpenModalDelete,
})

export const facturaGeneralCloseModalDelete = () => ({
 type: types.facturaGeneralCloseModalDelete,
})

export const facturaGeneralAddNew = (facturaGeneral) => ({
 type: types.facturaGeneralAddNew,
 payload: facturaGeneral,
})

export const facturaGeneralSetActive = (facturaGeneral) => ({
 type: types.facturaGeneralSetActive,
 payload: facturaGeneral,
})

export const facturaGeneralClearActive = () => ({
 type: types.facturaGeneralClearActive,
})

export const facturaGeneralUpdated = (facturaGeneral) => ({
 type: types.facturaGeneralUpdated,
 payload: facturaGeneral,
})

export const facturaGeneralGenerada = (idFactura, FacguraDell) => ({
 type: types.facturaGeneralGenerada,
 payload: { idFactura, FacguraDell },
})

export const anularFacturaGeneral = (idFactura, logEstatusRegistro) => ({
 type: types.facturaGeneralAnular,
 payload: { idFactura, logEstatusRegistro },
})

export const deletedFacturaGeneral = (idFactura) => ({
 type: types.facturaGeneralDelete,
 payload: idFactura,
})

export const reactiveFacturaGeneral = (facturaGeneral) => ({
 type: types.facturaGeneralReactived,
 payload: facturaGeneral,
})

export const facturaGeneralVerPdf = (facturaCodificada) => ({
 type: types.facturaGeneralVerPdf,
 payload: facturaCodificada,
})

export const facturaGeneralClearPdf = () => ({
 type: types.facturaGeneralClearPdf,
})

//Acciones para Detalle
export const facturaGeneralAddNewDetalle = (detalle) => ({
 type: types.facturaGeneralAddNewDetalle,
 payload: detalle,
})

export const deleteDetalle = (index) => ({
 type: types.facturaGeneralDeleteDetalle,
 payload: index,
})
export const setClearlstDetalles = () => ({
 type: types.facturaGeneralClearlstDetalles,
})

//Acciones para Pagos
const facturaGeneralAddNewPago = (pago, acumulado) => ({
 type: types.facturaGeneralAddNewPago,
 payload: { pago, acumulado },
})

export const deletePago = (idPago, pago) => ({
 type: types.facturaGeneralDeletePago,
 payload: { idPago, pago },
})

//Acciones para clientes
export const setClienteActive = (cliente) => ({
 type: types.facturaGeneralClienteSelected,
 payload: cliente,
})

export const setClienteClearActive = () => ({
 type: types.facturaGeneralClienteClearSelected,
})

export const clienteOpenModal = () => ({
 type: types.facturaGeneralClienteOpenModal,
})

export const clienteCloseModal = () => ({
 type: types.facturaGeneralClienteCloseModal,
})

//Acciones para Localidad
export const setLocalidadActive = (localidad) => ({
 type: types.facturaGeneralLocalidadSelected,
 payload: localidad,
})

export const setLocalidadClearActive = () => ({
 type: types.facturaGeneralLocalidadClearSelected,
})

//Acciones para Sucursal
export const setSucursalActive = (sucursal) => ({
 type: types.facturaGeneralSucursalSelected,
 payload: sucursal,
})

export const setSucursalClearActive = () => ({
 type: types.facturaGeneralSucursalClearSelected,
})

//Acciones para Tipo Venta
export const setTipoVentaActive = (tipoVenta) => ({
 type: types.facturaGeneralTipoVentaSelected,
 payload: tipoVenta,
})

//Acciones para nro de Factura
export const setNrofactura = (tipoVenta) => ({
 type: types.facturaGeneralNrofacturaSelected,
 payload: tipoVenta,
})

export const setTipoVentaClearActive = () => ({
 type: types.facturaGeneralTipoVentaClearSelected,
})

//Acciones para Banco
export const setBancoActive = (banco) => ({
 type: types.facturaGeneralBancoSelected,
 payload: banco,
})

export const setBancoClearActive = () => ({
 type: types.facturaGeneralBancoClearSelected,
})

//Acciones para MedioPago
export const setMedioPagoActive = (medioPago) => ({
 type: types.facturaGeneralMedioPagoSelected,
 payload: medioPago,
})

export const setMedioPagoClearActive = () => ({
 type: types.facturaGeneralMedioPagoClearSelected,
})

//Acciones para Divisa

export const setDivisaClearActive = () => ({
 type: types.facturaGeneralDivisaClearSelected,
})

//Acciones para Condición de Pago
export const setCondicionActive = (condicion) => ({
 type: types.facturaGeneralCondicionSelected,
 payload: condicion,
})

export const setCondicionClearActive = () => ({
 type: types.facturaGeneralCondicionClearSelected,
})

//Acciones para Días Crédito
export const setDiasCreditoActive = (diasCredito) => ({
 type: types.facturaGeneralDiasCreditoSelected,
 payload: diasCredito,
})

export const setDiasCreditoClearActive = () => ({
 type: types.facturaGeneraDiasCreditoClearSelected,
})

//Acciones para totales
const facturaGeneralUpdateSubTotal = (operacion, monto) => ({
 type: types.facturaGeneralUpdateSubTotal,
 payload: { operacion, monto },
})

const facturaGeneraUpdateTotalExento = (operacion, monto) => ({
 type: types.facturaGeneralUpdateExento,
 payload: { operacion, monto },
})
const facturaGeneraUpdateTotalDescuento = (operacion, monto) => ({
 type: types.facturaGeneralUpdateTotalDescuento,
 payload: { operacion, monto },
})

const facturaGeneraUpdateTotalExonerado = (operacion, monto) => ({
 type: types.facturaGeneralUpdateExonerado,
 payload: { operacion, monto },
})

const facturaGeneralUpdateTotalIva = (operacion, monto) => ({
 type: types.facturaGeneralUpdateTotalIva,
 payload: { operacion, monto },
})

const facturaGeneralUpdateImporteTotal = (operacion, monto) => ({
 type: types.facturaGeneralUpdateImporteTotal,
 payload: { operacion, monto },
})

const facturaGeneralUpdateTotalesModificar = (facturaActiva) => ({
 type: types.facturaGeneralUpdateTotalesModificar,
 payload: facturaActiva,
})

//Acciones para Gravamen
export const facturaGeneralAddNewGravamen = (gravamen) => ({
 type: types.facturaGeneralAddNewGravamen,
 payload: gravamen,
})

const facturaGeneralUpdatelLstGravamenes = ({
 baseImponible,
 tipo,
 montoAlicuota,
}) => ({
 type: types.facturaGeneralUpdatelLstGravamenes,
 payload: { baseImponible, tipo, montoAlicuota },
})

const facturaGeneralUpdateTotalGravamen = ({
 idTipoGravamen,
 baseImponible,
 montoAlicuota,
}) => ({
 type: types.facturaGeneralUpdateTotalGravamen,
 payload: { idTipoGravamen, baseImponible, montoAlicuota },
})

const facturaGeneralDeleteUpdateTotalGravamen = ({
 TipoGravamen,
 baseImponible,
 idgravamen,
 montoGravamen,
}) => ({
 type: types.facturaupdatedeleteTotalGravame,
 payload: { TipoGravamen, baseImponible, idgravamen, montoGravamen },
})

//Acciones para Actualizar algun Valor de la factura
export const setUpdateFactura = (valor) => ({
 type: types.setUpdateFactura,
 payload: valor,
})

export const clearDetalleProducto = () => ({
 type: types.clearDetalleProducto,
})

export const startLoadCanbioDivisa = () => {
 return (dispatch, getState) => {
  const { activeDivisa } = getState().divisa
  dispatch(setLoadCambioDivisa(activeDivisa?.value))
 }
}
const setLoadCambioDivisa = (divisa) => ({
 type: types.facturaGeneralLoadCambioDivisa,
 payload: divisa,
})

export const startLoadCambioDiaUsd = () => {
 return (dispatch, getState) => {
  const { divisas } = getState().divisa
  dispatch(
   setLoadtipoCambioDiaUsd(divisas?.data.find((divisa) => divisa.iso === 'USD'))
  )
 }
}

const setLoadtipoCambioDiaUsd = (divisa) => ({
 type: types.facturaGeneralLoadtLoadtipoCambioDiaUsd,
 payload: divisa,
})

export const setSavecuentaTerceros = (cuentaTerceros) => ({
 type: types.facturaGeneracuentaTerceros,
 payload: cuentaTerceros,
})

export const setClearcuentaTerceros = () => ({
 type: types.facturaGeneracuentaTercerosClear,
})

const asignaNroFacturaLoaded = (asignaNroFactura) => ({
 type: types.facturaAsignaNroFacturaLoaded,
 payload: asignaNroFactura,
})

export const asignaNroFacturaClear = () => ({
 type: types.facturaGeneralNrofacturaClearSelected,
})
