import { format } from 'date-fns'
import React from 'react'

export const columnas = [
  {
    title: 'Rif',
    field: 'rif',
    numeric: false,
    render: (rowData) => {
      return (
        <div
          style={{
            fontSize: '10px',
            // backgroundColor: '#054477',
            // color: 'whitesmoke',
            textAlign: 'center',
          }}
        >
          {rowData.rif}
        </div>
      )
    },
  },

  {
    title: 'Nro. Control',
    field: 'NroControlComprobante',
    numeric: false,
    render: (rowData) => {
      return (
        <div
          style={{
            fontSize: '10px',
            textAlign: 'center',
            // maxWidth: '100px',
          }}
        >
          {rowData.NroControlComprobante}
        </div>
      )
    },
  },
  {
    title: 'Nro. Comprobante',
    field: 'nroComprobante',
    numeric: false,
    render: (rowData) => {
      return (
        <div
          style={{
            fontSize: '10px',
            textAlign: 'center',
            // maxWidth: '100px',
          }}
        >
          {rowData.nroComprobante}
        </div>
      )
    },
  },
  {
    title: 'Rif Proveedor',
    field: 'rifProveedor',
    numeric: false,
    render: (rowData) => {
      return (
        <div
          style={{
            fontSize: '10px',
            textAlign: 'center',
          }}
        >
          {rowData.rifProveedor}
        </div>
      )
    },
  },
  {
    title: 'razon social',
    field: 'identificacion',
    numeric: false,
    render: (rowData) => {
      return (
        <div
          style={{
            // minWidth: '80px',
            fontSize: '10px',
            // backgroundColor: '#054477',
            // color: 'whitesmoke',
            textAlign: 'left',
          }}
        >
          {rowData.identificacion}
        </div>
      )
    },
  },
  {
    title: 'Fecha Emisión',
    field: 'fechaEmision',
    numeric: false,
    render: (rowData) => {
      return (
        <div
          style={{
            fontSize: '10px',
            textAlign: 'center',
          }}
        >
          {format(new Date(rowData.fechaEmision), 'dd/MM/yyyy')}
        </div>
      )
    },
  },
  {
    title: 'Periodo Fiscal',
    field: 'periodoFiscal',
    numeric: false,
    render: (rowData) => {
      return (
        <div
          style={{
            fontSize: '10px',
            textAlign: 'center',
          }}
        >
          {` desde: ${format(
            new Date(rowData?.peridofiscalDel || rowData?.fechaEmision),
            'dd/MM/yyyy'
          )} hasta: ${format(
            new Date(rowData?.peridofiscalAl || rowData?.fechaEmision),
            'dd/MM/yyyy'
          )}`}
        </div>
      )
    },
  },

  {
    id: 'logEstatusRegistro',
    title: 'Estatus',
    field: 'logEstatusRegistro',
    numeric: false,
    render: (rowData) => (
      <div style={{ fontSize: '12px' }}>
        {rowData?.logEstatusRegistro === 'INACTIVO' ? (
          <div
            style={{
              color: 'white',
              backgroundColor: 'tomato',
              textAlign: 'center',
              // textTransform: 'uppercase',
              width: '100px',
              padding: '2px',
              borderRadius: '5px',
              // border: 'solid 1px black',
              margin: 'auto',
            }}
          >
            Anulada
          </div>
        ) : (
          <div
            style={{
              color: 'blue',
              textAlign: 'center',
              // textTransform: 'uppercase',
              width: '100px',
              padding: '2px',
              borderRadius: '5px',
              // border: 'solid 1px black',
              margin: 'auto',
            }}
          >
            Activa
          </div>
        )}
      </div>
    ),
  },

  {
    title: 'Tipo',
    field: 'modeloRetencion',
    numeric: false,
    render: (rowData) => {
      return (
        <div
          style={{
            fontSize: '10px',
            textAlign: 'center',
          }}
        >
          {rowData.modeloRetencion === 'IVA' ? (
            <div
              style={{
                fontSize: '10px',
                fontWeight: 'bold',
                color: 'white',
                backgroundColor: '#2E865F',
                padding: '2px',
                borderRadius: '5px',
              }}
            >
              IVA
            </div>
          ) : (
            <div
              style={{
                fontSize: '10px',
                fontWeight: 'bold',
                color: 'white',
                backgroundColor: '#3498DB',
                padding: '2px',
                borderRadius: '5px',
              }}
            >
              ISLR
            </div>
          )}
        </div>
      )
    },
  },
]

export const opciones = {
  sorting: true,
  actionsColumnIndex: -1,
  headerStyle: {
    backgroundColor: '#085c9f',
    color: '#FFF',
  },
  exportButton: true,
  rowStyle: (rowData) => {
    if (rowData.logEstatusRegistro === 'INACTIVO') {
      return { backgroundColor: '#F3EFEE' }
    }
  },
}

export const localization = {
  header: {
    actions: 'Acciones',
  },
  toolbar: {
    searchPlaceholder: 'Buscar',
    searchTooltip: 'Buscar',
    exportTitle: 'Exportar',
    exportCSVName: 'Exportar en formato excel',
    exportPDFName: 'Exportar como pdf',
  },
  pagination: {
    firstAriaLabel: 'Primera página',
    firstTooltip: 'Primera página',
    previousAriaLabel: 'Página anterior',
    previousTooltip: 'Página anterior',
    nextAriaLabel: 'Página siguiente',
    nextTooltip: 'Página siguiente',
    lastAriaLabel: 'Última página',
    lastTooltip: 'Última página',
    labelDisplayedRows: '{from}-{to} de {count}',
    labelRowsSelect: 'filas',
  },
  body: {
    emptyDataSourceMessage: 'No existen datos para mostrar',
  },
}
