import { CardContent, Card, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useRetenciones from '../../../../hooks/useRetenciones'
import { format } from 'date-fns'
import { DeleteForever } from '@material-ui/icons'
import { startretencionRemoveItem } from '../../../../redux/actions/retencionActions'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '25vh',
    scrollBehavior: 'smooth',
    overflowY: 'scroll',
    margin: '2px 4px 2px 4px',
  },
  table: {
    // width: '100%',
  },
  title: {
    fontSize: '10px',
    // fontWeight: 'bolder',
    backgroundColor: '#e0e0e0',
    // padding: '2px 4px 2px 4px',
    border: '.1px solid #000',
  },
  tr: {
    fontSize: '10px',
    // padding: '2px 4px 2px 4px',
    border: '.1px solid #000',
    // scrollBehavior: 'smooth',
    // overflowY: 'scroll',
  },
  th: {
    textAlign: 'center',
    fontSize: '10px',
    // fontWeight: 'bolder',
    padding: '2px 4px 2px 4px',
    border: '.1px solid #000',
    height: '30px',
  },
}))

const EncabezadoDetalle = () => {
  const classes = useStyles()
  const { addRetencion } = useRetenciones()
  const dispatch = useDispatch()

  const handleDelete = (index) => {
    console.log('🚀 ~ handleDelete ~ index:', index)
    //   const newRetenciones = addRetencion.filter((_, i) => i !== index)
    dispatch(startretencionRemoveItem(index))
  }

  console.log('🚀 ~ EncabezadoDetalle ~ addRetencion:', addRetencion)
  return (
    <Card>
      <CardContent>
        <div className={classes.root}>
          <table className={classes.table}>
            <tr className={classes.title}>
              <th className={classes.th}>N° Retencion</th>
              <th className={classes.th}>Fecha factura o nota de débito</th>
              <th className={classes.th}>Numero de Factura o nota de débito</th>
              <th className={classes.th}>
                Numero de Control de la factura o nota de débito
              </th>
              <th className={classes.th}>N° Nota de Crédito</th>
              <th className={classes.th}>Tipo de Transacción</th>
              <th className={classes.th}>N° del Documento Afectado</th>
              <th className={classes.th}>
                Monto total de la factura o nota de debito
              </th>
              <th className={classes.th}>
                Compras sin Derecho a Crédito (exento)
              </th>
              <th className={classes.th}>Base Imponible del IVA</th>
              <th className={classes.th}>Alicuota del IVA</th>
              <th className={classes.th}>Impuesto de I.V.A. causado</th>
              <th className={classes.th}>Monto del I.V.A. Retenido</th>
              <th className={classes.th}>Accion</th>
            </tr>
            {addRetencion.lstRetenciones.map((item, index) => (
              <tr key={index} className={classes.tr}>
                <td className={classes.th}>{index + 1}</td>
                <td className={classes.th}>
                  {format(item.fechaDocumento, 'dd/MM/yyyy')}
                </td>
                <td className={classes.th}>{item.nroDocumento}</td>
                <td className={classes.th}>{item.nroControlDocumento}</td>
                <td className={classes.th}>{item.nroNota}</td>
                <td className={classes.th}>{item.tipoTransaccion}</td>
                <td className={classes.th}>{item.nroDocumentoAfectado}</td>
                <td className={classes.th}>{item.montoTotalDocumento}</td>
                <td className={classes.th}>{item.compraExento}</td>
                <td className={classes.th}>{item.baseImponible}</td>
                <td className={classes.th}>{item.Alicuota}</td>
                <td className={classes.th}>{item.inpuestoCausado}</td>
                <td className={classes.th}>{item.impuestoRetenido}</td>
                <td className={classes.th}>
                  <IconButton
                    color='secondary'
                    size='small'
                    aria-label='Eliminar'
                    onClick={() => handleDelete(index)}
                  >
                    <DeleteForever />
                  </IconButton>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </CardContent>
    </Card>
  )
}

export default EncabezadoDetalle
