import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ModalGenerico from '../../commons/ui/Modal'
// import useOrden from '../../../hooks/useOrden'
import { startLoadOrdenCodificada } from '../../../redux/actions/ordenActions'
import { startLoadRetencionCodificada } from '../../../redux/actions/retencionActions'

export default function MostrarPdf({
  open,
  handleClose,
  title,
  activeRetencion,
}) {
  const [facturaCodificada, setfacturaCodificada] = useState(null)
  const dispatch = useDispatch()
  //  const { activeRetencion } = useOrden()
  console.log('🚀 ~ MostrarPdf ~ activeRetencion:', activeRetencion)

  useEffect(() => {
    dispatch(startLoadRetencionCodificada(activeRetencion?.id)).then((res) => {
      setfacturaCodificada(res?.pdfBase64)
    })
  }, [activeRetencion, dispatch])

  return (
    <ModalGenerico
      open={open}
      handleClose={handleClose}
      title={`Nro.control Retencion: ${activeRetencion.NroControlComprobante}`}
      top={55}
      left={55}
    >
      <iframe
        src={`data:application/pdf;base64,${facturaCodificada}`}
        title='Factura'
        name='Factura'
        style={{
          border: 'none',
          minHeight: '80vh',
          minWidth: '80vh',
        }}
      />
    </ModalGenerico>
  )
}
