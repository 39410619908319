import {
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  TextField,
  InputAdornment,
} from '@material-ui/core'
import useStyles from './Styles'
import SearchIcon from '@material-ui/icons/Search'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import SearchSharp from '@material-ui/icons/SearchSharp'
import { doSearch } from '../../../Helpers/Search'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../Helpers/AuthPermisos'
import FiltroRetencion from './Filtros'

export const EnhancedTableToolbar = ({
  handleOpen,
  title,
  openFiltro,
  handleclosefiltro,
  hnadleOpenFiltro,
  goToBase,
  handleCloseGenerarMasivaModal,
  setModalGenerarMasFacturaIva,
}) => {
  var classes = useStyles()
  const { roles } = useSelector((state) => state.role)
  const { UsuarioLogiado: user } = useSelector((state) => state.auth)

  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant='h6'
        id='tableTitle'
        component='div'
      >
        {title}
      </Typography>
      <TextField
        id='searchTerm'
        type='search'
        // variant="outlined"
        onChange={doSearch}
        placeholder='Buscar'
        margin='normal'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        style={{
          // margin: '0 auto',
          width: 500,
        }}
      />

      {openFiltro && (
        <FiltroRetencion
          open={openFiltro}
          handleClose={handleclosefiltro}
          title='Filtro Retenciones'
        />
      )}

      {/* {hasPermission(
        roles.filter((r) => r.id === user.user.role),
        '/facturacion/OrdenesGuias',
        'search',
        user.user
      ) && (
        <Tooltip title='filtro de busqueda Retencion'>
          <IconButton aria-label='Filtrar Retencion' onClick={hnadleOpenFiltro}>
            <SearchSharp className={classes.btnAdd} />
          </IconButton>
        </Tooltip>
      )} */}

      {/* {hasPermission(
        roles.filter((r) => r.id === user.user.role),
        '/retenciones/iva-isrl/Agregar',
        'add',
        user.user
      ) && (
        <Tooltip title='Agrega una Nueva Retencion'>
          <IconButton aria-label='Agregar Retencion' onClick={goToBase}>
            <AddShoppingCartIcon className={classes.btnAdd} />
          </IconButton>
        </Tooltip>
      )} */}

      {/* {hasPermission(
        roles.filter((r) => r.id === user.user.role),
        '/retenciones/iva/Agregar',
        'add',
        user.user
      ) && (
        <Tooltip title='Carga Masiva de Retenciones hoja Excel'>
          <IconButton
            aria-label='Agregar Retencion'
            onClick={() => setModalGenerarMasFacturaIva(true)}
          >
            <Archive className={classes.btnAdd} style={{ color: 'red' }} />
          </IconButton>
        </Tooltip>
      )} */}
    </Toolbar>
  )
}
