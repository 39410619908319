import { types } from '../types/types'

const initialState = {
  conceptosIsrl: [],
  retenciones: {
    limit: 0,
    page: 0,
    totalpages: 0,
    totalResults: 0,
    results: [],
  },
  activeRetencion: null,
  filtro: {},
  addRetencion: {
    fechaEmision: null,
    peridofiscalDel: null,
    periodoFiscalAl: null,
    rif: null,
    rifProveedor: null,
    identificacion: null,
    direccion: null,
    correo: null,
    modeloRetencion: null,
    lstRetenciones: [],
  },
}

export const retencionReducer = (state = initialState, action) => {
  console.log('🚀 ~ retencionReducer ~ state:', state)
  console.log('🚀 ~ retencionReducer ~ action:', action.payload)

  switch (action.type) {
    case types.retencionesLoaded:
      return {
        ...state,
        retenciones: action.payload,
      }
    case types.retencionesAdd:
      return {
        ...state,
        addRetencion: {
          fechaEmision: action.payload.fechaEmision,
          peridofiscalDel: action.payload.peridofiscalDel,
          periodoFiscalAl: action.payload.periodoFiscalAl,
          rif: action.payload.rif,
          rifProveedor: action.payload.rifProveedor,
          identificacion: action.payload.identificacion,
          direccion: action.payload.direccion,
          correo: action.payload.correo,
          modeloRetencion: action.payload.modeloRetencion,
          lstRetenciones: [...state.addRetencion.lstRetenciones],
        },
      }
    case types.addRetencionClear:
      return {
        ...state,
        addRetencion: {
          NroRetencion: null,
          NroControlComprobante: null,
          fechaEmision: null,
          peridofiscalDel: null,
          periodoFiscalAl: null,
          rif: null,
          rifProveedor: null,
          identificacion: null,
          direccion: null,
          correo: null,
          modeloRetencion: null,
          lstRetenciones: [],
        },
      }

    case types.lstRetencionesClear:
      return {
        ...state,
        addRetencion: {
          ...state.addRetencion,
          lstRetenciones: [],
        },
      }

    case types.retencionesClear:
      return {
        ...state,
        retenciones: {
          limit: 0,
          page: 0,
          totalpages: 0,
          totalResults: 0,
          results: [],
        },
      }
    case types.retencionActive:
      return {
        ...state,
        activeRetencion: action.payload,
      }
    case types.retencionClearActive:
      return {
        ...state,
        activeRetencion: null,
      }

    case types.filtroRetencionAdd:
      return {
        ...state,
        filtro: action.payload,
      }
    // agregar a la retencion un item a la list de lsretenciones
    case types.retencionAddItem:
      return {
        ...state,
        addRetencion: {
          ...state.addRetencion,
          lstRetenciones: [
            ...state.addRetencion.lstRetenciones,
            action.payload,
          ],
        },
      }
    // quitar de la lista de retenciones
    case types.retencionRemoveItem:
      return {
        ...state,
        addRetencion: {
          ...state.addRetencion,
          lstRetenciones: state.addRetencion.lstRetenciones.filter(
            (_, i) => i !== action.payload
          ),
        },
      }

    case types.retencionesClearFiltro:
      return {
        ...state,
        filtro: {},
      }
    // case types.anularRetencion:
    //   return {
    //     ...state,
    //     retenciones: {
    //       ...state.retenciones,
    //       results: state.retenciones.results.map((retencion) => {
    //         if (retencion.nroRetencion === action.payload.idRetencion) {
    //           return {
    //             ...retencion,
    //             logEstatusRegistro: action.payload.logEstatusRegistro,
    //           }
    //         } else {
    //           return retencion
    //         }
    //       }),
    //     },
    //     activeRetencion: null,
    //   }
    // case types.setUpdateRetencion:
    //   return {
    //     ...state,
    //     retenciones: {
    //       ...state.retenciones,
    //       results: state.retenciones.results.map((retencion) => {
    //         if (retencion.nroRetencion === action.payload.nroRetencion) {
    //           return {
    //             ...retencion,
    //             ...action.payload,
    //           }
    //         } else {
    //           return retencion
    //         }
    //       }),
    //     },
    //     activeRetencion: null,
    //   }
    case types.queryConceptosIsrl:
      return {
        ...state,
        conceptosIsrl: action.payload,
      }
    default:
      return state
  }
}
