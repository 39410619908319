import { Container, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useToast } from '../../../../hooks/useToast'
import { useSelector } from 'react-redux'

import AddRetencionIva from './AddRetencionesIva'
import DetalleRetencionIva from './DetalleRetencionIva'
import ViewDetalles from './ViewDetalles'
import DetalleRetencionIslr from './DetalleRetencionIslr'
import { useDispatch } from 'react-redux'
import { setClearAddRetencion } from '../../../../redux/actions/retencionActions'

export default function CrearRetencionesIva() {
  const dispatch = useDispatch()
  const { notifyError, notifysucess, Toaster } = useToast()
  const [Siguiente, setSiguiente] = useState(false)
  const { isError, tipoMsg } = useSelector((state) => state.error)
  const { modeloRetencion } = useSelector(
    (state) => state.retencion.addRetencion
  )

  useEffect(() => {
    dispatch(setClearAddRetencion())
  }, [dispatch])

  console.log('🚀 ~ CrearRetencionesIva ~ modeloRetencion:', modeloRetencion)

  return (
    <Container style={{ marginTop: '8px' }}>
      <Toaster />
      <Grid container spacing={2}>
        <Grid item lg={6} sm={6} xl={12} xs={12}>
          <AddRetencionIva setSiguiente={setSiguiente} Siguiente={Siguiente} />
        </Grid>
        <Grid item lg={6} sm={6} xl={12} xs={12}>
          <Grid
            container
            spacing={2}
            alignContent='center'
            justify='space-between'
          >
            <Grid item>
              {modeloRetencion === 'ISLR' && (
                <DetalleRetencionIslr Siguiente={Siguiente} />
              )}

              {modeloRetencion === 'IVA' && (
                <DetalleRetencionIva Siguiente={Siguiente} />
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <ViewDetalles Siguiente={Siguiente} />
        </Grid>
      </Grid>
      {isError && tipoMsg === 'sucess'
        ? notifysucess()
        : tipoMsg === 'error' && notifyError()}
    </Container>
  )
}
