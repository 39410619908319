import { useState, useEffect } from 'react'
import {
  Grid,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Checkbox,
  FormControlLabel,
  Button,
  Fab,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import toast from 'react-hot-toast'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  // phoneRegExp,
  tipodoc,
  tipoProveedor,
} from '../../../Constants/ConstantesFacturas'

import ModalGenerico from '../../commons/ui/Modal'
// import { startLoadEstados } from '../../../redux/actions/estadoActions'
import { useEstados } from '../../../hooks/useEstados'
import {
  localidadClearActive,
  startSaveLocalidad,
  startUpdateLocalidad,
} from '../../../redux/actions/localidadActions'
import {
  convertBase64,
  filtroNumLetras,
  soloTextyNumeros,
} from '../../../Helpers/formater'
import { useMunicipio } from '../../../hooks/useMunicipios'
import { startLoadMunicipiosByEstado } from '../../../redux/actions/municipioActions'
import NumberFormat from 'react-number-format'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '550px',
    alignItems: 'end',
    fontSize: '10px',
    '& .MuiInputBase-input': {
      fontSize: '10px',
      padding: '8px',
    },
  },
  error: {
    fontSize: 10,
    fontWeight: 500,
    color: 'red',
  },
  sublabel: {
    fontSize: '10px',
    color: '#546E7A',
    // alignItems: 'center',
  },
  select: {
    fontSize: '10px',
    textAlign: 'left',
  },
  text: {
    // marginTop: '16px',
    fontSize: '10px',
    color: '#546E7A',
    textAlign: 'left',
    // margin: 0,
    // padding: 16,
  },
  margen: {
    marginTop: '24px',
  },
  textborde: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    verticalAlign: 'top',
  },

  inputtelefono: {
    fontSize: '10px',
    width: '100%',
    border: 'none',
    borderBottom: '1px solid red',
  },

  formcontrol: {
    margin: '1px',
  },

  control: {
    width: '100%',
    border: '.1px solid grey',
    marginTop: '16px',
    marginBottom: '16px',
  },

  radio: {
    '& .MuiSvgIcon-root': {
      fontSize: 16,
      color: 'blue',
    },
    '& .MuiTypography-body1': {
      fontSize: 14,
    },
  },

  btn: {
    width: '100%',
    fontSize: 14,
    padding: '4px 8px',
    borderRadius: 10,
  },
  field: {
    color: 'blue',
  },
}))

const schema = yup.object().shape({
  aerolinea: yup.boolean().required('Es requerido'),
  contribuyenteEspecial: yup.boolean().required('Es requerido'),
  correo: yup
    .string()
    .email('debe ser un correo valido')
    .required('Es requerido'),
  direccion: yup
    .string()
    .max(200, 'Maximo 200 caracteres permitidos, para la descripcion')
    .required('Es requerido'),
  estado: yup.string().required('Es requerido'),
  nombre: yup.string().required('Es requerido'),
  municipio: yup.string().required('Es requerido'),
  numeroDocumento: yup
    .string()
    .min(9, 'minimo valor requerido es 10 caracteres')
    .required('Es requerido'),
  telefono: yup.string().required('Es requerido'),
  tipoDocumento: yup.string().required('Es requerido'),
  tipoProveedor: yup.string().required('Es requerido'),
  // base64Sello: yup.string(),
})

export default function UpdateLocalidad({ open, handleClose, title }) {
  const [logo, setlogo] = useState()
  const [sello, setsello] = useState(null)
  const notifyerror = (res) => toast.error(res)

  // const [lstmunicipios, setlstmunicipios] = useState()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { activeLocalidad } = useSelector((state) => state.localidad)
  const { estados } = useEstados()
  const { municipiosByEstado } = useMunicipio()

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      tipoDocumento: activeLocalidad?.tipoDocumento,
      numeroDocumento: activeLocalidad?.numeroDocumento,
      nombre: activeLocalidad?.nombre,
      // descripcion: activeLocalidad.descripcion,
      direccion: activeLocalidad?.direccion,
      telefono: activeLocalidad?.telefono,
      correo: activeLocalidad?.correo,
      contribuyenteEspecial: activeLocalidad?.contribuyenteEspecial,
      aerolinea: activeLocalidad?.aerolinea,
      tipoProveedor: activeLocalidad?.tipoProveedor,
      // rutaLogo: activeLocalidad.rutaLogo,
      estado: activeLocalidad?.id_estado,
      municipio: activeLocalidad?.id_municipio,
    },
  })

  const onSubmitLocalidad = async (data) => {
    console.log(' data', data)

    console.log('DATA direccion', data.direccion.length)
    console.log("🚀 ~ onSubmitLocalidad ~ sello:", sello)

    dispatch(
      startUpdateLocalidad({
        nombre: data.nombre,
        // descripcion: data.direccion,
        tipoDocumento: data.tipoDocumento,
        numeroDocumento: data.numeroDocumento,
        id_municipio: data.municipio,
        id_estado: data.estado,
        direccion: data.direccion,
        telefono: data.telefono,
        correo: data.correo,
        contribuyenteEspecial: data.contribuyenteEspecial,
        // totalRetencion: null,
        aerolinea: data.aerolinea,
        rutaLogo: logo,
        base64Sello: sello,
        tipoProveedor: data.tipoProveedor,
        // logUsername": "sadmin"
      })
    ).then((res) => {
      console.log('res', res)
      if (res.payload.tipoMsg === 'sucess') {
        handleClose()
        // dispatch(localidadClearActive())
      }
    })
  }

  const uploadImageSello = async (e) => {
    const file = e.target.files[0]
    console.log('file', file)
    if (file.type !== 'image/png') {
      notifyerror(`Solo se Admiten archivos con formato .png`)
      return false
    }

    const base64 = await convertBase64(file)
    setsello(base64)
  }

  const uploadImage = async (e) => {
    const file = e.target.files[0]
    console.log('file', file)
    if (file.type !== 'image/jpeg') {
      notifyerror(`Solo se Admiten archivos con formato .JPG`)
      return false
    }

    const base64 = await convertBase64(file)
    console.log('logo', logo)
    setlogo(base64)
  }

  useEffect(() => {
    dispatch(startLoadMunicipiosByEstado(activeLocalidad?.id_estado))
  }, [dispatch, activeLocalidad])

  const handleChangeMunicipio = (e) => {
    dispatch(startLoadMunicipiosByEstado(e))
  }

  // console.log('activeLocalidadACTIVIDAD', activeLocalidad)

  return (
    <ModalGenerico open={open} handleClose={handleClose} title={title}>
      <form onSubmit={handleSubmit(onSubmitLocalidad)} autoComplete='off'>
        <Grid container spacing={2} className={classes.root}>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <Controller
              control={control}
              name='tipoDocumento'
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <InputLabel variant='standard' htmlFor='uncontrolled-native'>
                    Tipo Doc.
                  </InputLabel>
                  <Select
                    fullWidth
                    className={classes.select}
                    onChange={onChange}
                    value={value}
                    error={!!error}
                  >
                    {tipodoc.map((option) => {
                      return (
                        <MenuItem
                          className={classes.sublabel}
                          key={option.key}
                          value={option.value}
                        >
                          {option.value}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <Controller
              name='numeroDocumento'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <NumberFormat
                  // format='#########'
                  allowLeadingZeros={true}
                  customInput={TextField}
                  className={classes.text}
                  label='Nro. Documento'
                  // variant='outlined'
                  defaultValue={0}
                  size='small'
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Controller
              name='nombre'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  className={classes.text}
                  label='Nombre'
                  size='small'
                  fullWidth
                  value={value}
                  onChange={onChange}
                  // onChange={(e) =>
                  //   onChange(soloTextyNumeros(e.target.value, filtroNumLetras))
                  // }
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <Controller
              control={control}
              name='estado'
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <InputLabel variant='standard' htmlFor='uncontrolled-native'>
                    Estados
                  </InputLabel>
                  <Select
                    fullWidth
                    name='estados'
                    defaultValue={activeLocalidad?.id_estado}
                    className={classes.select}
                    onChange={(e) => {
                      handleChangeMunicipio(e.target.value)
                      onChange(e.target.value)
                    }}
                    value={value}
                    error={!!error}
                  >
                    {estados
                      .sort((a, b) => {
                        if (a.estado > b.estado) return 1
                        if (a.estado < b.estado) return -1
                        return 0
                      })
                      .map((option) => {
                        return (
                          <MenuItem
                            className={classes.sublabel}
                            key={option.id_estado}
                            value={option.id_estado}
                          >
                            {option.estado}
                          </MenuItem>
                        )
                      })}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <Controller
              control={control}
              name='municipio'
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <InputLabel variant='standard' htmlFor='uncontrolled-native'>
                    Municipios
                  </InputLabel>
                  <Select
                    defaultValue={activeLocalidad?.id_municipio}
                    label='municipio'
                    fullWidth
                    className={classes.select}
                    onChange={onChange}
                    value={value}
                    error={!!error}
                  >
                    {municipiosByEstado
                      .sort((a, b) => {
                        if (a.municipio > b.municipio) return 1
                        if (a.municipio < b.municipio) return -1
                        return 0
                      })
                      .map((option) => {
                        console.log('option', option)
                        return (
                          <MenuItem
                            className={classes.sublabel}
                            key={option.id_municipio}
                            value={option.id_municipio}
                          >
                            {option.municipio}
                          </MenuItem>
                        )
                      })}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <Controller
              name='direccion'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  id='outlined-multiline-static'
                  className={classes.text}
                  // variant='outlined'
                  label='Dirección'
                  size='small'
                  fullWidth
                  multiline
                  rows={3}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <Controller
              name='telefono'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  className={classes.text}
                  label='Teléfono'
                  size='small'
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
            <Controller
              name='correo'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  className={classes.text}
                  label='correo'
                  size='small'
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item lg={4} sm={4} xl={12} xs={12}>
            <Controller
              control={control}
              name='tipoProveedor'
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <InputLabel variant='standard' htmlFor='uncontrolled-native'>
                    Tipo Proveedor
                  </InputLabel>
                  <Select
                    fullWidth
                    defaultValue={activeLocalidad?.tipoProveedor}
                    className={classes.select}
                    onChange={onChange}
                    value={value}
                    error={!!error}
                  >
                    {tipoProveedor.map((option) => {
                      return (
                        <MenuItem
                          className={classes.sublabel}
                          key={option.key}
                          value={option.value}
                        >
                          {option.value}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item lg={8} sm={8} xl={12} xs={12}>
            <FormControl
              style={{ flexDirection: 'row' }}
              className={classes.radio}
            >
              <Controller
                name='contribuyenteEspecial'
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    className={classes.formcontrol}
                    label='Contribuyente especial'
                    component='legend'
                    control={
                      <Checkbox
                        name='contribuyenteEspecial'
                        defaultValue={activeLocalidad?.contribuyenteEspecial}
                        defaultChecked={activeLocalidad?.contribuyenteEspecial}
                        onChange={field.onChange}
                        checked={field.value}
                      />
                    }
                  />
                )}
              />
              <Controller
                name='aerolinea'
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    className={classes.formcontrol}
                    label='Aerolínea '
                    component='legend'
                    control={
                      <Checkbox
                        // name='aerolinea'
                        // disabled={exonerado}
                        defaultValue={activeLocalidad?.aerolinea}
                        defaultChecked={activeLocalidad?.aerolinea}
                        onChange={field.onChange}
                        checked={field.value}
                        value={(e) => e.target.value}
                      />
                    }
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <Controller
              name='rutaLogo'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <label htmlFor='rutaLogo' style={{ width: '100%' }}>
                  <input
                    style={{
                      display: 'none',
                      alingItems: 'center',
                      width: '100%',
                    }}
                    id='rutaLogo'
                    name='rutaLogo'
                    type='file'
                    accept='image/jpeg'
                    value={value}
                    onChange={(e) => uploadImage(e)}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />

                  <Fab
                    style={{ alingItems: 'center', width: '100%' }}
                    color='secondary'
                    size='small'
                    component='span'
                    aria-label='add'
                    variant='extended'
                  >
                    Cambiar logo empresa
                  </Fab>
                </label>
              )}
            />
          </Grid>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <Controller
              name='base64Sello'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <label htmlFor='base64Sello' style={{ width: '100%' }}>
                  <input
                    style={{
                      display: 'none',
                      alingItems: 'center',
                      width: '100%',
                    }}
                    id='base64Sello'
                    name='base64Sello'
                    type='file'
                    accept='image/png'
                    value={value}
                    onChange={(e) => uploadImageSello(e)}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />

                  <Fab
                    style={{
                      alingItems: 'center',
                      width: '100%',
                      backgroundColor: 'green',
                      color: 'white',
                    }}
                    // color='green'
                    size='small'
                    component='span'
                    aria-label='add'
                    variant='extended'
                  >
                    Sello empresa retenciones
                  </Fab>
                </label>
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.margen}>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <Button
              className={classes.btn}
              size='small'
              type='submit'
              variant='contained'
              color='primary'
              fullWidth
              //  onClick={() => peticionPost()}
            >
              Modificar
            </Button>
          </Grid>
          <Grid item md={6} xs={6}>
            <Button
              className={classes.btn}
              size='small'
              variant='contained'
              fullWidth
              onClick={handleClose}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </form>
    </ModalGenerico>
  )
}
