import {
  ApiAxiosGet,
  ApiAxiosPost,
  ApiAxiosPostFetch,
} from '../../Helpers/ApiAxios'
import { config } from '../../config/configProperties'
import { types } from '../types/types'
import { errorSetActive, errorStatusLoading } from './errorActions'
const URLRETENCION = `${config.urlServer}/retenciones`

/**
 * Function to load the retenciones from the server.
 * @param {Object} options
 * @param {number} options.limit The number of records to return.
 * @param {number} options.page The page number to return.
 * @returns {Object} The action object.
 */
export const startLoadRetenciones = ({ limit = 10, page = 1, datos }) => {
  let filtroFin = `?limit=${limit}&page=${page}`
  console.log('🚀 ~ startLoadRetenciones ~ datos:', datos)

  /**
   * The function to be dispatched when the data is loaded.
   * @param {Object} getState The function to get the state from the store.
   */
  return async (dispatch, getState) => {
    // const { filtro } = getState().retencion

    if (datos !== undefined) {
      if (datos.rif !== 'undefined-undefined') {
        // Add the rif to the filter.
        filtroFin = `${filtroFin}&rif=${datos?.rif}`
      }
      if (datos.fechaInicio !== undefined && datos.fechaFin !== undefined) {
        // Add the fecha inicio and fecha fin to the filter.
        filtroFin = `${filtroFin}&fechaInicio=${datos?.fechaInicio}&fechaFin=${datos?.fechaFin}`
      }

      if (datos.id_localidad !== undefined) {
        // Add the id_localidad to the filter.
        filtroFin = `${filtroFin}&id_localidad=${datos?.id_localidad}`
      }
      if (datos.nroComprobante !== undefined) {
        // Add the nroOrden to the filter.
        filtroFin = `${filtroFin}&nroComprobante=${datos?.nroComprobante}`
      }
    }
    console.log('🚀 ~ return ~ filtroFin:', filtroFin)

    try {
      // Call the api to get the retenciones.
      const res = await ApiAxiosGet(
        `${URLRETENCION}/getRetencionesIva/${filtroFin}`,
        'GET'
      )
      console.log('🚀 ~ return ~ res:', res)

      // Dispatch the action to load the retenciones.
      dispatch(retencionesLoaded(res))
    } catch (error) {
      console.log('🚀 ~ return ~ error:', error)
    }
  }
}

export const startSaveRetencion = (retencion) => {
  console.log('🚀 ~ startSaveRetencion ~ retencion:', retencion)
  return async (dispatch) => {
    if (!Array.isArray(retencion)) {
      retencion = [retencion]
    }

    let json = retencion
    //Llamado al endpoint usando Axios
    console.log('ENTRO OOjson', json)
    try {
      dispatch(errorStatusLoading())
      const res = await ApiAxiosPostFetch(
        `${URLRETENCION}/createRetencionIvaMasiva`,
        'POST',
        json
      )
      console.log('🚀 ~ return ~ res:', res)
      if (res.TotalRentecionIvaProcedas === 1) {
        // dispatch(localidadCargaFacturasJsonSetActive(res.result))
        return dispatch(
          errorSetActive(
            `${res.DetalleretencionesProcesadas.map((success) => {
              return `${success.msg}\n Nro. Control:${success.NroControlComprobante}\n Nro.  Retencion:${success.nroComprobante}`
            })}`,
            'sucess'
          )
        )
      } else {
        return dispatch(
          errorSetActive(
            `Error retencion: ${res.DetalleErrorRetencionesIva.map(
              (error, index) => {
                return `${error.msg}, index: ${index}`
              }
            )}`,
            'error'
          )
        )
      }
    } catch (error) {
      console.log('error', error)
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

/**º
 * Asynchronously loads a retencion in base64 format by its ID.
 *
 * This function makes an API GET request to retrieve the retencion data
 * encoded in base64 format. The result is logged and returned.
 *
 * @param {string} id - The ID of the retencion to load.
 * @returns {Promise<Object>} The response object containing the retencion data.
 * @throws Will log an error message if the request fails.
 */

export const startLoadRetencionCodificada = (id) => {
  console.log('🚀 ~ startLoadRetencionCodificada ~ id:QQQQQQQQ', id)
  return async (dispatch) => {
    try {
      const res = await ApiAxiosGet(
        `${URLRETENCION}/getBase64RetencionIva/${id}`,
        'GET'
      )
      //    dispatch(ordenesLoaded(res))
      console.log('🚀 ~ return ~ res: RETECION', res)
      return res
    } catch (error) {
      console.log('ERRORRROR', error)
    }
  }
}

export const startAnularRetencionIva = (retencion) => {
  const { id } = retencion
  return async (dispatch) => {
    try {
      const res = await ApiAxiosGet(
        `${URLRETENCION}/anularRetencionIva/${id}`,
        'PUT'
      )
      return dispatch(errorSetActive(`${res.msg}`, 'sucess'))
    } catch (error) {
      console.log('ERRORRROR', error)
    }
  }
}

export const startSaveNroretenciones = (data) => {
  return async (dispatch, getState) => {
    //Llamado al endpoint usando Axios
    try {
      dispatch(errorStatusLoading())
      const res = await ApiAxiosPost(
        `${URLRETENCION}/AddNroRetenciones`,
        'POST',
        data
      )
      console.log('🚀 ~ return ~ res:', res)
      // dispatch(updateAsignaNroFacturasLocalidad(res.result.res))
      return dispatch(
        errorSetActive(
          `${res?.message || 'Nro de Retenciones Actualizado con Exito'} `,
          'sucess'
        )
      )
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const startLoadnroRetenciones = (id) => {
  return async (dispatch) => {
    try {
      const res = await ApiAxiosGet(
        `${URLRETENCION}/getnroRetenciones/${id}`,
        'GET'
      )
      console.log('🚀 ~ return ~ res:', res)
      //    dispatch(ordenesLoaded(res))
      console.log('🚀 ~ return ~ res: RETECION', res)
      return res
    } catch (error) {
      console.log('ERRORRROR', error?.response?.data.message)
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const StartqueryConceptosIsrl = () => {
  return async (dispatch) => {
    try {
      const res = await ApiAxiosGet(
        `${URLRETENCION}/queryConceptosIsrl/?limit=100&page=1`,
        'GET'
      )
      dispatch(loadqueryConceptosIsrl(res))
      // return res
    } catch (error) {
      console.log('ERRORRROR', error)
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

// validar que en el array de lstretenciones no se repita el tipoTransaccion, nroDocumento y nroControlDocumento

export const startValidarRetenciones = (objRetencion) => {
  return async (dispatch, getState) => {
    const { addRetencion } = getState().retencion
    const lstRetenciones = addRetencion?.lstRetenciones
    const retenciones = [...lstRetenciones, objRetencion]
    const retencionesValidas = []
    retenciones.forEach((retencion) => {
      if (
        retencionesValidas.filter(
          (ret) =>
            ret.nroDocumento === retencion.nroDocumento &&
            ret.nroControlDocumento === retencion.nroControlDocumento &&
            ret.tipoTransaccion === retencion.tipoTransaccion
        ).length === 0
      ) {
        retencionesValidas.push(retencion)
      }
    })
    console.log('🚀 ~ return ~ retencionesValidas:', retencionesValidas)
  }
}

const loadqueryConceptosIsrl = (conceptosIsrl) => ({
  type: types.queryConceptosIsrl,
  payload: conceptosIsrl,
})

/**
 * Dispatched action for reducer.
 * @param {Object} retenciones The retenciones to be loaded into the state.
 * @return {Object} The action object.
 */
const retencionesLoaded = (retenciones) => ({
  type: types.retencionesLoaded,
  payload: retenciones,
})

/**
 * Setea una retencion como activa en el estado de Redux.
 * @param {Object} retencion La retencion que se va a activar.
 * @return {Object} Accion para el reducer.
 */
export const retencionActive = (retencion) => ({
  type: types.retencionActive,
  payload: retencion,
})

export const retencionesClear = () => ({
  type: types.retencionesClear,
})
export const retencionClearActive = () => ({
  type: types.retencionClearActive,
})

export const startAddRetencion = (retencion) => ({
  type: types.retencionesAdd,
  payload: retencion,
})
export const startretencionAddItem = (retencion) => ({
  type: types.retencionAddItem,
  payload: retencion,
})
export const startretencionRemoveItem = (index) => ({
  type: types.retencionRemoveItem,
  payload: index,
})

export const setClearAddRetencion = () => ({
  type: types.addRetencionClear,
})

export const setlstRetencionesClear = () => ({
  type: types.lstRetencionesClear,
})
