import React from 'react'
import EncabezadoDetalle from './EncabezadoDetalle'
import { useSelector } from 'react-redux'
import EncabezadoDetalleIslr from './EncabezadoDetalleIslr'

export default function ViewDetalles() {
  const { modeloRetencion } = useSelector(
    (state) => state.retencion.addRetencion
  )
  return (
    <>
      {modeloRetencion === 'ISLR' && <EncabezadoDetalleIslr />}
      {modeloRetencion === 'IVA' && <EncabezadoDetalle />}
    </>
  )
}
