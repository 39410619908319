import { useState } from 'react'
import {
  Grid,
  TextField,
  Button,
  Fab,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core'
import toast from 'react-hot-toast'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import useStyles from './Styles'

import ModalGenerico from '../../../commons/ui/Modal'
// import { localidadActions } from '../../../redux/actions/localidadActions'

import {
  startCargaAnularFacturaJsonXls,
  startCargaAnularOrdenesJsonXls,
  startCargaFacturaJsonXls,
  startCargaOrdenJsonXls,
} from '../../../../redux/actions/facturaGeneralActions'
import {
  startCargaAnularNotaJsonXls,
  startCargaNotaJsonXls,
} from '../../../../redux/actions/notaActions'
import { startCargaFacturaXls } from '../../../../redux/actions/localidadActions'
import { Autocomplete } from '@material-ui/lab'
import { useRazonSocial } from '../../../../hooks/useRazonSocial'

export default function CargaGeneracionMasivaIva({ open, handleClose, title }) {
  const [archivoxls, setarchivoxls] = useState(true)
  const [resultadoJson, setresultadoJson] = useState({})
  const [Loadingxls, setLoadingxls] = useState(true)
  const [LoadingJson, setLoadingJson] = useState(true)
  const [tipoJson, settipoJson] = useState(null)
  const { localidades, localidadActive, HandleselectLocalidad } =
    useRazonSocial()
  console.log("🚀 ~ CargaGeneracionMasivaIva ~ localidadActive:", localidadActive?.cargaFacturasJson)
  console.log('🚀 ~ CargaGeneracionMasivaIva ~ localidades:', localidades)

  const notifyerror = (res) => toast.error(res)

  const classes = useStyles()
  const dispatch = useDispatch()
  // const { localidadActive } = useSelector((state) => state.localidad)
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  const onSubmitLocalidad = async (data) => {
    console.log(' DATA', data)
    setLoadingJson(false)

    switch (tipoJson) {
      case 'factura':
        dispatch(
          startCargaFacturaJsonXls(localidadActive.cargaFacturasJson)
        ).then((res) => {
          console.log('RESPONSE', res)
          setresultadoJson(res?.payload?.resultado)
          setLoadingJson(true)
          // if (res.payload.status === 'error') {
          //   notifyerror(res.payload.message)
          // }
        })
        break
      case 'nota':
        dispatch(startCargaNotaJsonXls(localidadActive.cargaFacturasJson)).then(
          (res) => {
            console.log('RESPONSE', res)
            setresultadoJson(res?.payload?.resultado)
            setLoadingJson(true)
            // if (res.payload.status === 'error') {
            //   notifyerror(res.payload.message)
            // }
          }
        )
        break
      case 'anularFactura':
        dispatch(
          startCargaAnularFacturaJsonXls(localidadActive.cargaFacturasJson)
        ).then((res) => {
          console.log(
            '🚀 ~ file: CargaGeneracionMasiva.js:75 ~ ).then ~ res',
            res?.payload?.resultado
          )
          setresultadoJson(res?.payload?.resultado)
          setLoadingJson(true)
        })
        break
      case 'anularNota':
        dispatch(
          startCargaAnularNotaJsonXls(localidadActive.cargaFacturasJson)
        ).then((res) => {
          console.log('RESPONSE', res)
          setresultadoJson(res?.payload?.resultado)
          setLoadingJson(true)
        })
        break
      case 'ordenEntrega':
        dispatch(
          startCargaOrdenJsonXls(localidadActive.cargaFacturasJson)
        ).then((res) => {
          console.log('RESPONSE', res)
          setresultadoJson(res?.payload?.resultado)
          setLoadingJson(true)
        })
        break
      case 'anularOrden':
        dispatch(
          startCargaAnularOrdenesJsonXls(localidadActive.cargaFacturasJson)
        ).then((res) => {
          console.log('RESPONSE', res)
          setresultadoJson(res?.payload?.resultado)
          setLoadingJson(true)
        })
        break
      default:
        break
    }
  }

  const uploadImage = async (e) => {
    const file = e.target.files[0]
    console.log('file', file)
    setLoadingxls(false)
    if (
      file.type !==
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      notifyerror(`Solo se Admiten archivos con formato .xls`)
      return false
    }

    const formData = new FormData()
    formData.append('archivoXls', file)
    formData.append(
      'nombreArchivo',
      `${localidadActive.tipoDocumento}-${localidadActive.numeroDocumento}`
    )
    formData.append('tipo', tipoJson)

    dispatch(startCargaFacturaXls(formData)).then((res) => {
      console.log('res', res)
      if (res.payload.tipoMsg === 'sucess') {
        setLoadingxls(true)
        setarchivoxls(false)
      } else {
        console.log('res', res)
        setLoadingxls(true)
      }
    })
  }

  console.log('settipoJson', tipoJson)

  return (
    <ModalGenerico open={open} handleClose={handleClose} title={title}>
      <form onSubmit={handleSubmit(onSubmitLocalidad)} autoComplete='off'>
        <Grid container spacing={1} className={classes.root}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Controller
              control={control}
              name='tipodocumento'
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Select
                  labelId='localidad'
                  id='localidad'
                  fullWidth
                  defaultValue={''}
                  // disabled={!Siguiente}
                  className={classes.select}
                  onChange={(event) => {
                    const newValue = event.target.value
                    onChange(newValue)
                    HandleselectLocalidad(event, newValue)
                  }}
                  value={value}
                  error={!!error}
                >
                  {localidades.map((option) => {
                    return (
                      <MenuItem
                        className={classes.sublabel}
                        key={option._id}
                        value={option}
                      >
                        {option.nombre}
                      </MenuItem>
                    )
                  })}
                </Select>
              )}
            />
            <FormHelperText error>
              {errors?.tipodocumento?.message}
            </FormHelperText>

            {/* <Controller
              name='localidad'
              control={control}
              defaultValue=''
              rules={{ required: 'Este campo es requerido' }}
              render={({ field }) => (
                <Autocomplete
                  style={{ width: '100%' }}
                  size='small'
                  id='localidad'
                  clearText='Limpiar'
                  noOptionsText='No hay Localidades'
                  options={localidades}
                  getOptionLabel={(option) => option.nombre || ''}
                  value={localidadActive}
                  onChange={(_, newValue) => {
                    field.onChange(newValue?._id)
                    HandleselectLocalidad(_, newValue)
                  }}
                  filterSelectedOptions
                  renderOption={(option) => {
                    return <h6 className={classes.texto}>{option.nombre}</h6>
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ style: { fontSize: '12px' } }}
                      className={classes.textField}
                      variant='standard'
                      label='NOMBRE O RAZÓN SOCIAL DEL AGENTE DE RETENCIÓN'
                      // error={!!errors.localidad}
                      // helperText={errors.localidad?.message}
                    />
                  )}
                />
              )}
            /> */}
          </Grid>

          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <TextField
              id='nombre'
              name='nombre'
              label='Razón Social'
              className={classes.text}
              //  value={localidadActive.nombre}
              disabled
              // variant='outlined'
              margin='normal'
              fullWidth
              InputProps={{
                readOnly: true,
                classes: {
                  input: classes.input,
                },
              }}
            />
          </Grid>

          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <TextField
              id='cantidad'
              name='cantidad'
              label='Facturas/Notas/Ordenes ha procesar'
              className={classes.text}
              //  value={localidadActive?.cargaFacturasJson?.length || 0}
              disabled
              // variant='outlined'
              margin='normal'
              fullWidth
              InputProps={{
                readOnly: true,
                className: {
                  input: classes.input,
                },
              }}
            />
          </Grid>

          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <TextField
              id='procesados'
              name='procesados'
              label='Procesadas'
              className={classes.text}
              value={
                resultadoJson?.TotalFacturasProcedas ||
                resultadoJson?.TotalNotasProcedas ||
                resultadoJson?.TotalOrdenesProcedas ||
                0
              }
              disabled
              // variant='outlined'
              margin='normal'
              fullWidth
              InputProps={{
                readOnly: true,
                className: {
                  input: classes.input,
                },
              }}
            />
          </Grid>

          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <TextField
              id='nuevasfacturas'
              name='nuevasfacturas'
              label='Facturas/Notas/Ordenes (Nuevas)'
              className={classes.text}
              value={
                resultadoJson?.totalFacturaNew ||
                resultadoJson?.totalNotasNew ||
                resultadoJson?.TotalFacturasAnuladasProcedas ||
                resultadoJson?.TotalNotasAnuladasProcedas ||
                resultadoJson?.totalOrdenesNew ||
                0
              }
              disabled
              // variant='outlined'
              margin='normal'
              fullWidth
              InputProps={{
                readOnly: true,
                className: {
                  input: classes.input,
                },
              }}
            />
          </Grid>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <TextField
              id='errorfacturas'
              name='errorfacturas'
              label='Facturas/Notas/Ordenes con Errores'
              className={classes.text}
              value={
                resultadoJson?.totalFacturaconError ||
                resultadoJson?.totalNotasconError ||
                resultadoJson?.totalAnuladasFacturaconError ||
                resultadoJson?.totalAnuladasNotasconError ||
                resultadoJson?.TotalFacturasAnuladasconError ||
                resultadoJson?.TotalNotasAnuladasconError ||
                resultadoJson?.totalOrdenesconError ||
                // ||
                0
              }
              disabled
              // variant='outlined'
              margin='normal'
              fullWidth
              InputProps={{
                readOnly: true,
                className: {
                  input: classes.input,
                },
              }}
            />
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <FormControl>
              <FormLabel id='demo-row-radio-buttons-group-label'>
                Tipo de Archivo
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby='demo-row-radio-buttons-group-label'
                name='row-radio-buttons-group'
                className={classes.radiolabel}
                value={tipoJson}
                onChange={(e) => settipoJson(e.target.value)}
              >
                <FormControlLabel
                  value='factura'
                  control={<Radio />}
                  label='Factura'
                />
                <FormControlLabel
                  value='nota'
                  control={<Radio />}
                  label='Nota'
                />
                <FormControlLabel
                  value='anularFactura'
                  control={<Radio />}
                  label='Anul. Factura'
                />
                <FormControlLabel
                  value='anularNota'
                  control={<Radio />}
                  label='Anul. Nota'
                />
                <FormControlLabel
                  value='ordenEntrega'
                  control={<Radio />}
                  label='Orden/Guía'
                />
                <FormControlLabel
                  value='anularOrden'
                  control={<Radio />}
                  label='Anul. Orden/Guía'
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <>
              <Typography variant='h6' className={classes.text}>
                errores encontrados:
              </Typography>
              <Divider variant='fullWidth' component='li' />
            </>
            <div
              style={{
                width: '100%',
                maxWidth: 360,
                bgcolor: 'background.paper',
                position: 'relative',
                overflow: 'auto',
                maxHeight: 200,
                '& ul': { padding: 0 },
              }}
            >
              {resultadoJson.TotalFacturasAnuladasProcedas !== undefined &&
                resultadoJson?.DetalleErrorFacturas?.map(
                  (item, inx) =>
                    item && (
                      <List key={inx}>
                        {item.map(
                          (item, i) =>
                            item && (
                              <ListItem key={i}>
                                <Divider variant='fullWidth' component='li' />
                                <ListItemText
                                  primary={item?.nroFactura || item?.NroFactura}
                                  secondary={
                                    <>
                                      <Typography
                                        // sx={{ display: 'inline' }}
                                        component='span'
                                        variant='body1'
                                        color='tex  t.primary'
                                      >
                                        {item?.Mensaje !== undefined
                                          ? item?.Mensaje
                                          : item?.msg}
                                      </Typography>
                                    </>
                                  }
                                />
                              </ListItem>
                            )
                        )}
                      </List>
                    )
                )}

              {resultadoJson.DetalleFacturaProcesadas !== undefined &&
                resultadoJson?.DetalleErrorFacturas?.map(
                  (item, inx) =>
                    item && (
                      <List key={inx}>
                        {item.map(
                          (item, i) =>
                            item && (
                              <ListItem key={i}>
                                <Divider variant='fullWidth' component='li' />
                                <ListItemText
                                  primary={item?.nroFactura}
                                  secondary={
                                    <>
                                      <Typography
                                        // sx={{ display: 'inline' }}
                                        component='span'
                                        variant='body1'
                                        color='text.primary'
                                      >
                                        {item?.msg.message || item?.msg}
                                      </Typography>
                                    </>
                                  }
                                />
                              </ListItem>
                            )
                        )}
                      </List>
                    )
                )}

              {resultadoJson.TotalNotasProcedas !== undefined &&
                resultadoJson?.DetalleErrorNotas?.map(
                  (item, inx) =>
                    item && (
                      <List key={inx}>
                        {item.map(
                          (item, i) =>
                            item && (
                              <ListItem key={i}>
                                <Divider variant='fullWidth' component='li' />
                                <ListItemText
                                  primary={item?.nroFactura}
                                  secondary={
                                    <>
                                      <Typography
                                        // sx={{ display: 'inline' }}
                                        component='span'
                                        variant='body1'
                                        color='text.primary'
                                      >
                                        {item?.msg.message || item?.msg}
                                      </Typography>
                                    </>
                                  }
                                />
                              </ListItem>
                            )
                        )}
                      </List>
                    )
                )}
              {resultadoJson.TotalNotasAnuladasProcedas !== undefined &&
                resultadoJson?.DetalleErrorNotas?.map(
                  (item, inx) =>
                    item && (
                      <List key={inx}>
                        {item.map(
                          (item, i) =>
                            item && (
                              <ListItem key={i}>
                                <Divider variant='fullWidth' component='li' />
                                <ListItemText
                                  primary={item?.nroNota || item?.NroNota}
                                  secondary={
                                    <>
                                      <Typography
                                        // sx={{ display: 'inline' }}
                                        component='span'
                                        variant='body1'
                                        color='tex  t.primary'
                                      >
                                        {item?.Mensaje !== undefined
                                          ? item?.Mensaje
                                          : item?.msg}
                                      </Typography>
                                    </>
                                  }
                                />
                              </ListItem>
                            )
                        )}
                      </List>
                    )
                )}
              {/* ordenes */}
              {resultadoJson.DetalleOrdenesProcesadas !== undefined &&
                resultadoJson?.DetalleErrorOrdenes?.map(
                  (item, inx) =>
                    item && (
                      <List key={inx}>
                        {item.map(
                          (item, i) =>
                            item && (
                              <ListItem key={i}>
                                <Divider variant='fullWidth' component='li' />
                                <ListItemText
                                  primary={item?.nroOrden}
                                  secondary={
                                    <>
                                      <Typography
                                        // sx={{ display: 'inline' }}
                                        component='span'
                                        variant='body1'
                                        color='text.primary'
                                      >
                                        {item?.msg.message || item?.msg}
                                      </Typography>
                                    </>
                                  }
                                />
                              </ListItem>
                            )
                        )}
                      </List>
                    )
                )}

              {resultadoJson.TotalOrdensAnuladasProcedas !== undefined &&
                resultadoJson?.DetalleErrorOrdenes?.map(
                  (item, inx) =>
                    item && (
                      <List key={inx}>
                        {item.map(
                          (item, i) =>
                            item && (
                              <ListItem key={i}>
                                <Divider variant='fullWidth' component='li' />
                                <ListItemText
                                  primary={item?.NroOrden}
                                  secondary={
                                    <>
                                      <Typography
                                        // sx={{ display: 'inline' }}
                                        component='span'
                                        variant='body1'
                                        color='text.primary'
                                      >
                                        {item?.msg?.message ||
                                          item?.msg ||
                                          item?.Mensaje}
                                      </Typography>
                                    </>
                                  }
                                />
                              </ListItem>
                            )
                        )}
                      </List>
                    )
                )}
            </div>
          </Grid>

          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Controller
              name='rutaLogo'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <label htmlFor='rutaLogo' style={{ width: '100%' }}>
                  <input
                    style={{
                      display: 'none',
                      alingItems: 'center',
                      width: '100%',
                    }}
                    disabled={
                      tipoJson === null || tipoJson === '' ? true : false
                    }
                    id='rutaLogo'
                    name='rutaLogo'
                    type='file'
                    accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                    value={value}
                    onChange={(e) => uploadImage(e)}
                    // error={!!error}
                    // helperText={error ? error.message : null}
                    // disabled={!archivoxls}
                  />

                  <Fab
                    style={{ alingItems: 'center', width: '100%' }}
                    color='secondary'
                    size='small'
                    component='span'
                    aria-label='add'
                    variant='extended'
                    disabled={
                      tipoJson === null || tipoJson === '' ? true : false
                    }
                    // disabled={!archivoxls}
                  >
                    {Loadingxls ? 'subir Archivo excel' : 'Cargando...'}
                  </Fab>
                </label>
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.margen}>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <Button
              className={classes.btn}
              size='small'
              type='submit'
              variant='contained'
              color='primary'
              fullWidth
              disabled={archivoxls}
              //  onClick={() => peticionPost()}
            >
              {LoadingJson ? 'Procesar' : 'Cargando...'}
            </Button>
          </Grid>
          <Grid item md={6} xs={6}>
            <Button
              className={classes.btn}
              size='small'
              variant='contained'
              fullWidth
              onClick={handleClose}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </form>
    </ModalGenerico>
  )
}
