import { Container, Grid, Typography, Button } from '@material-ui/core'
import { useDispatch } from 'react-redux'
// import { startDeleteFacturaGeneral } from '../../../redux/actions/facturaGeneralActions'
import ModalGenerico from '../../commons/ui/Modal'
// import { useStyles } from '../General/FacturaGeneralModalShow/Styles'
import { startAnularRetencionIva } from '../../../redux/actions/retencionActions'
import useStyles from './Styles'
// import useOrden from '../../../hooks/useOrden'
// import { startAnularRetencionIva } from '../../../redux/actions/retencionesActions'

const AnularRetencionIva = ({
  handleClose,
  open,
  title,
  activedRetencionIva,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  //  const { activedRetencionIva } = useOrden()

  const handleSubmit = (e) => {
    //   e.preventDefault()
    dispatch(startAnularRetencionIva(activedRetencionIva)).then((res) => {
      console.log('🚀 ~ dispatch ~ res:', res)
      handleClose()
    })
  }

  return (
    <ModalGenerico
      open={open}
      handleClose={handleClose}
      title={title}
      // top={55}
      // left={55}
      // left={55}
    >
      <form onSubmit={handleSubmit}>
        <Container
          style={{
            backgroundColor: '#f5f5f5',
            // Width: '40vh',
            // minHeight: '20vh',
          }}
        >
          <Grid container spacing={2}>
            <Grid item lg={6} sm={6} xl={6} xs={6}>
              <Typography varint='h6' style={{ fontWeight: 'bold' }}>
                {`N° Comprobante: ${activedRetencionIva?.nroComprobante}`}
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} xl={6} xs={6}>
              <Typography varint='h6' style={{ fontWeight: 'bold' }}>
                {`Razon Social: ${activedRetencionIva?.identificacion}`}
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} xl={6} xs={6}>
              <Typography varint='h6' style={{ fontWeight: 'bold' }}>
                {`Rif: ${activedRetencionIva?.rifProveedor}`}
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} xl={6} xs={6}>
              <Typography varint='h6' style={{ fontWeight: 'bold' }}>
                {`Correo: ${activedRetencionIva?.correo}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.margen}>
            <Grid item lg={6} sm={6} xl={12} xs={12}>
              <Button
                className={classes.btn}
                size='small'
                type='submit'
                variant='contained'
                color='primary'
                fullWidth
                //  onClick={() => peticionPost()}
              >
                Anular
              </Button>
            </Grid>
            <Grid item md={6} xs={6}>
              <Button
                className={classes.btn}
                size='small'
                variant='contained'
                fullWidth
                onClick={handleClose}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Container>
      </form>
    </ModalGenerico>
  )
}

export default AnularRetencionIva
