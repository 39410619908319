import { useEffect, useState } from 'react'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import useStyles from './Styles'
// import { ConsultaNroFacturaAuto } from '../../../redux/actions/controlFacturaActions'
import NumberFormat from 'react-number-format'
import AddCircleOutlineOutlined from '@material-ui/icons/AddCircleOutlineOutlined'
import CheckIcon from '@material-ui/icons/Check'
import { startSaveNroFacturaAuto } from '../../../redux/actions/controlFacturaActions'
import {
  startLoadnroRetenciones,
  startSaveNroretenciones,
} from '../../../redux/actions/retencionActions'

export default function ParamsNroRetenciones() {
  const { activeLocalidad } = useSelector((state) => state.localidad)
  const classes = useStyles()
  const dispatch = useDispatch()

  const [NroAsignaRetencion, setNroAsignaRetencion] = useState({
    id_localidad: activeLocalidad?._id,
    cantidad: 0,
    nroActual: 0,
    nroDesde: 0,
    nroHasta: 0,
    serie: ' ',
    asignaNro: 'GLOBAL',
    // logEstatusRegistro: 'INACTIVO',
  })

  const [values, setvalues] = useState(false)

  const onSubmitParamtrosNro = async () => {
    console.log("🚀 ~ onSubmitParamtrosNro ~ NroAsignaRetencion:", NroAsignaRetencion)
    dispatch(startSaveNroretenciones(NroAsignaRetencion))
  }

  useEffect(() => {
    dispatch(startLoadnroRetenciones(activeLocalidad?._id)).then((res) => {
      console.log('🚀 ~ dispatch ~ res:', res)
      if (!res) {
        setNroAsignaRetencion({
          id_localidad: activeLocalidad?._id,
          cantidad: 0,
          nroActual: 0,
          nroDesde: 0,
          nroHasta: 0,
          serie: ' ',
          asignaNro: 'GLOBAL',
          // logEstatusRegistro: 'INACTIVO',
        })
      } else {
        setvalues(res.logEstatusRegistro !== 'INACTIVO' ? true : false)
        setNroAsignaRetencion((prevState) => ({
          ...prevState,
          cantidad: res.cantidad,
          nroActual: res.nroActual,
          nroDesde: res.nroDesde,
          nroHasta: res.nroHasta,
          serie: res.serie,
          asignaNro: 'GLOBAL',
          // logEstatusRegistro: 'ACTIVO',
        }))
      }

    })
  }, [dispatch, activeLocalidad?._id])

  const handleChange = (e) => {
    const { name, checked, value } = e.target
    console.log(name, checked)
    if (name === 'activafacturaAuto') {
      setvalues(checked)
    } else {
      setNroAsignaRetencion((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  console.log('🚀 ~ ParamsNroRetenciones ~ values:', values)

  console.log(
    '🚀 ~ onSubmitParamtrosNro ~ NroAsignaRetencion:',
    '🚀 ~ onSubmitParamtrosNro ~ NroAsignaRetencion:',
    NroAsignaRetencion
  )

  return (
    // <form onSubmit={handleSubmit(onSubmitParamtrosNro)}>
    <>
      <Grid container spacing={2} className={classes.root}>
        <Grid item lg={10} sm={10} xl={12} xs={12}>
          <FormControlLabel
            style={{ margitTop: '8px' }}
            className={classes.switchgenerico}
            labelPlacement='end'
            defaultValue={values}
            defaultChecked={values}
            control={<Switch />}
            name='activafacturaAuto'
            label='Activar Nro. Retenciones Automatico'
            value={values}
            checked={values}
            onChange={(e) => handleChange(e)}
          />
        </Grid>
        <Grid item lg={2} sm={2} xl={2} xs={2}>
          <IconButton
            // className={classes.btn}
            onClick={onSubmitParamtrosNro}
            size='small'
            // type='submit'
            variant='contained'
            color='primary'
            fullWidth
            //  onClick={() => peticionPost()}
          >
            {NroAsignaRetencion.asignaNro === 'GLOBAL' ? (
              <CheckIcon />
            ) : (
              <AddCircleOutlineOutlined />
            )}
          </IconButton>
        </Grid>
      </Grid>

      {values && (
        <Grid container spacing={2} className={classes.root}>
          <Grid item lg={1} sm={1} xl={12} xs={12}>
            <TextField
              name='serie'
              className={classes.text}
              label='Serie'
              defaultValue={NroAsignaRetencion?.serie || ' '}
              size='small'
              fullWidth
              InputProps={{
                inputProps: { maxlength: 2 },
              }}
              value={NroAsignaRetencion.serie}
              onChange={(e) => handleChange(e)}
              // helperText={error ? error.message : null}
            />
          </Grid>
          <Grid item lg={3} sm={3} xl={12} xs={12}>
            <NumberFormat
              name='cantidad'
              allowLeadingZeros={true}
              customInput={TextField}
              className={classes.text}
              label='Cantidad'
              defaultValue={NroAsignaRetencion?.cantidad || 0}
              size='small'
              fullWidth
              value={NroAsignaRetencion.cantidad}
              onChange={(e) => handleChange(e)}
              // helperText={error ? error.message : null}
            />
          </Grid>
          <Grid item lg={3} sm={3} xl={12} xs={12}>
            <NumberFormat
              name='nroActual'
              allowLeadingZeros={true}
              customInput={TextField}
              className={classes.text}
              label='Actual'
              // variant='outlined'
              defaultValue={NroAsignaRetencion?.nroActual || 0}
              size='small'
              fullWidth
              value={NroAsignaRetencion.nroActual}
              onChange={(e) => handleChange(e)}
              // helperText={error ? error.message : null}
            />
          </Grid>
          <Grid item lg={3} sm={3} xl={12} xs={12}>
            <NumberFormat
              name='nroDesde'
              allowLeadingZeros={true}
              customInput={TextField}
              className={classes.text}
              label='Desde'
              // variant='outlined'
              size='small'
              fullWidth
              defaultValue={NroAsignaRetencion?.nroDesde || 0}
              value={NroAsignaRetencion.nroDesde}
              onChange={(e) => handleChange(e)}
              // helperText={error ? error.message : null}
            />
          </Grid>
          <Grid item lg={2} sm={2} xl={12} xs={12}>
            <NumberFormat
              name='nroHasta'
              allowLeadingZeros={true}
              customInput={TextField}
              className={classes.text}
              label='Hasta'
              // variant='outlined'
              size='small'
              fullWidth
              defaultValue={NroAsignaRetencion?.nroHasta || 0}
              value={NroAsignaRetencion.nroHasta}
              onChange={(e) => handleChange(e)}
            />
          </Grid>
        </Grid>
      )}
    </>
  )
}
