import React from 'react'

export const columnas = [
  {
    title: 'Codigo',
    field: 'Codigo',
    numeric: false,
    render: (rowData) => {
      return (
        <div
          style={{
            fontSize: '10px',
            // backgroundColor: '#054477',
            // color: 'whitesmoke',
            textAlign: 'center',
          }}
        >
          {rowData.Codigo}
        </div>
      )
    },
  },

  {
    title: 'Concepto',
    field: 'Concepto',
    numeric: false,
    render: (rowData) => {
      return (
        <div
          style={{
            fontSize: '10px',
            textAlign: 'left',
            maxWidth: '500px',
          }}
        >
          {rowData.Concepto}
        </div>
      )
    },
  },
  {
    title: 'Porcentaje',
    field: 'Porcentaje',
    numeric: false,
    render: (rowData) => {
      return (
        <div
          style={{
            fontSize: '10px',
            textAlign: 'center',
            // maxWidth: '100px',
          }}
        >
          {rowData.Porcentaje}
        </div>
      )
    },
  },
  {
    title: 'BaseImponible',
    field: 'Base Imponible',
    numeric: false,
    render: (rowData) => {
      return (
        <div
          style={{
            fontSize: '10px',
            textAlign: 'center',
          }}
        >
          {rowData.BaseImponible}
        </div>
      )
    },
  },
  {
    title: 'Sustraendo',
    field: 'Sustraendo',
    numeric: false,
    render: (rowData) => {
      return (
        <div
          style={{
            // minWidth: '80px',
            fontSize: '10px',
            // backgroundColor: '#054477',
            // color: 'whitesmoke',
            textAlign: 'left',
          }}
        >
          {rowData.Sustraendo}
        </div>
      )
    },
  },
  {
    title: 'UT',
    field: 'UT',
    numeric: false,

    render: (rowData) => {
      return (
        <div
          style={{
            fontSize: '10px',
            textAlign: 'center',
          }}
        >
          {rowData.UT}
        </div>
      )
    },
  },
  {
    title: 'Factor',
    field: 'Factor',
    numeric: false,
    render: (rowData) => {
      return (
        <div
          style={{
            fontSize: '10px',
            textAlign: 'center',
          }}
        >
          {rowData.Factor}
        </div>
      )
    },
  },
]

export const opciones = {
  sorting: true,
  actionsColumnIndex: -1,
  headerStyle: {
    backgroundColor: '#085c9f',
    color: '#FFF',
  },
  exportButton: true,
  rowStyle: (rowData) => {
    if (rowData.logEstatusRegistro === 'INACTIVO') {
      return { backgroundColor: '#F3EFEE' }
    }
  },
}

export const localization = {
  header: {
    actions: 'Acciones',
  },
  toolbar: {
    searchPlaceholder: 'Buscar',
    searchTooltip: 'Buscar',
    exportTitle: 'Exportar',
    exportCSVName: 'Exportar en formato excel',
    exportPDFName: 'Exportar como pdf',
  },
  pagination: {
    firstAriaLabel: 'Primera página',
    firstTooltip: 'Primera página',
    previousAriaLabel: 'Página anterior',
    previousTooltip: 'Página anterior',
    nextAriaLabel: 'Página siguiente',
    nextTooltip: 'Página siguiente',
    lastAriaLabel: 'Última página',
    lastTooltip: 'Última página',
    labelDisplayedRows: '{from}-{to} de {count}',
    labelRowsSelect: 'filas',
  },
  body: {
    emptyDataSourceMessage: 'No existen datos para mostrar',
  },
}
